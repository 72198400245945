import React from 'react';
import { Routes, Route } from 'react-router-dom';

import TripsList from '../../components/trips-list/trips-list.component';
import Trip from '../../components/trip/trip.component';

function Trips() {
  return (
    <Routes>
      <Route index element={<TripsList />} />
      <Route path=":tripId/*" element={<Trip />} />
    </Routes>
  );
}

Trips.displayName = 'Trips';
export default Trips;
