import React, { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AccordionDetails } from '@mui/material';

import { Trip, TripList } from '../../../redux/trip/trip.types';
import { getElementByIdInArray } from '../../../redux/trip/trip.utils';
import { selectListOrder, selectActiveListOriginalCardOrder } from '../../../redux/trip/trip.selectors';
import { moveCardToList, updateActiveListId } from '../../../redux/trip/trip.slice';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { useOrderedLists } from '../../../hooks/trip/trip-list.hooks';
import {
  ItineraryCardsContainer,
  ItinerarySidebarAccordion,
  ItinerarySidebarContainer,
  ItinerarySidebarItemAccordionSummary,
} from '../itinerary/itinerary-sidebar/itinerary-sidebar.styles';
import PlacesAutocomplete from '../../places-autocomplete/places-autocomplete.component';
import SortableCard from '../../sortable-card/sortable-card.component';
import AddListInput from './add-list-input/add-list-input.component';
import ListItem from './list-item/list-item.component';

import {
  ListAutocompleteContainer,
  ListInputButton,
  MobilePlacesInputContainer,
  MobileListTitleContainer,
  MobileListTitleEmoji,
  MobileListTitle,
} from './trip-sidebar.styles';

interface TripSidebarMobileProps {
  trip: Trip,
  canEdit?: boolean,
  location?: { lat: number, lng: number },
  onSuggestionMouseEnter?: (suggestion: google.maps.places.AutocompletePrediction) => void,
  onSuggestionMouseLeave?: (suggestion: google.maps.places.AutocompletePrediction) => void,
  onSuggestionSelect?: (suggestion: google.maps.places.AutocompletePrediction) => void,
}

function TripSidebarMobile({
  trip,
  canEdit,
  location,
  onSuggestionMouseEnter,
  onSuggestionMouseLeave,
  onSuggestionSelect,
}: TripSidebarMobileProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const listOrder = useAppSelector(selectListOrder);
  const orderedLists = useOrderedLists(trip.lists, trip.listOrder);
  const activeListOriginalCardOrder = useAppSelector(selectActiveListOriginalCardOrder);

  // shows/hides a list's card container if the autcomplete component is clicked
  const [isAddingPlace, setIsAddingPlace] = useState(false);

  // so far seems to correctly load the first list on initial render of the page.
  const [expanded, setExpanded] = useState<string | false>(
    trip.lists.length ? orderedLists[0].id : false
  );

  const handleChange = (listId: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? listId : false);
    if (isExpanded) {
      dispatch(updateActiveListId(listId));
      searchParams.set('list', listId);
    } else {
      searchParams.delete('list');
    }
    setSearchParams(searchParams);
  };

  const moveCard = useCallback(
    (cardId: string, fromListId: string, toListId: string) => {
      if (trip) {
        const fromList = getElementByIdInArray(trip.lists, fromListId);
        const toList = getElementByIdInArray(trip.lists, toListId);
        if (fromList && toList) {
          const fromListCardOrder = (fromList as TripList).cardOrder;
          if (fromListCardOrder) {
            const toListCardOrder = (toList as TripList).cardOrder || [];
            dispatch(
              moveCardToList({
                tripId: trip.id,
                cardId,
                fromListId,
                toListId,
                fromListCardOrder,
                toListCardOrder,
              })
            );
          }
        }
      }
    },
    [dispatch, trip]
  );

  // Handle url navigation
  useEffect(() => {
    setExpanded(searchParams.get('list') || false);
  }, [searchParams, setSearchParams]);

  return (
    <ItinerarySidebarContainer>
      {!isAddingPlace && <AddListInput tripId={trip.id} canEdit={canEdit} listOrder={listOrder} />}
      {orderedLists.map((list) => (
        <React.Fragment key={list.id}>
          {canEdit && isAddingPlace && expanded === list.id && (
            <MobilePlacesInputContainer>
              <MobileListTitleContainer>
                <MobileListTitleEmoji>{list.emoji}</MobileListTitleEmoji>
                <MobileListTitle>{list.name}</MobileListTitle>
              </MobileListTitleContainer>

              <PlacesAutocomplete
                location={location}
                placeholder="Add a place or an address"
                className="playground-autocomplete"
                onSuggestionMouseEnter={onSuggestionMouseEnter}
                onSuggestionMouseLeave={onSuggestionMouseLeave}
                onSuggestionSelect={onSuggestionSelect}
                setIsAddingPlace={setIsAddingPlace}
                shouldClearValueOnSelect
                isMobilePlayground
              />
            </MobilePlacesInputContainer>
          )}
          {!isAddingPlace && (
            <ItinerarySidebarAccordion
              key={list.id}
              expanded={expanded === list.id}
              onChange={handleChange(list.id)}
              disableGutters
            >
              <ItinerarySidebarItemAccordionSummary key={list.id}>
                <ListItem
                  key={list.id}
                  tripId={trip.id}
                  list={list}
                  listOrder={listOrder || []}
                  isActive={expanded === list.id}
                  canEdit={canEdit}
                  moveCard={moveCard}
                />
              </ItinerarySidebarItemAccordionSummary>
              <AccordionDetails>
                {canEdit && (
                  <ListAutocompleteContainer onClick={() => setIsAddingPlace(true)}>
                    <ListInputButton
                      className="ListInputButton"
                      placeholder="Add a place or an address "
                      disabled
                    />
                  </ListAutocompleteContainer>
                )}
                <ItineraryCardsContainer>
                  {trip &&
                    list.cardOrder &&
                    expanded === list.id &&
                    list.cardOrder.map((cardId) => (
                      <SortableCard
                        key={cardId}
                        card={trip.cards[cardId]}
                        listId={list.id}
                        tripId={trip.id}
                        cardOrder={list.cardOrder ?? []}
                        originalCardOrder={activeListOriginalCardOrder}
                        canEdit={canEdit}
                      />
                    ))}
                </ItineraryCardsContainer>
              </AccordionDetails>
            </ItinerarySidebarAccordion>
          )}
        </React.Fragment>
      ))}
    </ItinerarySidebarContainer>
  );
}

TripSidebarMobile.displayName = 'TripSidebarMobile';
export default TripSidebarMobile;
