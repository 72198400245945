/* eslint-disable object-curly-newline */
import {
  createAsyncThunk,
  createSlice,
  isRejected,
  AnyAction,
  PayloadAction,
  SerializedError,
} from '@reduxjs/toolkit';
import { User } from 'firebase/auth';
import { Descendant } from 'slate';
import _ from 'lodash';
import { Timestamp, serverTimestamp } from 'firebase/firestore';
import {
  createTripDocumentAndLists,
  getListsDocs,
  getTripDocument,
  getTripsForUser,
  updateTripField,
  createListInTrip,
  removeListInTrip,
  updateListInTrip,
  getCardsDocs,
  updateCardInTrip,
  updateNotesForCard,
  createCardInList,
  deleteCardFromList,
  moveCard,
  createDayAndAddEventInTrip,
  addEventToDayInTrip,
  moveEventInTrip,
  moveEventToNewDayInTrip,
  deleteEventInTrip,
  deleteTripDocument,
  deleteFieldInCardInTrip,
  updateDayInTrip,
  updateLastOpenedForUser,
} from '../../firebase/firebase.utils';
import { CardData, LatLng, ListData, TripData, TripRole } from '../../firebase/firebase.types';
import { signOutUser } from '../user/user.slice';
import { cleanObject, getIntersection } from '../utils';
import {
  CardID,
  CurrentTrip,
  DateISO,
  DayID,
  EventID,
  IChangeDefaultDatesThunkResult,
  IChangeEventLengthPayload,
  ISetDefaultDaysPayload,
  isEventID,
  ListID,
  Trip,
  TripCard,
  TripCreationStatus,
  TripID,
  TripList,
} from './trip.types';
import {
  getDaysMapSnapshot,
  getElementByIdInArray,
  getEventsMapSnapshot,
  getList,
  tripTitleForPlace,
} from './trip.utils';
import { changeEventLength, changeDefaultDates, updateDates } from './trip.operations';

export const emptyTemplate: Descendant[] = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

export interface TripState {
  readonly trips: Trip[],
  readonly tripCreationStatus: TripCreationStatus,
  readonly currentTrip?: CurrentTrip,
  readonly isLoading: boolean,
  readonly error?: Error | SerializedError,
}

const INITIAL_STATE: TripState = {
  trips: [],
  tripCreationStatus: TripCreationStatus.NOT_CREATED,
  isLoading: false,
};

const getListsSnapshot = async (tripId: TripID): Promise<TripList[]> => {
  const listsDocs = await getListsDocs(tripId);
  const lists: TripList[] = [];

  listsDocs.forEach((listDoc) => {
    const listData = listDoc.data() as ListData;

    // Convert the createdAt field to an ISO string if it exists
    const createdAtISO = listData.createdAt
      ? (listData.createdAt as Timestamp).toDate().toISOString()
      : null;

    // Exclude createdAt from listData to avoid duplication
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { createdAt, ...restListData } = listData;

    lists.push({
      id: listDoc.id,
      createdAt: createdAtISO,
      ...restListData, // Spread the rest of the listData
    });
  });

  return lists;
};
const getCardsMapSnapshot = async (tripId: TripID) => {
  const cardsDocs = await getCardsDocs(tripId);
  const cards: { [key: string]: TripCard } = {};

  cardsDocs.forEach((cardDoc) => {
    const cardData = cardDoc.data() as CardData;
    const dateAdded = cardData.dateAdded as Timestamp | undefined;

    // Convert dateAdded to ISO string
    const dateAddedISO = dateAdded ? dateAdded.toDate().toISOString() : '';

    cards[cardDoc.id] = {
      id: cardDoc.id,
      ...cardData,
      // Override dateAdded with ISO string
      dateAdded: dateAddedISO,
    };
  });

  return cards;
};

const getCardIdToListIdMap = (lists: TripList[]) => {
  const cardIdToListId: { [key: CardID]: ListID } = {};
  lists.forEach((list) => {
    list.cardOrder?.forEach((cardId) => {
      cardIdToListId[cardId] = list.id;
    });
  });
  return cardIdToListId;
};

export const fetchTrips = createAsyncThunk('trip/fetchAll', async (userId: string) => {
  const tripsSnap = await getTripsForUser(userId);
  const trips: Trip[] = [];

  tripsSnap.forEach((trip) => {
    const tripData = trip.data() as TripData;
    const createdAtISO = tripData.createdAt
      ? (tripData.createdAt as Timestamp).toDate().toISOString()
      : '';

    // Convert Timestamp to ISOstring for lastOpened. Makes it serializable data that can save to redux
    const lastOpened: { [userId: string]: string } = {};
    Object.entries(tripData.lastOpened ?? {}).forEach(([uid, timestamp]: [string, Timestamp]) => {
      lastOpened[uid] = timestamp.toDate().toISOString();
    });

    trips.push({
      id: trip.id,
      ...tripData,
      createdAt: createdAtISO, // Store createdAt as an ISO string
      lastOpened,
      lists: [],
      cards: {},
      cardIdToListId: {},
      days: {},
      events: {},
      cardIdToEventId: {},
    });
  });

  return trips;
});

export interface TripCreateProps {
  place: string,
  location: LatLng,
  user: User,
}

export const createTrip = createAsyncThunk(
  'trip/create',
  async ({ place, location, user }: TripCreateProps): Promise<CurrentTrip> => {
    const tripDoc = await createTripDocumentAndLists(user.uid, {
      title: tripTitleForPlace(place),
      place,
      location,
      notes: emptyTemplate,
      createdAt: serverTimestamp(),
    });
    const lists = await getListsSnapshot(tripDoc.id);

    // Get the trip data from the document
    const tripData = tripDoc.data() as TripData;

    // Convert the createdAt field to an ISO string if it exists
    const createdAtISO = tripData.createdAt
      ? (tripData.createdAt as Timestamp).toDate().toISOString()
      : null;

    return {
      id: tripDoc.id,
      ...tripData,
      createdAt: createdAtISO, // Store createdAt as an ISO string for Redux
      lists,
      cards: {},
      cardIdToListId: {},
      days: {},
      events: {},
      cardIdToEventId: {},
      lastOpened: {},
    };
  }
);

export const fetchTrip = createAsyncThunk(
  'trip/retrieve',
  async ({ tripID, userID }: { tripID: TripID, userID?: string }): Promise<CurrentTrip> => {
    const tripDoc = await getTripDocument(tripID);
    if (!tripDoc.exists()) {
      throw new Error(`Trip with ID ${tripID} not found`);
    }

    const tripData = tripDoc.data() as TripData;

    const lists = await getListsSnapshot(tripID);
    const cards = await getCardsMapSnapshot(tripID);
    // Don't use old saved imgUrls since they expired from Google Places
    Object.values(cards).forEach(async (card) => {
      if (card.imgUrls) {
        // eslint-disable-next-line no-param-reassign
        delete card.imgUrls;
        await deleteFieldInCardInTrip(tripID, card.id, 'imgUrls');
      }
    });
    const cardIdToListId = getCardIdToListIdMap(lists);
    const days = await getDaysMapSnapshot(tripID, tripData.startDateISO, tripData.endDateISO);
    const events = await getEventsMapSnapshot(tripID);
    const cardIdToEventId: { [key: CardID]: EventID } = {};
    Object.values(events).forEach((event) => {
      if (cards[event.cardId]) {
        cards[event.cardId].isInItinerary = true;
        if (event.id) {
          cardIdToEventId[event.cardId] = event.id;
        }
      }
    });

    // Exclude createdAt and lastOpened from the rest of the trip data to prevent non-serializable data from being saved in redux
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { createdAt, lastOpened, ...restTripData } = tripData;

    // Convert the createdAt field to an ISO string so data can be serializable for Redux
    const createdAtISO = createdAt ? (createdAt as Timestamp).toDate().toISOString() : '';

    let lastOpenedISOString;
    if (userID) {
      const lastOpenedUpdate = await updateLastOpenedForUser(tripID, userID);
      lastOpenedISOString = lastOpenedUpdate.toDate().toISOString();
    }

    return {
      id: tripID,
      lists,
      cards,
      cardIdToListId,
      days,
      events,
      createdAt: createdAtISO,
      lastOpened: lastOpenedISOString ?? '',
      ...restTripData,
      cardIdToEventId,
    };
  }
);

export interface TripUpdateProps {
  tripId: TripID,
  field: string,
  value: any,
}

export const updateTrip = createAsyncThunk(
  'trip/updateTrip',
  async ({ tripId, field, value }: TripUpdateProps): Promise<TripUpdateProps> => {
    await updateTripField(tripId, field, value);
    return { tripId, field, value };
  }
);

export interface TripDeleteProps {
  tripId: TripID,
}

export const deleteTrip = createAsyncThunk(
  'trip/deleteTrip',
  async ({ tripId }: TripDeleteProps): Promise<TripDeleteProps> => {
    await deleteTripDocument(tripId);
    return { tripId };
  }
);

export interface TripListAddProps {
  tripId: TripID,
  list: ListData,
  listOrder: string[],
}

export interface TripListAddSuccess {
  tripId: TripID,
  list: TripList,
}

export const addList = createAsyncThunk(
  'trip/addList',
  async ({
    tripId,
    list,
    listOrder,
  }: TripListAddProps): Promise<{ tripId: string, list: TripList }> => {
    const listDoc = await createListInTrip(tripId, list, listOrder);
    const listData = listDoc.data() as ListData;

    // Convert the createdAt field to an ISO string if it exists
    const createdAtISO = listData.createdAt
      ? (listData.createdAt as Timestamp).toDate().toISOString()
      : null;

    // Exclude createdAt from listData to avoid duplication
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { createdAt, ...restListData } = listData;

    // Return the list in the correct format
    return {
      tripId,
      list: {
        id: listDoc.id,
        createdAt: createdAtISO,
        ...restListData, // Spread the rest of the listData
      } as TripList,
    };
  }
);

export interface TripListRemoveProps {
  tripId: TripID,
  listId: ListID,
  listOrder: ListID[],
  cardIds: CardID[],
}

export interface TripListRemoveSuccess {
  tripId: TripID,
  removedListId: ListID,
  deletedCardIds: CardID[],
  deletedEventIds: EventID[],
}

export const removeList = createAsyncThunk(
  'trip/removeList',
  async (
    { tripId, listId, listOrder, cardIds }: TripListRemoveProps,
    { getState }
  ): Promise<TripListRemoveSuccess> => {
    const state = getState() as { trip: { currentTrip: Trip } };
    const eventIdsToDelete = cardIds
      .map((cardId) => state.trip.currentTrip.cardIdToEventId[cardId])
      .filter(isEventID);
    const daysToUpdate: { [key: DayID]: EventID[] } = {};
    if (state.trip.currentTrip.days) {
      const eventIdsToDeleteSet = new Set(eventIdsToDelete);
      Object.values(state.trip.currentTrip.days).forEach((day) => {
        if (day.id && day.eventOrder) {
          const eventsOnDay = getIntersection(new Set(day.eventOrder), eventIdsToDeleteSet);
          daysToUpdate[day.id] = day.eventOrder.filter((eventId) => !eventsOnDay.has(eventId));
        }
      });
    }
    await removeListInTrip(tripId, listId, listOrder, cardIds, eventIdsToDelete, daysToUpdate);
    return {
      tripId,
      removedListId: listId,
      deletedCardIds: cardIds,
      deletedEventIds: eventIdsToDelete,
    };
  }
);

export interface TripListOrderUpdateProps {
  tripId: TripID,
  listOrder: ListID[],
  originalListOrder?: ListID[],
}

export const saveListOrder = createAsyncThunk(
  'trip/saveListOrder',
  async ({ tripId, listOrder, originalListOrder }: TripListOrderUpdateProps): Promise<void> => {
    if (!_.isEqual(listOrder, originalListOrder)) {
      await updateTripField(tripId, 'listOrder', listOrder);
    }
  }
);

export interface TripListUpdateProps {
  tripId: TripID,
  listId: ListID,
  field: string,
  value: any,
}

export interface TripListUpdateSuccess {
  listId: ListID,
  field: string,
  value: any,
}

export const updateList = createAsyncThunk(
  'trip/updateList',
  async ({ tripId, listId, field, value }: TripListUpdateProps): Promise<TripListUpdateSuccess> => {
    await updateListInTrip(tripId, listId, field, value);
    return { listId, field, value };
  }
);

export interface TripListCardAddProps {
  tripId: TripID,
  listId: ListID,
  card: CardData,
  cardOrder: string[],
}

export interface TripListCardAddSuccess {
  listId: ListID,
  card: TripCard,
}
export const addCardToList = createAsyncThunk(
  'trip/addCardToList',
  async ({
    tripId,
    listId,
    card,
    cardOrder,
  }: TripListCardAddProps): Promise<TripListCardAddSuccess> => {
    cleanObject(card);

    const cardDoc = await createCardInList(tripId, listId, card, cardOrder);
    const cardData = cardDoc.data() as CardData; // Cast cardData to CardData

    // Convert dateAdded to ISO string
    const dateAddedISO = cardData.dateAdded ? cardData.dateAdded.toDate().toISOString() : null;

    return {
      listId,
      card: {
        id: cardDoc.id,
        // Spread the rest of the card data, excluding dateAdded to avoid duplication
        ...cardData,
        dateAdded: dateAddedISO, // Set dateAdded as ISO string
      },
    };
  }
);

export interface TripListCardRemoveProps {
  tripId: TripID,
  listId: ListID,
  cardId: CardID,
  cardOrder: CardID[],
}

export interface TripListCardRemoveSuccess {
  listId: ListID,
  removedCardId: CardID,
}

export const removeCardFromList = createAsyncThunk(
  'trip/removeCardFromList',
  async (
    { tripId, listId, cardId, cardOrder }: TripListCardRemoveProps,
    { getState }
  ): Promise<TripListCardRemoveSuccess> => {
    const state = getState() as { trip: { currentTrip: Trip } };
    const eventIdToDelete = state.trip.currentTrip.cardIdToEventId[cardId];
    let dayToUpdate: { dayId: DayID, eventOrder: EventID[] } | undefined;
    if (state.trip.currentTrip.days) {
      Object.values(state.trip.currentTrip.days).forEach((day) => {
        if (
          !dayToUpdate &&
          day.id &&
          day.eventOrder &&
          day.eventOrder.find((eventId) => eventId === eventIdToDelete)
        ) {
          dayToUpdate = {
            dayId: day.id,
            eventOrder: day.eventOrder.filter((eventId) => eventId !== eventIdToDelete),
          };
        }
      });
    }
    await deleteCardFromList(tripId, listId, cardId, cardOrder, eventIdToDelete, dayToUpdate);
    return { listId, removedCardId: cardId };
  }
);

export interface TripListCardMoveProps {
  tripId: TripID,
  cardId: CardID,
  fromListId: ListID,
  toListId: ListID,
  fromListCardOrder: CardID[],
  toListCardOrder: CardID[],
}

export interface TripListCardMoveSuccess {
  movedCardId: CardID,
  fromListId: ListID,
  toListId: ListID,
}

export const moveCardToList = createAsyncThunk(
  'trip/moveCardToList',
  async ({
    tripId,
    cardId,
    fromListId,
    toListId,
    fromListCardOrder,
    toListCardOrder,
  }: TripListCardMoveProps): Promise<TripListCardMoveSuccess> => {
    await moveCard(tripId, cardId, fromListId, toListId, fromListCardOrder, toListCardOrder);
    return { movedCardId: cardId, fromListId, toListId };
  }
);

export interface TripListCardOrderUpdateProps {
  tripId: TripID,
  listId: ListID,
  cardOrder: CardID[],
  originalCardOrder?: CardID[],
}

export interface TripListCardOrderUpdateSuccess {
  listId: ListID,
}

export const saveCardOrderForActiveList = createAsyncThunk(
  'trip/saveCardOrderForActiveList',
  async ({
    tripId,
    listId,
    cardOrder,
    originalCardOrder,
  }: TripListCardOrderUpdateProps): Promise<TripListCardOrderUpdateSuccess> => {
    if (!_.isEqual(cardOrder, originalCardOrder)) {
      await updateListInTrip(tripId, listId, 'cardOrder', cardOrder);
    }
    return { listId };
  }
);

export interface TripCardUpdateProps {
  tripId: TripID,
  cardId: CardID,
  field: string,
  value: any,
}

export interface TripCardUpdateSuccess {
  cardId: CardID,
  field: string,
  value: any,
}

export const updateCard = createAsyncThunk(
  'trip/updateCard',
  async ({ tripId, cardId, field, value }: TripCardUpdateProps): Promise<TripCardUpdateSuccess> => {
    await updateCardInTrip(tripId, cardId, field, value);
    return { cardId, field, value };
  }
);

export interface TripCardNotesUpdateProps {
  tripId: TripID,
  cardId: CardID,
  notes: Descendant[],
}

export interface TripCardNotesUpdateSuccess {
  cardId: CardID,
  notes: Descendant[],
}

export const updateCardNotes = createAsyncThunk(
  'trip/updateCardNotes',
  async ({
    tripId,
    cardId,
    notes,
  }: TripCardNotesUpdateProps): Promise<TripCardNotesUpdateSuccess> => {
    await updateNotesForCard(tripId, cardId, notes);
    return { cardId, notes };
  }
);

interface TripEventAddToDateProps {
  tripId: TripID,
  dayId?: DayID,
  dateISO: DateISO,
  cardId: CardID,
  eventOrder?: string[],
}

interface TripEventAddToDateSuccess {
  tripId: TripID,
  dayId: DayID,
  dateISO: DateISO,
  cardId: CardID,
  eventId: EventID,
  eventOrder: EventID[],
}

export const addEventToDate = createAsyncThunk(
  'trip/addEventToDate',
  async ({
    tripId,
    dayId,
    dateISO,
    cardId,
    eventOrder,
  }: TripEventAddToDateProps): Promise<TripEventAddToDateSuccess> => {
    if (dayId) {
      const { eventId, eventOrder: newEventOrder } = await addEventToDayInTrip(
        tripId,
        dayId,
        eventOrder ?? [],
        {
          cardId,
          startDateISO: dateISO,
        }
      );
      return {
        tripId,
        dayId,
        dateISO,
        cardId,
        eventId,
        eventOrder: newEventOrder
      };
    }

    const { dayId: newDayId, eventId } = await createDayAndAddEventInTrip(
      tripId,
      { dateISO },
      { cardId, startDateISO: dateISO }
    );
    return {
      tripId,
      dayId:
      newDayId,
      dateISO,
      cardId,
      eventId,
      eventOrder: [eventId],
    };
  }
);

interface TripEventMoveToDateProps {
  tripId: TripID,
  fromDayId: DayID,
  toDayId?: DayID,
  fromDateISO: DateISO,
  toDateISO: DateISO,
  eventId: EventID,
  fromDayEventOrder: EventID[],
  toDayEventOrder?: EventID[],
}

interface TripEventMoveToDateSuccess {
  tripId: TripID,
  fromDayId: DayID,
  toDayId: DayID,
  fromDateISO: DateISO,
  toDateISO: DateISO,
  eventId: EventID,
  fromDayEventOrder: EventID[],
  toDayEventOrder: EventID[],
}

export const moveEventToDate = createAsyncThunk(
  'trip/moveEventToDate',
  async ({
    tripId,
    fromDayId,
    toDayId,
    fromDateISO,
    toDateISO,
    eventId,
    fromDayEventOrder,
    toDayEventOrder,
  }: TripEventMoveToDateProps): Promise<TripEventMoveToDateSuccess> => {
    const newFromDayEventOrder = fromDayEventOrder.filter((id) => eventId !== id);
    const newToDayEventOrder = (toDayEventOrder ?? []).concat([eventId]);
    if (toDayId) {
      await moveEventInTrip(
        tripId,
        fromDayId,
        toDayId,
        toDateISO,
        eventId,
        newFromDayEventOrder,
        newToDayEventOrder
      );
      return {
        tripId,
        fromDayId,
        toDayId,
        fromDateISO,
        toDateISO,
        eventId,
        fromDayEventOrder: newFromDayEventOrder,
        toDayEventOrder: newToDayEventOrder,
      };
    }

    const { toDayId: newDayId } = await moveEventToNewDayInTrip(
      tripId,
      fromDayId,
      toDateISO,
      eventId,
      newFromDayEventOrder,
      newToDayEventOrder
    );
    return {
      tripId,
      fromDayId,
      toDayId: newDayId,
      fromDateISO,
      toDateISO,
      eventId,
      fromDayEventOrder: newFromDayEventOrder,
      toDayEventOrder: newToDayEventOrder,
    };
  }
);

interface TripEventDeleteFromDateProps {
  tripId: TripID,
  eventId: EventID,
  cardId: CardID,
  dayId: DayID,
  dateISO: DateISO,
  eventOrder: string[],
}

interface TripEventDeleteFromDateSuccess {
  tripId: TripID,
  eventId: EventID,
  cardId: CardID,
  dateISO: DateISO,
}

export const deleteEventFromDate = createAsyncThunk(
  'trip/deleteEventFromDate',
  async ({
    tripId,
    eventId,
    cardId,
    dayId,
    dateISO,
    eventOrder,
  }: TripEventDeleteFromDateProps): Promise<TripEventDeleteFromDateSuccess> => {
    const newEventOrder = eventOrder.filter((id) => eventId !== id);
    await deleteEventInTrip(tripId, dayId, newEventOrder, eventId);
    return { tripId, eventId, cardId, dateISO };
  }
);

export interface TripDayEventOrderUpdateProps {
  tripId: TripID,
  dayId: DayID,
  dateISO: DateISO,
  eventOrder: EventID[],
  dragIndex: number,
  hoverIndex: number,
}

export interface TripDayEventOrderUpdateSuccess {
  tripId: TripID,
  dateISO: DayID,
}

export const updateEventOrderForDay = createAsyncThunk(
  'trip/updateEventOrderForDay',
  async ({
    tripId,
    dayId,
    dateISO,
    eventOrder,
    dragIndex,
    hoverIndex,
  }: TripDayEventOrderUpdateProps): Promise<TripDayEventOrderUpdateSuccess> => {
    const newEventOrder = [...eventOrder];
    const dragItem = newEventOrder.splice(dragIndex, 1)[0];
    newEventOrder.splice(hoverIndex, 0, dragItem);
    await updateDayInTrip(tripId, dayId, 'eventOrder', newEventOrder);
    return { tripId, dateISO };
  }
);

export interface TripRolesUpdateProps {
  tripId: TripID,
  rolesToUpdate: {
    userId: string,
    role: TripRole,
  }[],
  usersToDelete: string[],
}

export interface TripTempUpdateOrderProps {
  dragIndex: number,
  hoverIndex: number,
}

export interface TripUpdateCardImgUrlsProps {
  tripId: TripID,
  cardId: CardID,
  imgUrls: string[],
}

export interface TripUpdateMapCenterProps {
  lat: number,
  lng: number,
}

export interface TripUpdateLastOpenedProps {
  userId: string,
  lastOpened: Timestamp,
}

const tripSlice = createSlice({
  name: 'trip',
  initialState: INITIAL_STATE,
  reducers: {
    resetTripCreationStatus(state) {
      state.tripCreationStatus = TripCreationStatus.NOT_CREATED;
    },
    clearCurrentTrip(state) {
      state.currentTrip = undefined;
    },
    updateLastOpened: (state, action: PayloadAction<TripUpdateLastOpenedProps>) => {
      if (state.currentTrip && state.currentTrip.lastOpened) {
        const { userId, lastOpened } = action.payload;

        // Convert Firestore Timestamp to ISO string
        const lastOpenedISO: string = lastOpened.toDate().toISOString();

        state.currentTrip.lastOpened[userId] = lastOpenedISO;
      }
    },
    updateTripRoles(state, action: PayloadAction<TripRolesUpdateProps>) {
      const { tripId, rolesToUpdate, usersToDelete } = action.payload;
      if (state.currentTrip && state.currentTrip.id === tripId) {
        rolesToUpdate.forEach((userRole) => {
          if (state.currentTrip) {
            state.currentTrip.roles[userRole.userId] = userRole.role;
          }
        });
        usersToDelete.forEach((userId: string) => {
          if (state.currentTrip) {
            delete state.currentTrip.roles[userId];
          }
        });
      }
    },
    updateTripUserEmails(state, action: PayloadAction<string[]>) {
      if (state.currentTrip) {
        state.currentTrip.userEmails = action.payload;
      }
    },
    updateActiveListId(state, action: PayloadAction<string>) {
      if (state.currentTrip) {
        state.currentTrip.activeListId = action.payload;
      }
    },
    tempUpdateListOrder(state, action: PayloadAction<TripTempUpdateOrderProps>) {
      if (state.currentTrip) {
        if (!state.currentTrip.originalListOrder) {
          state.currentTrip.originalListOrder = [...state.currentTrip.listOrder];
        }
        const { dragIndex, hoverIndex } = action.payload;
        const dragItem = state.currentTrip.listOrder.splice(dragIndex, 1)[0];
        state.currentTrip.listOrder.splice(hoverIndex, 0, dragItem);
      }
    },
    tempUpdateCardOrder(state, action: PayloadAction<TripTempUpdateOrderProps>) {
      if (state.currentTrip && state.currentTrip.activeListId) {
        const list = getList(state.currentTrip.lists, state.currentTrip.activeListId);
        if (list && list.cardOrder) {
          if (!list.originalCardOrder) {
            list.originalCardOrder = [...list.cardOrder];
          }
          const { dragIndex, hoverIndex } = action.payload;
          const dragItem = list.cardOrder.splice(dragIndex, 1)[0];
          list.cardOrder.splice(hoverIndex, 0, dragItem);
        }
      }
    },
    updateCardImgUrls(state, action: PayloadAction<TripUpdateCardImgUrlsProps>) {
      const { tripId, cardId, imgUrls } = action.payload;
      if (state.currentTrip && state.currentTrip.id === tripId) {
        if (state.currentTrip.cards[cardId]) {
          state.currentTrip.cards[cardId].imgUrls = imgUrls;
        }
      }
    },
    updateMapCenter(state, action: PayloadAction<TripUpdateMapCenterProps>) {
      if (state.currentTrip) {
        state.currentTrip.mapCenter = action.payload;
      }
    },
    updateMapZoom(state, action: PayloadAction<number>) {
      if (state.currentTrip) {
        state.currentTrip.mapZoom = action.payload;
      }
    },
    setDefaultDays(state, { payload }: PayloadAction<ISetDefaultDaysPayload>) {
      if (state.currentTrip) {
        state.currentTrip.days = payload.days;
      }
    },
    clearDeletedEvents(state) {
      if (state.currentTrip) {
        state.currentTrip.deletedEvents = undefined;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrips.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTrips.fulfilled, (state, action) => {
        state.isLoading = false;
        state.trips = action.payload;
      })
      .addCase(createTrip.pending, (state) => {
        state.tripCreationStatus = TripCreationStatus.CREATING;
      })
      .addCase(createTrip.fulfilled, (state, action) => {
        state.tripCreationStatus = TripCreationStatus.CREATED;
        state.currentTrip = {
          activeListId: action.payload.lists[0].id,
          ...action.payload,
        };
        state.error = undefined;
      })
      .addCase(createTrip.rejected, (state, action) => {
        state.tripCreationStatus = TripCreationStatus.NOT_CREATED;
        state.error = action.error;
      })
      .addCase(fetchTrip.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTrip.fulfilled, (state, action) => {
        const { listOrder } = action.payload;
        let activeListId = listOrder && listOrder.length > 0 ? listOrder[0] : undefined;
        // Keep activeListId the same if it's the same trip as before and list still exists
        if (
          state.currentTrip &&
          state.currentTrip.activeListId &&
          listOrder &&
          listOrder.find((listId) => state.currentTrip && listId === state.currentTrip.activeListId)
        ) {
          activeListId = state.currentTrip.activeListId;
        }
        state.isLoading = false;
        state.currentTrip = {
          activeListId,
          ...action.payload,
        };
        state.error = undefined;
      })
      .addCase(updateTrip.fulfilled, (state, { payload: { tripId, field, value } }) => {
        const trip = getElementByIdInArray(state.trips, tripId) as Trip;
        if (trip && field in trip) {
          trip[field] = value;
        }
        if (state.currentTrip && state.currentTrip.id === tripId && field in state.currentTrip) {
          state.currentTrip[field] = value;
        }
      })
      .addCase(deleteTrip.fulfilled, (state, { payload: { tripId } }) => {
        state.trips = state.trips.filter((trip) => trip.id !== tripId);
      })
      .addCase(addList.fulfilled, (state, { payload: { tripId, list } }) => {
        if (state.currentTrip && state.currentTrip.id === tripId) {
          state.currentTrip.lists.unshift(list);
          state.currentTrip.listOrder.unshift(list.id);
          state.currentTrip.activeListId = list.id;
        }
      })
      .addCase(
        removeList.fulfilled,
        (state, { payload: { tripId, removedListId, deletedEventIds } }) => {
          if (state.currentTrip && state.currentTrip.id === tripId) {
            const removedList = state.currentTrip.lists.find((list) => list.id === removedListId);
            removedList?.cardOrder?.forEach((cardId) => {
              if (state.currentTrip) {
                delete state.currentTrip.cards[cardId];
                delete state.currentTrip.cardIdToListId[cardId];
                delete state.currentTrip.cardIdToEventId[cardId];
              }
            });
            state.currentTrip.lists = state.currentTrip.lists.filter(
              (list) => list.id !== removedListId
            );
            state.currentTrip.listOrder = state.currentTrip.listOrder.filter(
              (listId) => listId !== removedListId
            );
            if (state.currentTrip.activeListId === removedListId) {
              if (state.currentTrip.listOrder.length) {
                [state.currentTrip.activeListId] = state.currentTrip.listOrder;
              } else {
                state.currentTrip.activeListId = undefined;
              }
            }
            deletedEventIds.forEach((eventId) => {
              if (state.currentTrip) {
                delete state.currentTrip.events[eventId];
              }
            });
            const eventIdsSet = new Set(deletedEventIds);
            Object.values(state.currentTrip.days).forEach((day) => {
              // eslint-disable-next-line no-param-reassign
              day.eventOrder = day.eventOrder?.filter((eventId) => !eventIdsSet.has(eventId));
            });
          }
        }
      )
      .addCase(saveListOrder.fulfilled, (state) => {
        if (state.currentTrip) {
          state.currentTrip.originalListOrder = undefined;
        }
      })
      .addCase(updateList.fulfilled, (state, { payload: { listId, field, value } }) => {
        if (state.currentTrip) {
          const existingList = getList(state.currentTrip.lists, listId);
          if (existingList) {
            existingList[field] = value;
          }
        }
      })
      .addCase(addCardToList.fulfilled, (state, { payload: { listId, card } }) => {
        if (state.currentTrip) {
          const existingList = getList(state.currentTrip.lists, listId);
          if (existingList) {
            state.currentTrip.cards[card.id] = card;
            if (existingList.cardOrder) {
              existingList.cardOrder.unshift(card.id);
            } else {
              existingList.cardOrder = [card.id];
            }
          }
          state.currentTrip.cardIdToListId[card.id] = listId;
        }
      })
      .addCase(removeCardFromList.fulfilled, (state, { payload: { listId, removedCardId } }) => {
        if (state.currentTrip) {
          const existingList = getList(state.currentTrip.lists, listId);
          if (existingList) {
            delete state.currentTrip.cards[removedCardId];
            existingList.cardOrder = existingList.cardOrder
              ? existingList.cardOrder.filter((cardId: string) => cardId !== removedCardId)
              : undefined;
          }
          const eventIdToDelete = state.currentTrip.cardIdToEventId[removedCardId];
          if (eventIdToDelete) {
            delete state.currentTrip.events[eventIdToDelete];
          }
          Object.values(state.currentTrip.days).forEach((day) => {
            if (day.eventOrder && day.eventOrder.find((eventId) => eventId === eventIdToDelete)) {
              // eslint-disable-next-line no-param-reassign
              day.eventOrder = day.eventOrder.filter((eventId) => eventId !== eventIdToDelete);
            }
          });
          delete state.currentTrip.cardIdToListId[removedCardId];
        }
      })
      .addCase(
        moveCardToList.fulfilled,
        (state, { payload: { movedCardId, fromListId, toListId } }) => {
          if (state.currentTrip) {
            const fromList = getList(state.currentTrip.lists, fromListId);
            const toList = getList(state.currentTrip.lists, toListId);
            if (fromList?.cardOrder && toList) {
              fromList.cardOrder = fromList.cardOrder.filter(
                (cardId: string) => cardId !== movedCardId
              );
              if (toList.cardOrder) {
                toList.cardOrder.unshift(movedCardId);
              } else {
                toList.cardOrder = [movedCardId];
              }
            }
            state.currentTrip.cardIdToListId[movedCardId] = toListId;
          }
        }
      )
      .addCase(saveCardOrderForActiveList.fulfilled, (state, { payload: { listId } }) => {
        if (state.currentTrip) {
          const list = getList(state.currentTrip.lists, listId);
          if (list) {
            list.originalCardOrder = undefined;
          }
        }
      })
      .addCase(updateCard.fulfilled, (state, { payload: { cardId, field, value } }) => {
        if (state.currentTrip) {
          const existingCard = state.currentTrip.cards[cardId];
          if (existingCard) {
            existingCard[field] = value;
          }
        }
      })
      .addCase(updateCardNotes.fulfilled, (state, { payload: { cardId, notes } }) => {
        if (state.currentTrip) {
          const existingCard = state.currentTrip.cards[cardId];
          if (existingCard) {
            existingCard.notes = notes;
          }
        }
      })
      .addCase(
        updateDates.fulfilled,
        (state, {
          payload: {
            tripId,
            startDateISO,
            endDateISO,
            days,
            events,
            deletedEvents,
          }
        }) => {
          if (state.currentTrip && state.currentTrip.id === tripId) {
            state.currentTrip.startDateISO = startDateISO;
            state.currentTrip.endDateISO = endDateISO;
            state.currentTrip.days = days;
            state.currentTrip.events = events;
            Object.keys(state.currentTrip.cards).forEach((cardId) => {
              if (state.currentTrip) {
                state.currentTrip.cards[cardId].isInItinerary = false;
                delete state.currentTrip.cardIdToEventId[cardId];
              }
            });
            Object.values(events).forEach((event) => {
              if (state.currentTrip?.cards[event.cardId]) {
                state.currentTrip.cards[event.cardId].isInItinerary = true;
              }
            });
            state.currentTrip.isChangedDefaultDates = false;
            if (deletedEvents.length) {
              state.currentTrip.deletedEvents = deletedEvents;
            }
          }
        }
      )
      .addCase(
        addEventToDate.pending,
        (
          state,
          {
            meta: {
              arg: { tripId, dayId, dateISO, cardId, eventOrder },
            },
          }
        ) => {
          if (state.currentTrip && state.currentTrip.id === tripId) {
            const tripDay = state.currentTrip.days[dateISO];
            if (tripDay) {
              const newEventOrder = eventOrder ? eventOrder.concat([cardId]) : [cardId];
              tripDay.id = dayId;
              tripDay.eventOrder = newEventOrder;
            } else {
              state.currentTrip.days[dateISO] = {
                id: dayId,
                dateISO,
                eventOrder,
              };
            }

            state.currentTrip.events[cardId] = {
              id: cardId,
              cardId,
              startDateISO: dateISO,
              isConfirmed: false,
            };

            if (state.currentTrip.cards[cardId]) {
              state.currentTrip.cards[cardId].isInItinerary = true;
            }
          }
        }
      )
      .addCase(
        addEventToDate.fulfilled,
        (state, { payload: { tripId, dayId, dateISO, cardId, eventId } }) => {
          if (state.currentTrip && state.currentTrip.id === tripId) {
            const tripDay = state.currentTrip.days[dateISO];
            if (tripDay.eventOrder) {
              const index = tripDay.eventOrder.findIndex((value) => value === cardId);
              if (index !== -1) {
                tripDay.eventOrder[index] = eventId;
              }
            }

            state.currentTrip.events[eventId] = {
              id: eventId,
              cardId,
              startDateISO: dateISO,
              isConfirmed: true,
            };
            state.currentTrip.cardIdToEventId[cardId] = eventId;
            state.currentTrip.days[dateISO] = { ...tripDay, id: dayId };

            delete state.currentTrip.events[cardId];
          }
        }
      )
      .addCase(
        addEventToDate.rejected,
        (
          state,
          {
            meta: {
              arg: { tripId, dateISO, cardId, eventOrder },
            },
          }
        ) => {
          if (state.currentTrip && state.currentTrip.id === tripId) {
            const tripDay = state.currentTrip.days[dateISO];
            tripDay.eventOrder = eventOrder;
            delete state.currentTrip.events[cardId];
            if (state.currentTrip.cards[cardId]) {
              state.currentTrip.cards[cardId].isInItinerary = false;
            }
          }
        }
      )
      .addCase(
        moveEventToDate.pending,
        (
          state,
          {
            meta: {
              arg: {
                tripId,
                fromDayId,
                toDayId,
                fromDateISO,
                toDateISO,
                eventId,
                fromDayEventOrder,
                toDayEventOrder,
              },
            },
          }
        ) => {
          if (state.currentTrip?.id === tripId) {
            const newFromDayEventOrder = fromDayEventOrder.filter((id) => eventId !== id);
            const newToDayEventOrder = (toDayEventOrder ?? []).concat([eventId]);
            const fromDay = state.currentTrip.days[fromDateISO];
            const toDay = state.currentTrip.days[toDateISO];
            if (fromDay) {
              fromDay.eventOrder = newFromDayEventOrder;
            } else {
              state.currentTrip.days[fromDateISO] = {
                id: fromDayId,
                dateISO: fromDateISO,
                eventOrder: newFromDayEventOrder,
              };
            }
            if (toDay) {
              toDay.eventOrder = newToDayEventOrder;
            } else {
              state.currentTrip.days[toDateISO] = {
                id: toDayId,
                dateISO: toDateISO,
                eventOrder: newToDayEventOrder,
              };
            }

            state.currentTrip.events[eventId].startDateISO = toDateISO;
            state.currentTrip.events[eventId].isConfirmed = false;
          }
        }
      )
      .addCase(
        moveEventToDate.fulfilled,
        (state, { payload: { tripId, eventId, toDateISO, toDayId } }) => {
          if (state.currentTrip?.id === tripId) {
            state.currentTrip.events[eventId].isConfirmed = true;
            state.currentTrip.days[toDateISO] = {
              ...state.currentTrip.days[toDateISO],
              id: toDayId,
            };
          }
        }
      )
      .addCase(
        moveEventToDate.rejected,
        (
          state,
          {
            meta: {
              arg: {
                tripId,
                fromDayId,
                toDayId,
                fromDateISO,
                toDateISO,
                eventId,
                fromDayEventOrder,
                toDayEventOrder,
              },
            },
          }
        ) => {
          if (state.currentTrip?.id === tripId) {
            const fromDay = state.currentTrip.days[fromDateISO];
            const toDay = state.currentTrip.days[toDateISO];
            if (fromDay) {
              fromDay.eventOrder = fromDayEventOrder;
            } else {
              state.currentTrip.days[fromDateISO] = {
                id: fromDayId,
                dateISO: fromDateISO,
                eventOrder: fromDayEventOrder,
              };
            }
            if (toDay) {
              toDay.eventOrder = toDayEventOrder;
            } else {
              state.currentTrip.days[toDateISO] = {
                id: toDayId,
                dateISO: toDateISO,
                eventOrder: toDayEventOrder,
              };
            }

            state.currentTrip.events[eventId].startDateISO = fromDateISO;
            state.currentTrip.events[eventId].isConfirmed = true;
          }
        }
      )
      .addCase(
        deleteEventFromDate.pending,
        (
          state,
          {
            meta: {
              arg: { tripId, eventId, dateISO, eventOrder },
            },
          }
        ) => {
          if (state.currentTrip?.id === tripId) {
            const newEventOrder = eventOrder.filter((id) => eventId !== id);
            const day = state.currentTrip.days[dateISO];
            if (day) {
              day.eventOrder = newEventOrder;
            }
            const event = state.currentTrip.events[eventId];
            if (event) {
              state.currentTrip.cards[event.cardId].isInItinerary = false;
            }
          }
        }
      )
      .addCase(deleteEventFromDate.fulfilled, (state, { payload: { tripId, eventId, cardId } }) => {
        if (state.currentTrip && state.currentTrip.id === tripId) {
          delete state.currentTrip.events[eventId];
          delete state.currentTrip.cardIdToEventId[cardId];
        }
      })
      .addCase(
        deleteEventFromDate.rejected,
        (
          state,
          {
            meta: {
              arg: { tripId, eventId, dateISO, eventOrder },
            },
          }
        ) => {
          if (state.currentTrip?.id === tripId) {
            const day = state.currentTrip.days[dateISO];
            if (day) {
              day.eventOrder = eventOrder;
            }
            const event = state.currentTrip.events[eventId];
            if (event) {
              state.currentTrip.cards[event.cardId].isInItinerary = true;
            }
          }
        }
      )
      .addCase(signOutUser.fulfilled, () => INITIAL_STATE)
      .addCase(
        changeEventLength.fulfilled,
        (state: TripState, { payload }: PayloadAction<IChangeEventLengthPayload>) => {
          if (state.currentTrip && state.currentTrip.id === payload.tripId) {
            if (payload.eventLength) {
              state.currentTrip.events[payload.eventId].eventLength = payload.eventLength;
            } else {
              delete state.currentTrip.events[payload.eventId].eventLength;
            }
          }
        }
      )
      .addCase(
        changeDefaultDates.fulfilled,
        (state: TripState, { payload }: PayloadAction<IChangeDefaultDatesThunkResult>) => {
          if (state.currentTrip) {
            state.currentTrip.isChangedDefaultDates = true;
            state.currentTrip.startDateISO = payload.startDateISO;
            state.currentTrip.endDateISO = payload.endDateISO;
            state.currentTrip.days = payload.days;
            state.currentTrip.events = payload.events;
          }
        }
      )
      .addCase(
        updateEventOrderForDay.pending,
        (
          state,
          {
            meta: {
              arg: { tripId, dateISO, dragIndex, hoverIndex },
            },
          }
        ) => {
          if (state.currentTrip && state.currentTrip.id === tripId) {
            const day = state.currentTrip.days[dateISO];
            if (day && day.eventOrder) {
              const dragItem = day.eventOrder.splice(dragIndex, 1)[0];
              day.eventOrder.splice(hoverIndex, 0, dragItem);
            }
          }
        }
      )
      .addCase(
        updateEventOrderForDay.rejected,
        (
          state,
          {
            meta: {
              arg: { tripId, dateISO, eventOrder },
            },
          }
        ) => {
          if (state.currentTrip && state.currentTrip.id === tripId) {
            const day = state.currentTrip.days[dateISO];
            if (day) {
              day.eventOrder = eventOrder;
            }
          }
        }
      )
      .addMatcher(
        (action: AnyAction) => isRejected(action),
        (state, action) => {
          state.isLoading = false;
          state.error = action.error;
        }
      );
  },
});

export const {
  resetTripCreationStatus,
  clearCurrentTrip,
  updateTripRoles,
  updateActiveListId,
  updateTripUserEmails,
  tempUpdateListOrder,
  tempUpdateCardOrder,
  updateCardImgUrls,
  updateMapCenter,
  updateMapZoom,
  setDefaultDays,
  clearDeletedEvents,
} = tripSlice.actions;

export default tripSlice;
