import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import useMobileMediaQuery from '../../utils/media-query.utils';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { selectTripsList } from '../../redux/trip/trip.selectors';
import { fetchTrips } from '../../redux/trip/trip.slice';
import { isOwner } from '../../redux/trip/trip.utils';
import { Trip } from '../../redux/trip/trip.types';

import Spinner from '../spinner/spinner.component';
import TripsListItem from './trips-list-item/trips-list-item.component';
import SortByDropdown from './trips-list-sort-menu/trips-list-sort-menu.component';
import CreateTripModal from './create-trip-modal/create-trip-modal.component';

import {
  TripsListContainer,
  TripsListHeader,
  TripsListInfoContainer,
  TripsListSortMenuContainer,
  TripCount,
  SortBySpan,
} from './trips-list.styles';

function TripsList() {
  const dispatch = useAppDispatch();
  const isMobile = useMobileMediaQuery();
  const navigate = useNavigate();
  const currentUser = useAppSelector(selectCurrentUser);
  const tripsList = useAppSelector(selectTripsList);

  const [sortOption, setSortOption] = useState(
    sessionStorage.getItem('sortOption') || 'Last Opened'
  );
  const [sortedTripsList, setSortedTripsList] = useState<Trip[]>([]);

  const isLoading = !tripsList;

  if (!currentUser) {
    navigate('/login');
  }

  const cleanUpTitleInURL = (str: string) => str.replace(/\s+/g, '-').toLowerCase(); // removes spaces and adds hyphens to title in

  useEffect(() => {
    if (tripsList) {
      let sortedList = [...tripsList];

      if (sortOption === 'Alphabetical: A-Z') {
        // Sorting tripsList alphabetically by title
        sortedList = sortedList.sort((a, b) => a.title.localeCompare(b.title));
      } else if (sortOption === 'Last Opened') {
        // Sort trips list by lastOpened timestamps
        sortedList = sortedList.sort((a, b) => {
          const userId = currentUser?.uid;
          if (!userId) return 0;

          const aLastOpened = a.lastOpened?.[userId] ? Date.parse(a.lastOpened[userId]) : 0;
          const bLastOpened = b.lastOpened?.[userId] ? Date.parse(b.lastOpened[userId]) : 0;

          return bLastOpened - aLastOpened;
        });
      }
      setSortedTripsList(sortedList);
    }
  }, [sortOption, tripsList, currentUser]);

  useEffect(() => {
    if (currentUser) {
      dispatch(fetchTrips(currentUser.uid));
    }
  }, [currentUser, dispatch]);

  const tripCountText = tripsList.length === 1 ? '1 Trip' : `${tripsList.length} Trips`;

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <Helmet>
        <title>Wander | All Trips</title>
      </Helmet>
      <TripsListHeader isMobile={isMobile}>Adventures</TripsListHeader>
      <TripsListInfoContainer>
        <TripCount isMobile={isMobile}>{tripCountText}</TripCount>
        <TripsListSortMenuContainer isMobile={isMobile}>
          <SortBySpan isMobile={isMobile}>Sort By:</SortBySpan>
          <SortByDropdown sortOption={sortOption} setSortOption={setSortOption} />
        </TripsListSortMenuContainer>
      </TripsListInfoContainer>
      <TripsListContainer>
        {sortedTripsList &&
          sortedTripsList.map((trip) => (
            <TripsListItem
              key={trip.id}
              tripId={trip.id}
              title={trip.title}
              location={trip.place}
              startDateISO={trip?.startDateISO}
              endDateISO={trip?.endDateISO}
              userRoles={trip.roles}
              canDelete={isOwner(trip, currentUser)}
              onClick={() => navigate(`${trip.id}/${cleanUpTitleInURL(trip.title)}`)}
            />
          ))}

        <CreateTripModal />
      </TripsListContainer>
    </>
  );
}

TripsList.displayName = 'TripsList';
export default TripsList;
