/**
 *
 * Sidebar
 *
 */

import React, { useState, MouseEvent, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import Grow from '@material-ui/core/Grow';
import { ClickAwayListener } from '@mui/material';
import Picker, { IEmojiData } from 'emoji-picker-react';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch } from '../../../../redux/hooks';
import {
  saveListOrder,
  tempUpdateListOrder,
  updateActiveListId,
  updateList,
} from '../../../../redux/trip/trip.slice';

import MobileRenameListInput from '../rename-list-input/rename-list-input.component';
import { ItemType } from '../../../dnd/dnd.types';
import { TripList } from '../../../../redux/trip/trip.types';
import useMobileMediaQuery from '../../../../utils/media-query.utils';
import ListOptions from './list-options/list-options.component';

import {
  EmojiPickerDiv,
  ListItemContainerDiv,
  ListItemDiv,
  ListItemInfo,
  ListItemEmoji,
  ListItemName,
  ListOptionsDiv,
  StyledInput,
} from './list-item.styles';

type ListItemProps = {
  tripId: string,
  list: TripList,
  listOrder: string[],
  originalListOrder?: string[],
  isActive?: boolean,
  canEdit?: boolean,
  moveCard: (cardId: string, fromListId: string, toListId: string) => void,
};

interface ListDragItem {
  type: string,
  id: string,
  index: number,
}

function ListItem({
  tripId,
  list,
  listOrder,
  originalListOrder,
  isActive = false,
  canEdit = false,
  moveCard,
}: ListItemProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useMobileMediaQuery();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingEmoji, setIsEditingEmoji] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const index = listOrder.indexOf(list.id);

  const handleOptionsOpen = (event: MouseEvent<HTMLDivElement>) => {
    // prevents event propogation to avoid closing the active list when clicking on list options button
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const [, listDropRef] = useDrop<ListDragItem, void, void>({
    accept: ItemType.LIST,
    hover: (item: ListDragItem) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      dispatch(tempUpdateListOrder({ dragIndex, hoverIndex }));

      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
    drop: () => {
      dispatch(saveListOrder({ tripId, listOrder, originalListOrder }));
    },
  });

  const [{ isDragging }, listDragRef] = useDrag({
    item: { type: ItemType.LIST, id: list.id, index },
    canDrag: () => canEdit === true && !isEditingName,
    collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
  });

  const [{ isOver, canDrop }, cardDropRef] = useDrop({
    accept: ItemType.CARD,
    drop: (item: { type: string, id: string, listId: string }) => {
      moveCard(item.id, item.listId, list.id);
    },
    canDrop: () => !isActive,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const onUpdateListName = (input: HTMLInputElement) => {
    const newListName = input.value;
    if (newListName.length !== 0 && newListName !== list.name)
      dispatch(
        updateList({
          tripId,
          listId: list.id,
          field: 'name',
          value: newListName,
        })
      );
    setIsEditingName(false);
  };

  const editListName = () => setIsEditingName(true);

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const maxCharacterCount = 100;
    const listItem = event.target as HTMLInputElement;
    const text = listItem.value;

    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent creating a new line
      onUpdateListName(listItem);
    }

    if (
      text.length >= maxCharacterCount &&
      event.key !== 'Backspace' &&
      event.key !== 'ArrowLeft' &&
      event.key !== 'ArrowRight'
    ) {
      event.preventDefault(); // Prevent further input
    }
  };

  const handleListEmojiEdit = canEdit
    ? (event: React.MouseEvent<HTMLLIElement>) => {
        event.stopPropagation(); // Stop event propagation to prevent closing the menu
        setAnchorEl(null);
        setTimeout(() => setIsEditingEmoji(true), 0);
      }
    : undefined;

  const onEmojiClick = (event: MouseEvent, emojiObject: IEmojiData) => {
    event.stopPropagation(); // Stop event propagation to prevent closing the menu

    setIsEditingEmoji(false);
    dispatch(
      updateList({
        tripId,
        listId: list.id,
        field: 'emoji',
        value: emojiObject.emoji,
      })
    );
  };

  const handleListClick = () => {
    dispatch(updateActiveListId(list.id));
    searchParams.set('list', list.id);
    setSearchParams(searchParams);
  };

  listDragRef(listDropRef(cardDropRef(ref)));

  return (
    <>
      <ListItemContainerDiv
        ref={ref}
        selected={isActive}
        isOver={isOver && canDrop}
        isDragging={isDragging}
        isMobile={isMobile}
      >
        <ListItemDiv key={list.id}>
          {isMobile && isEditingName ? (
            <MobileRenameListInput
              tripId={tripId}
              list={list}
              canEdit={canEdit}
              isEditingName={isEditingName}
              setIsEditingName={setIsEditingName}
            />
          ) : (
            <ListItemInfo>
              {isMobile ? (
                <ListItemEmoji>{list.emoji}</ListItemEmoji>
              ) : (
                <ListItemEmoji onClick={handleListEmojiEdit}>{list.emoji}</ListItemEmoji>
              )}{' '}
              <ListItemName selected={isActive} onClick={handleListClick}>
                {list.name}
              </ListItemName>
            </ListItemInfo>
          )}
          {isEditingEmoji && (
            <ClickAwayListener onClickAway={() => setIsEditingEmoji(false)}>
              <EmojiPickerDiv onClick={(e) => e.stopPropagation()} isMobile={isMobile}>
                <Picker onEmojiClick={onEmojiClick} />
              </EmojiPickerDiv>
            </ClickAwayListener>
          )}
        </ListItemDiv>
        {!isMobile && isEditingName && (
          <Grow
            in={isEditingName}
            mountOnEnter
            unmountOnExit
            timeout={{
              enter: 800,
              exit: 400,
            }}
          >
            <StyledInput
              defaultValue={list.name}
              onBlur={(event) => onUpdateListName(event.target)}
              onKeyDown={handleOnKeyDown}
              autoFocus
              readOnly={!canEdit}
            />
          </Grow>
        )}
        {canEdit && isActive && !isEditingName && (
          <ListOptionsDiv onClick={handleOptionsOpen} isMobile={isMobile}>
            •••
          </ListOptionsDiv>
        )}
      </ListItemContainerDiv>

      <ListOptions
        tripId={tripId}
        list={list}
        listOrder={listOrder}
        isMobile={isMobile}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        editListName={editListName}
        handleListEmojiEdit={handleListEmojiEdit}
      />
    </>
  );
}

ListItem.displayName = 'ListItem';
export default ListItem;
