import styled from 'styled-components';

type ListItemContainerDivProps = {
  selected: boolean,
  isDragging: boolean,
  isOver: boolean,
  isMobile: boolean,
};

export const ListItemContainerDiv = styled.div<ListItemContainerDivProps>`
  position: ${(props) => (props.selected ? 'sticky' : '')};
  top: ${(props) => (props.selected ? '0' : '')};
  bottom: ${(props) => (props.selected ? '-1em' : '')};
  background-color: ${(props) => (props.selected && !props.isMobile ? '#A7E0E4' : '')};
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: ${(props) => (props.isMobile ? '0 0.25rem 0 0.25rem' : '0.65rem 1.5rem 0.65rem 3rem')};
  border-top: 2px solid ${(props) => (props.isOver ? '#1CB6C1;' : 'transparent')};
  border-bottom: 2px solid ${(props) => (props.isOver ? '#1CB6C1;' : 'transparent')};
  opacity: ${(props) => (props.isDragging ? '0' : '1')};
  z-index: ${(props) => (props.selected ? '1' : '')};
  border-radius: ${(props) => (props.isMobile ? '' : '0 10px 10px 0')};
`;

export const ListItemDiv = styled.div`
  position: relative;
  user-select: none;
  cursor: pointer;
`;

export const ListItemInfo = styled.div`
  display: flex;
  align-items: flex-start;
`;

type ListItemNameProps = {
  selected: boolean,
};

export const ListItemName = styled.span<ListItemNameProps>`
  font-size: 1rem;
  font-weight: ${(props) => (props.selected ? '600' : 'normal')};

  :focus {
    font-weight: lighter;
  }

  :hover {
    font-weight: 600;
  }
`;

export const ListItemEmoji = styled.span`
  font-size: 1.15rem;
  padding-right: 10px;
`;

type MobileProp = {
  isMobile: boolean,
};

export const EmojiPickerDiv = styled.div<MobileProp>`
  position: fixed;
  z-index: 10;
  transform: ${(props) => (props.isMobile ? 'translate(-50%, 50%)' : 'translate(24px, 4px)')};

  ${(props) =>
    props.isMobile &&
    `
    top: 25%;
    left: 50%;
  `}
`;

type ListOptionsDivProps = {
  isMobile: boolean,
};
export const ListOptionsDiv = styled.div<ListOptionsDivProps>`
  color: #141414;
  ${(props) => (props.isMobile ? '#141414' : '')}
  opacity: ${(props) => (props.isMobile ? '1' : '0')};
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0px;
  cursor: pointer;
  transform: rotate(90deg);
  white-space: nowrap;
  align-items: flex-start;

  ${(props) =>
    !props.isMobile &&
    `
    ${ListItemContainerDiv}:hover & {
      opacity: 1;
    }
  `}

  transition: opacity 0.5s;
`;

export const StyledInput = styled.input`
  position: absolute;
  color: #141414;
  border: none;
  border-bottom: 0.15rem solid black;
  background-color: white;
  outline: none;
`;
