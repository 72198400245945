import styled, { css } from 'styled-components';
import { Button, ListItemIcon, ListItemText } from '@mui/material';

type MobileProp = {
  isMobile: boolean,
};

export const TripsListItemContainer = styled.div<MobileProp>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.isMobile ? '16' : '24')}px;
  width: ${(props) => (props.isMobile ? '170' : '320')}px;
  height: ${(props) => (props.isMobile ? '170' : '300')}px;
  font-size: 0.8rem;
  opacity: 0.9;

  background: #fafafa;
  border: ${(props) => (props.isMobile ? '2' : '4')}px solid #141414;
  border-radius: 10px;
  box-sizing: border-box;

  &:hover {
    color: #fafafa;
    background: #000000;
    cursor: pointer;
  }
`;

export const TripsListTitle = styled.div<MobileProp>`
  max-width: 95%;
  font-size: ${(props) => (props.isMobile ? '14' : '24')}px;
  font-weight: 700;

  ${(props) => {
    if (props.isMobile) {
      return css`
        line-height: 18px;
        letter-spacing: 0.32px;
        max-height: 54px;
      `;
    }
    return '';
  }}

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TripsListMoreOptionsContainer = styled.div<MobileProp>`
  position: absolute;
  right: ${(props) => (props.isMobile ? '-16' : '-10')}px;
  top: ${(props) => (props.isMobile ? '0' : '8')}px;
`;

export const TripsListLocation = styled.div<MobileProp>`
  font-size: ${(props) => (props.isMobile ? '12' : '16')}px;
  padding-top: ${(props) => (props.isMobile ? '10' : '14')}px;
  margin-left: -3px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TripsListDates = styled.div<MobileProp>`
  font-size: ${(props) => (props.isMobile ? '12' : '18')}px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.28px;
  padding-top: ${(props) => (props.isMobile ? '5' : '16')}px;
`;

export const TripsListAvatarsGroup = styled.div<MobileProp>`
  position: absolute;
  left: ${(props) => (props.isMobile ? '' : '24px')};
  bottom: ${(props) => (props.isMobile ? '16' : '24')}px;
`;

export const TripsListMoreButton = styled(Button)`
  color: inherit;
`;

export const TripsListItemDeleteItemIcon = styled(ListItemIcon)`
  color: rgb(219, 88, 88, 1);
`;

export const TripsListItemDeleteItemText = styled(ListItemText)`
  color: rgb(219, 88, 88, 1);
`;
