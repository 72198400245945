import { useEffect, useState } from 'react';
import { getDetails } from 'use-places-autocomplete';
import { TripCard, TripID } from '../../redux/trip/trip.types';
import { useAppDispatch } from '../../redux/hooks';
import { useTaskQueue } from '../queue.hooks';
import { updateCardImgUrls } from '../../redux/trip/trip.slice';

type QueuedMap = { [key: string]: boolean };

function hasPhotos(
  details: google.maps.places.PlaceResult | string
): details is google.maps.places.PlaceResult {
  return (details as google.maps.places.PlaceResult).photos !== undefined;
}

function timeout(ms: number) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// eslint-disable-next-line import/prefer-default-export
export function usePlaceImgUrls(tripId: TripID | undefined, cards: TripCard[]) {
  const dispatch = useAppDispatch();
  const { addTask } = useTaskQueue({ shouldProcess: true });
  const [queued, setQueued] = useState<QueuedMap>({});

  useEffect(() => {
    if (tripId && cards) {
      // eslint-disable-next-line no-restricted-syntax
      for (const { id, googlePlaceId, imgUrls } of Object.values(cards)) {
        if (!imgUrls && !queued[id]) {
          addTask(async () => {
            const urls = [];
            const details = await getDetails({ placeId: googlePlaceId, fields: ['photos'] });
            if (hasPhotos(details)) {
              const { photos } = details;
              if (photos) {
                // eslint-disable-next-line no-plusplus
                for (let idx = 0; idx < photos.length && idx < 8; idx++) {
                  urls.push(
                    photos[idx].getUrl({
                      maxWidth: 1600,
                      maxHeight: 1600,
                    })
                  );
                }

                dispatch(
                  updateCardImgUrls({
                    tripId,
                    cardId: id,
                    imgUrls: urls,
                  })
                );
              }
            }
            await timeout(1000);
          });
          queued[id] = true;
          setQueued(queued);
        }
      }
    }
  }, [tripId, cards, queued, setQueued, dispatch, addTask]);
}

export function usePlaceImgUrlsForCard(tripId: TripID | undefined, card: TripCard) {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    async function fetchImgUrls() {
      if (tripId) {
        // eslint-disable-next-line no-restricted-syntax
        const { id, googlePlaceId, imgUrls } = card;
        if (!imgUrls) {
          setIsLoading(true);
          try {
            const urls = [];
            const details = await getDetails({ placeId: googlePlaceId, fields: ['photos'] });
            if (hasPhotos(details)) {
              const { photos } = details;
              if (photos) {
                // eslint-disable-next-line no-plusplus
                for (let idx = 0; idx < photos.length && idx < 8; idx++) {
                  urls.push(
                    photos[idx].getUrl({
                      maxWidth: 1600,
                      maxHeight: 1600,
                    })
                  );
                }

                dispatch(
                  updateCardImgUrls({
                    tripId,
                    cardId: id,
                    imgUrls: urls,
                  })
                );
                setIsLoading(false);
              } else {
                setIsLoading(false);
              }
            } else {
              setIsLoading(false);
            }
          } catch (error) {
            setIsLoading(false);
          }
        }
      }
    }

    fetchImgUrls();
  }, [tripId, card, dispatch, setIsLoading]);

  return isLoading;
}
