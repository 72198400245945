import { CardID, ListID, TripCard, TripList } from '../../redux/trip/trip.types';
import { getList } from '../../redux/trip/trip.utils';
import { Marker, MarkerType } from '../marker/marker.types';

function isMarker(markerOrUndefined: Marker | undefined): markerOrUndefined is Marker {
  return markerOrUndefined !== undefined;
}

function isMarkerArray(
  markerArrayOrUndefined: (Marker | undefined)[] | undefined
): markerArrayOrUndefined is (Marker | undefined)[] {
  return markerArrayOrUndefined !== undefined;
}

// eslint-disable-next-line import/prefer-default-export
export function getMarkersFromCardsAndExtendBounds(
  cards: TripCard[],
  lists: TripList[],
  cardIdToListId: { [key: CardID]: ListID },
  bounds: google.maps.LatLngBounds,
  highlightedCardId?: string | null
) {
  return cards
    .map((card) => {
      if (card.location) {
        const cardLocation = {
          lat: card.location.latitude,
          lng: card.location.longitude,
        };
        bounds.extend(cardLocation);
        const listId = cardIdToListId[card.id];
        const list = getList(lists, listId);
        return {
          id: card.id,
          markerType: MarkerType.EMOJI,
          location: cardLocation,
          data: {
            emoji: list?.emoji,
            description: card.customName ?? card.name,
            isHighlighted: card.id === highlightedCardId,
          },
        } as Marker;
      }
      return undefined;
    })
    .filter(isMarker);
}

export function getMarkersForCardsInListsAndExtendBounds(
  lists: TripList[],
  cards: { [key: CardID]: TripCard },
  bounds: google.maps.LatLngBounds,
  highlightedCardId?: string | null
) {
  let didBoundsUpdate = false;
  const markers = lists
    .map((list) =>
      list.cardOrder?.map((cardId) => {
        const card = cards[cardId];
        if (card && card.location) {
          const cardLocation = {
            lat: card.location.latitude,
            lng: card.location.longitude,
          };
          bounds.extend(cardLocation);
          didBoundsUpdate = true;
          return {
            id: card.id,
            markerType: MarkerType.EMOJI,
            location: cardLocation,
            data: {
              emoji: list.emoji,
              description: card.customName ?? card.name,
              isHighlighted: card.id === highlightedCardId,
            },
          } as Marker;
        }
        return undefined;
      })
    )
    .filter(isMarkerArray)
    .reduce((previousValue, currentValue) => previousValue?.concat(currentValue), [])
    .filter(isMarker);

  return { markers, didBoundsUpdate };
}
