import React, { Fragment, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useDrop } from 'react-dnd';
import { ItemType } from '../../../dnd/dnd.types';

import { useAppSelector } from '../../../../redux/hooks';
import { selectCards, selectLists } from '../../../../redux/trip/trip.selectors';
import {
  CardID,
  EventID,
  TripCard,
  TripDay,
  TripEvent,
  TripID,
} from '../../../../redux/trip/trip.types';
import { getOpeningHours } from '../../../card/card.utils';
import { getEmojiAndColorForCard } from '../../../../utils/itinerary.utils';
import SortableItineraryEvent from '../itinerary-event/sortable-itinerary-event.component';
import { EItineraryEvents } from './itinerary-events.types';

import {
  ItineraryEventsContainer,
  ItineraryEventsDialogDayContainer,
  ItineraryEventsDialogHoursContainer,
  ItineraryEventsDialogTimeContainer,
} from './itinerary-events.styles';

interface ItineraryEventsProps {
  tripId: TripID,
  tripDay: TripDay,
  events?: TripEvent[],
  col: number,
  canEdit?: boolean,
  createEventForDate: (
    dayId: string | undefined,
    dateISO: string,
    cardId: CardID,
    eventOrder: string[] | undefined
  ) => void,
  moveEventToDate: (
    dayId: string | undefined,
    toDateISO: string,
    eventId: EventID,
    toDateEventOrder: string[] | undefined
  ) => void,
}

function isOpenOnDate(card: TripCard, day: TripDay) {
  const date = parseISO(day.dateISO);
  const dayOfWeek = format(date, 'EEEE');
  if (card.openingHours) {
    const dayOfWeekHours = card.openingHours.filter(
      (openingHour) => openingHour.indexOf(dayOfWeek) !== -1
    );
    if (dayOfWeekHours.length) {
      return dayOfWeekHours[0].match(/closed/i) === null;
    }
  }
  return true;
}

interface PendingItineraryEvent {
  card: TripCard,
  event?: TripEvent,
}

function ItineraryEvents({
  tripId,
  tripDay,
  events,
  col,
  canEdit,
  createEventForDate,
  moveEventToDate,
}: ItineraryEventsProps) {
  const cards = useAppSelector(selectCards);
  const lists = useAppSelector(selectLists);
  const eventOrder = tripDay?.eventOrder;
  const ref = useRef<HTMLDivElement>(null);

  const [closedOnDayEvent, setClosedOnDayEvent] = useState<PendingItineraryEvent | undefined>(
    undefined
  );

  const [, cardDropRef] = useDrop({
    accept: ItemType.CARD,
    drop: (item: { type: string, card: TripCard }) => {
      if (isOpenOnDate(item.card, tripDay)) {
        createEventForDate(tripDay.id, tripDay.dateISO, item.card.id, tripDay.eventOrder);
      } else {
        setClosedOnDayEvent({ card: item.card });
      }
    },
    collect: (monitor) => ({ isOver: monitor.isOver() }),
  });
  const [, eventDropRef] = useDrop({
    accept: ItemType.EVENT,
    drop: (item: { type: string, event: TripEvent, card: TripCard }) => {
      if (item.event.id && !events?.find((event) => event.id === item.event.id)) {
        if (isOpenOnDate(item.card, tripDay)) {
          moveEventToDate(tripDay.id, tripDay.dateISO, item.event.id, tripDay.eventOrder);
        } else {
          setClosedOnDayEvent({ card: item.card, event: item.event });
        }
      }
    },
    collect: (monitor) => ({ isOver: monitor.isOver() }),
  });

  eventDropRef(cardDropRef(ref));

  const handleCloseDialog = () => {
    setClosedOnDayEvent(undefined);
  };

  const handleConfirmEvent = () => {
    if (closedOnDayEvent) {
      if (closedOnDayEvent.event && closedOnDayEvent.event.id) {
        moveEventToDate(tripDay.id, tripDay.dateISO, closedOnDayEvent.event.id, tripDay.eventOrder);
      } else {
        createEventForDate(
          tripDay.id,
          tripDay.dateISO,
          closedOnDayEvent.card.id,
          tripDay.eventOrder
        );
      }
    }
    setClosedOnDayEvent(undefined);
  };

  return (
    <>
      <ItineraryEventsContainer
        ref={ref}
        col={col}
        gap={EItineraryEvents.gridGap}
        containerPadding={EItineraryEvents.containerPadding}
      >
        {events?.map((event) => {
          if (!event.eventLength) {
            if (cards && lists && cards[event.cardId]) {
              const { emoji, color } = getEmojiAndColorForCard(event.cardId, lists);
              return (
                <SortableItineraryEvent
                  key={event.id}
                  tripId={tripId}
                  event={event}
                  card={cards[event.cardId]}
                  dayId={tripDay.id}
                  emoji={emoji}
                  color={color}
                  eventOrder={eventOrder}
                  canEdit={canEdit}
                />
              );
            }
          }
          return undefined;
        })}
      </ItineraryEventsContainer>

      {closedOnDayEvent !== undefined && (
        <Dialog open={closedOnDayEvent !== undefined} onClose={handleCloseDialog}>
          <DialogTitle>
            Heads up! It looks like {closedOnDayEvent.card.customName ?? closedOnDayEvent.card.name}{' '}
            is closed on this day.
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {closedOnDayEvent.card.openingHours ? ' Their hours are:' : ''}
              {closedOnDayEvent.card.openingHours && (
                <ItineraryEventsDialogHoursContainer>
                  {getOpeningHours(closedOnDayEvent.card.openingHours).map(
                    (openingHours, dayIndex) => {
                      const dayOfWeek = Object.keys(openingHours)[0];
                      return (
                        <Fragment key={`${closedOnDayEvent.card.id}-${dayOfWeek}`}>
                          <ItineraryEventsDialogDayContainer row={dayIndex + 1}>
                            {dayOfWeek}:
                          </ItineraryEventsDialogDayContainer>
                          <ItineraryEventsDialogTimeContainer row={dayIndex + 1}>
                            {openingHours[dayOfWeek].map((hours, index) => (
                              <span
                                // eslint-disable-next-line react/no-array-index-key
                                key={`${closedOnDayEvent.card.id}-hours-${dayOfWeek}-${index}`}
                              >
                                {hours}
                              </span>
                            ))}
                          </ItineraryEventsDialogTimeContainer>
                        </Fragment>
                      );
                    }
                  )}
                </ItineraryEventsDialogHoursContainer>
              )}
              Do you still want to {closedOnDayEvent?.event ? 'move' : 'add'} it?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>No</Button>
            <Button onClick={handleConfirmEvent}>Yes</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

ItineraryEvents.displayName = 'ItineraryEvents';
export default ItineraryEvents;
