/* eslint-disable no-nested-ternary */
import React from 'react';
import Avatar from '@mui/material/Avatar';
import useMobileMediaQuery from '../../utils/media-query.utils';

const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

const stringAvatar = (
  string: string,
  capitalize: boolean,
  remainingRolesCount: number | undefined,
  isMobile: boolean
) => {
  if (remainingRolesCount) {
    const isTwoDigits = remainingRolesCount >= 10 && remainingRolesCount < 100;
    const isThreeDigits = remainingRolesCount > 99;
    const fontSize = isTwoDigits || isThreeDigits ? (isMobile ? '12px' : '14px') : undefined;
    const childrenText = isThreeDigits ? '99+' : `+${remainingRolesCount}`;
    return {
      sx: {
        fontSize: fontSize ? `${fontSize} !important` : undefined,
        color: '#141414',
        background: '#FFF',
        border: '1.5px solid #141414',
      },
      children: childrenText,
    };
  }

  const finalString = capitalize ? string.toUpperCase() : string;
  return {
    sx: { bgcolor: stringToColor(finalString) },
    children: finalString.charAt(0),
  };
};

type LetterAvatarProps = {
  string: string,
  capitalize?: boolean,
  remainingRolesCount?: number,
};

function LetterAvatar({ string, capitalize = false, remainingRolesCount = 0 }: LetterAvatarProps) {
  const isMobile = useMobileMediaQuery();
  const isTripsList = window.location.pathname === '/trips';
  const avatarSize = isMobile ? (isTripsList ? '28px' : '32px') : '40px';
  const fontSize = isMobile ? (isTripsList ? '14px' : '16px') : '18px';

  return (
    <Avatar
      {...stringAvatar(string, capitalize, remainingRolesCount, isMobile)}
      style={{ width: avatarSize, height: avatarSize, fontSize }}
    />
  );
}

LetterAvatar.displayName = 'LetterAvatar';
export default LetterAvatar;
