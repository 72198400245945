import React from 'react';
import { Helmet } from 'react-helmet';
import { useAppSelector } from '../../redux/hooks';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { selectTripsList } from '../../redux/trip/trip.selectors';
import useMobileMediaQuery from '../../utils/media-query.utils';
import {
  ForbiddenErrorContainer,
  ForbiddenErrorImage,
  ForbiddenErrorMessage,
  ForbiddenErrorSubText,
  ForbiddenErrorButton,
} from './forbidden-error-403.styles';

function ForbiddenError403() {
  const isMobile = useMobileMediaQuery();
  const currentUser = useAppSelector(selectCurrentUser);
  const tripsList = useAppSelector(selectTripsList);
  /**
   * Determine Route for 'Head Back Home' Button
   * - if currentUser is null, route to sign up page
   * - if currentUser && tripsList.length > 0, route to tripsList
   * - if currentUser && tripsList.length === 0, route to home
   */
  let route;
  if (currentUser && tripsList.length === 0) {
    route = '/';
  } else if (currentUser && tripsList.length > 1) {
    route = '/trips';
  } else {
    route = '/login';
  }
  return (
    <>
      <Helmet>
        <title> Forbidden Error 403</title>
      </Helmet>
      <ForbiddenErrorContainer isMobile={isMobile}>
        {isMobile ? (
          <ForbiddenErrorImage
            src="/svgs/403-mobile.png"
            alt="403 Forbidden Error"
            isMobile={isMobile}
          />
        ) : (
          <ForbiddenErrorImage src="/svgs/403.png" alt="403 Forbidden Error" isMobile={isMobile} />
        )}
        <ForbiddenErrorMessage isMobile={isMobile}>
          You&apos;ve wandered into restricted territory
        </ForbiddenErrorMessage>
        <ForbiddenErrorSubText isMobile={isMobile}>
          *request access from the owner of this trip
        </ForbiddenErrorSubText>
        <ForbiddenErrorButton href={route} isMobile={isMobile}>
          Head Back Home
        </ForbiddenErrorButton>
      </ForbiddenErrorContainer>
    </>
  );
}

ForbiddenError403.displayName = 'ForbiddenError403';
export default ForbiddenError403;
