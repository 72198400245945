import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TripCard } from '../../redux/trip/trip.types';
import { usePlaceImgUrlsForCard } from '../../hooks/trip/trip.hooks';
import CardDetailsModalMobile from '../card-details-modal/card-details-modal-mobile/card-details-modal-mobile.component';

import {
  CardContainer,
  CardImgContainer,
  CardDescriptionContainer,
  CardImg,
  CardName,
  Exit,
} from './single-card.styles';

export interface CardProps {
  card: TripCard,
  tripId: string,
  canEdit?: boolean,
  handleClose?: () => void,
}

function SingleCard({ card, tripId, canEdit = false, handleClose }: CardProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  usePlaceImgUrlsForCard(tripId, card);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => {
    setModalOpen(false);
    searchParams.delete('card');
    searchParams.delete('card-tab');
    setSearchParams(searchParams);
  };

  return (
    <>
      <CardContainer onClick={handleModalOpen}>
        <CardImgContainer>
          <CardImg
            src={
              card.imgUrls && card.imgUrls.length > 0
                ? card.imgUrls[card.defaultImage ?? 0]
                : undefined
            }
          />
        </CardImgContainer>
        <CardDescriptionContainer>
          <CardName>{card.customName ?? card.name}</CardName>
          <Exit onClick={handleClose} />
        </CardDescriptionContainer>
      </CardContainer>

      {modalOpen && (
        <CardDetailsModalMobile
          card={card}
          tripId={tripId}
          canEdit={canEdit}
          open={modalOpen}
          handleClose={handleModalClose}
        />
      )}
    </>
  );
}

SingleCard.displayName = 'SingleCard';
export default SingleCard;
