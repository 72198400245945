import { createGlobalStyle } from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const GlobalStyle = createGlobalStyle`
    body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.0225rem;
    color: #141414;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }

// @media (max-width: 2560px) {
//   html {
//     font-size: 133%;
//   }
// }

// @media (max-width: 1920px) {
//   html {
//     font-size: 100%;
//   }
// }

// @media (max-width: 1680px) {
//   html {
//     font-size: 89%;
//   }
// }

// @media (max-width: 1600px) {
//   html {
//     font-size: 85%;
//   }
// }

// @media (max-width: 1536px) {
//   html {
//     font-size: 81%;
//   }
// }

// @media (max-width: 1440px) {
//   html {
//     font-size: 75%;
//   }
// }

// @media (max-width: 1366px) {
//   html {
//     font-size: 73%;
//   }
// }

// @media (max-width: 1280px) {
//   html {
//     font-size: 69%;
//   }
// }

// @media (max-width: 1024px) {
//   html {
//     font-size: 54%;
//   }
// }

// @media (max-width: 800px) {
//   html {
//     font-size: 43%;
//   }
// }

// @media (max-width: 360px) {
//   html {
//     font-size: 19%;
//   }
// }

    a {
    text-decoration: none;
    color: black;
    }

    * {
    box-sizing: border-box;
    }
`;
