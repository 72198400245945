import React, { useState, SyntheticEvent } from 'react';
import { Helmet } from 'react-helmet';

import { Tab } from '@mui/material';
import { TabContext } from '@mui/lab';

import useMobileMediaQuery from '../../utils/media-query.utils';

import SignIn from '../../components/sign-in/sign-in.component';
import SignUp from '../../components/sign-up/sign-up.component';

import {
  SignInAndSignUpContainer,
  SignInAndSignUpMainContainer,
  SignInAndSignUpTitle,
  SignInTabList,
  SignInTabPanel,
} from './sign-in-and-sign-up.styles';

function SignInAndSignUp() {
  const [tabValue, setTabValue] = useState('login');
  const isMobile = useMobileMediaQuery();

  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>Wander | {tabValue === 'login' ? 'Login' : 'Sign Up'}</title>
      </Helmet>
      <SignInAndSignUpContainer isMobile={isMobile}>
        <SignInAndSignUpMainContainer>
          <SignInAndSignUpTitle>
            {tabValue === 'login' ? 'Welcome Back!' : 'New to Wander?'}
          </SignInAndSignUpTitle>
          <TabContext value={tabValue}>
            <SignInTabList onChange={handleTabChange}>
              <Tab label="Log In" value="login" />
              <Tab label="Sign Up" value="signup" />
            </SignInTabList>

            <SignInTabPanel value="login">
              <SignIn />
            </SignInTabPanel>
            <SignInTabPanel value="signup">
              <SignUp />
            </SignInTabPanel>
          </TabContext>
        </SignInAndSignUpMainContainer>
      </SignInAndSignUpContainer>
    </>
  );
}

SignInAndSignUp.displayName = 'SignInAndSignUp';
export default SignInAndSignUp;
