/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import {
  Menu,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

export const EmojiPickerDiv = styled.div`
  position: absolute;
  z-index: 10;
`;

export const OptionsMenu = styled(Menu)`
  border-radius: 10px;
`;

export const OptionsDialog = styled(Dialog)`
  border-radius: 10px;
`;

export const OptionsDialogContent = styled(DialogContent)`
  padding: 0px 22px;
`;

export const OptionsDialogTitle = styled(DialogTitle)`
  font-weight: bold;
  text-align: center;
`;

export const OptionsDialogContentText = styled(DialogContentText)`
  font-size: 14px;
  color: black;
`;

export const OptionsDialogActions = styled(DialogActions)`
  margin: auto;
  gap: 32px;
  margin-top: -6px;
  margin-bottom: 4px;
`;

export const CancelButton = styled(Button)`
  color: #219fa8;
  font-size: 16px;
  text-transform: capitalize;
  border-radius: 10px;
  padding: 12px 24px;
`;

export const DeleteButton = styled(Button)`
  color: #eb5757;
  font-size: 16px;
  text-transform: capitalize;
  border-radius: 10px;
  padding: 12px 24px;
`;
