import { Fab, Box } from '@mui/material';
import styled from 'styled-components';

export const TripMapContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  height: 70vh;
  background: white;
  margin-top: 20px;
`;

export const TripMapFilterFab = styled(Fab)`
  position: absolute;
  bottom: 8em;
  right: 0.4em;
  width: 3em;
  height: 3em;
  background: #fafafa;
  drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.25));
`;

export const TripMapFilterContainerMobile = styled(Box)`
  position: fixed;
  width: 100%;
  max-height: 50vh;
  padding: 1em 1.5em;
  bottom: 0;
  left: 0;
  background: #fafafa;
  overflow-y: auto;
`;

export const TripMapFilterContainer = styled.div`
  position: absolute;
  min-width: 275px;
  max-width: 350px;
  height: 50vh;
  padding: 20px;
  top: 72px;
  right: 64px;
  opacity: 0.95;
  background: #fafafa;
  overflow-y: auto;
  border-radius: 10px;
`;
