import React, { useState, ChangeEvent, FormEvent } from 'react';
import FormInput from '../form-input/form-input.component';

import {
  signInWithGooglePopup,
  signInAuthUserWithEmailAndPassword,
} from '../../redux/user/user.slice';

import {
  SignInContainer,
  ButtonsContainer,
  LoginWithGoogleButton,
  SeparatorContainer,
  FormInstructions,
  LoginButton,
} from './sign-in.styles';

const defaultFormFields = {
  email: '',
  password: '',
};

function SignIn() {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email, password } = formFields;

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const signInWithGoogle = async () => {
    await signInWithGooglePopup();
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      await signInAuthUserWithEmailAndPassword(email, password);
      resetFormFields();
    } catch (error) {
      console.log('user sign in failed', error);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <SignInContainer>
      <ButtonsContainer>
        <LoginWithGoogleButton
          variant="contained"
          type="button"
          onClick={signInWithGoogle}
          fullWidth
        >
          Log in with Google
        </LoginWithGoogleButton>
      </ButtonsContainer>
      <SeparatorContainer>
        <span>OR</span>
      </SeparatorContainer>
      <FormInstructions>Log in with your email and password</FormInstructions>

      <form onSubmit={handleSubmit}>
        <FormInput
          name="email"
          type="email"
          handleChange={handleChange}
          value={email}
          label="Email"
          required
        />
        <FormInput
          name="password"
          type="password"
          value={password}
          handleChange={handleChange}
          label="Password"
          required
        />
        <LoginButton variant="outlined" type="submit">
          Log in
        </LoginButton>
      </form>
    </SignInContainer>
  );
}

SignIn.displayName = 'SignIn';
export default SignIn;
