import { createAsyncThunk } from '@reduxjs/toolkit';
import { eachDayOfInterval, addDays } from 'date-fns';
import { updateTripDates } from '../../firebase/firebase.utils';
import {
  changeEventLengthService,
  removeEventLengthService,
} from '../../firebase/services/event.service';
import { changeDefaultDatesService } from '../../firebase/services/trip.service';
import { getISODate } from '../utils';
import {
  DayID,
  EventID,
  IChangeDefaultDatesThunkPayload,
  IChangeDefaultDatesThunkResult,
  IChangeEventLengthPayload,
  Trip,
  TripDatesUpdateProps,
  TripDatesUpdateSuccess,
  TripEvent,
} from './trip.types';
import { getDaysMapSnapshot, getEventsMapSnapshot } from './trip.utils';

export const changeEventLength = createAsyncThunk(
  'trip/changeEventLength',
  async (payload: IChangeEventLengthPayload): Promise<IChangeEventLengthPayload> => {
    if (payload.eventLength) {
      await changeEventLengthService(payload); // return undefined
    } else {
      await removeEventLengthService(payload); // return undefined too
    }
    return payload;
  }
);

export const changeDefaultDates = createAsyncThunk(
  'trip/changeDefaultDates',
  async ({
    tripId,
    newStartDate,
    defaultNumDays,
    defaultStartDate,
  }: IChangeDefaultDatesThunkPayload): Promise<IChangeDefaultDatesThunkResult> => {
    const enumDays: Record<string, string> = {};
    const defaultRange = eachDayOfInterval({
      start: defaultStartDate,
      end: addDays(defaultStartDate, defaultNumDays - 1),
    });
    defaultRange.forEach((el, idx) => {
      enumDays[getISODate(el)] = getISODate(addDays(newStartDate, idx));
    });
    await changeDefaultDatesService({ tripId, enumDays });
    const startDateISO = enumDays[getISODate(defaultRange[0])];
    const endDateISO = enumDays[getISODate(defaultRange[defaultRange.length - 1])];
    const days = await getDaysMapSnapshot(tripId, startDateISO, endDateISO);
    const events = await getEventsMapSnapshot(tripId);
    return { startDateISO, endDateISO, days, events };
  }
);

export const updateDates = createAsyncThunk(
  'trip/updateDates',
  async (
    { tripId, oldDateRange, newDateRange, days }: TripDatesUpdateProps,
    { getState }
  ): Promise<TripDatesUpdateSuccess> => {
    if (!newDateRange.from || !newDateRange.to) {
      return Promise.reject(new Error('Invalid new date range'));
    }
    const startDateISO = getISODate(newDateRange.from);
    const endDateISO = getISODate(newDateRange.to);
    let daysToDelete: DayID[] = [];
    let eventsToDelete: EventID[] = [];
    const deletedEvents: TripEvent[] = [];
    const state = getState() as { trip: { currentTrip: Trip } };

    if (oldDateRange) {
      const oldDateISOs = new Set(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        eachDayOfInterval({ start: oldDateRange.from!, end: oldDateRange.to! }).map((date) =>
          getISODate(date)
        )
      );
      const newDateISOs = new Set(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        eachDayOfInterval({ start: newDateRange.from!, end: newDateRange.to! }).map((date) =>
          getISODate(date)
        )
      );
      const dateISOsToDelete = Array.from(oldDateISOs).filter(
        (dateISO) => !newDateISOs.has(dateISO)
      );
      dateISOsToDelete.forEach((dateISO) => {
        const day = days[dateISO];
        if (day) {
          if (day.id) {
            daysToDelete = daysToDelete.concat([day.id]);
          }
          if (day.eventOrder) {
            eventsToDelete = eventsToDelete.concat(day.eventOrder);
          }
        }
      });
    }
    if (eventsToDelete.length) {
      eventsToDelete.forEach(
        (eventId) =>
          state.trip.currentTrip?.events[eventId] &&
          deletedEvents.push(state.trip.currentTrip?.events[eventId])
      );
    }

    await updateTripDates(tripId, startDateISO, endDateISO, daysToDelete, eventsToDelete);
    const newDays = await getDaysMapSnapshot(tripId, startDateISO, endDateISO);
    const events = await getEventsMapSnapshot(tripId);
    return {
      tripId,
      startDateISO,
      endDateISO,
      days:
      newDays,
      events,
      deletedEvents,
    };
  }
);
