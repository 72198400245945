import { createTheme } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const DEFAULT_THEME = createTheme({
  palette: {
    primary: { main: '#141414' },
    secondary: { main: '#2C969D' },
    error: { main: '#ED7474' },
    success: { main: '#219FA8' },
    info: { main: '#62C5EF' },
  },
  typography: {
    allVariants: {
      fontFamily: 'Poppins',
      fontSize: 16,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          fontSize: '0.875rem',
          letterSpacing: '0.05rem',
          minHeight: '44px',
        },
      },
    },
  },
});
