import React, { useState, MouseEvent } from 'react';
import { ListItemIcon } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import useMobileMediaQuery from '../../../utils/media-query.utils';
import {
  SortByButton,
  StyledMenu,
  StyledMenuItem,
  StyledListItemText,
} from './trips-list-sort-menu.styles';

interface SortByDropdownProps {
  sortOption: string,
  setSortOption: React.Dispatch<React.SetStateAction<string>>,
}

function SortByDropdown({ sortOption, setSortOption }: SortByDropdownProps): JSX.Element {
  const isMobile = useMobileMediaQuery();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: MouseEvent) => {
    // checks if the click event's target is within the anchor element (the button that opens the menu)
    if (anchorEl && anchorEl.contains(event.target as Node)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option: string) => {
    setSortOption(option);
    sessionStorage.setItem('sortOption', option);
  };

  return (
    <div>
      <SortByButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        isMobile={isMobile}
        onClick={handleMenuClick}
      >
        {sortOption}
      </SortByButton>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        isMobile={isMobile}
        onClose={handleClose}
      >
        {['Alphabetical: A-Z', 'Last Opened'].map((option) => (
          <StyledMenuItem
            key={option}
            isMobile={isMobile}
            onClick={() => handleMenuItemClick(option)}
            selected={option === sortOption}
          >
            {option === sortOption && (
              <ListItemIcon>
                <CircleIcon fontSize="small" style={{ color: '#FFD700' }} />
              </ListItemIcon>
            )}
            <StyledListItemText
              primary={option}
              primaryTypographyProps={{ style: { fontWeight: option === sortOption ? 'bold' : 'normal' } }}
              isMobile={isMobile}
              style={{ marginLeft: option === sortOption ? 0 : 36 }} // provides space for active sort icon
            />
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}

SortByDropdown.displayName = 'SortByDropdown';
export default SortByDropdown;
