import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import useMobileMediaQuery from '../../utils/media-query.utils';

import { selectCurrentUser } from '../../redux/user/user.selectors';
import { signOutUser } from '../../redux/user/user.slice';

import {
  NavigationContainer,
  NavLinks,
  NavLink,
  LogoContainer,
  MenuIconContainer,
  MenuButton,
  Overlay,
  CloseButtonContainer,
  CloseButtonText,
  CloseButtonIcon,
} from './navigation.styles';

function Navigation() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useMobileMediaQuery();

  const currentUser = useAppSelector(selectCurrentUser);
  const [menuOpen, setMenuOpen] = useState(false);

  const signOut = () => {
    dispatch(signOutUser());
    navigate('/');
    setMenuOpen(false);
  };

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <NavigationContainer>
        <LogoContainer to="/">WANDER</LogoContainer>
        <NavLinks>
          {/* unauthorized user */}
          {!currentUser && <NavLink to="/login">LOG IN</NavLink>}
          {/* authorized user - not mobile */}
          {currentUser && !isMobile && (
            <>
              <NavLink to="/trips">TRIPS</NavLink>
              <NavLink as="span" onClick={signOut}>
                LOG OUT
              </NavLink>
            </>
          )}
          {/* authorized user - mobile */}
          {currentUser && isMobile && (
            <>
              <MenuIconContainer onClick={handleMenuToggle}>
                <MenuButton />
              </MenuIconContainer>
              <Menu
                right // opens menu from right side. default is left.
                width="65%"
                isOpen={menuOpen}
                onStateChange={(state) => setMenuOpen(state.isOpen)}
                customBurgerIcon={false} // hide default burger icon
                customCrossIcon={false} // Hide default close icon
                styles={{
                  bmMenu: {
                    background: '#ffffff',
                    padding: '1em',
                    zIndex: '1000',
                    transition: 'transform 0.3s ease-in-out',
                    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.8)',
                    borderRadius: '10px 0 0 10px',
                    top: '0',
                    left: 'auto',
                    height: '100vh',
                    marginTop: '-40px',
                  },
                }}
              >
                <CloseButtonContainer onClick={handleMenuToggle}>
                  <CloseButtonIcon />
                  <CloseButtonText>Close</CloseButtonText>
                </CloseButtonContainer>
                <NavLink to="/trips" className="trips" onClick={handleMenuToggle}>
                  TRIPS
                </NavLink>
                <NavLink className="logout" as="span" onClick={signOut}>
                  LOG OUT
                </NavLink>
              </Menu>
            </>
          )}
        </NavLinks>
      </NavigationContainer>
      {menuOpen && <Overlay onClick={handleMenuToggle} />}
      <Outlet />
    </>
  );
}

Navigation.displayName = 'Navigation';
export default Navigation;
