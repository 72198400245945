import React, { useEffect, useState } from 'react';
import { AccordionDetails } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useOrderedLists } from '../../../../hooks/trip/trip-list.hooks';
import Card from '../../../card/card.component';
import { Trip } from '../../../../redux/trip/trip.types';
import useMobileMediaQuery from '../../../../utils/media-query.utils';

import {
  ItineraryCardsContainer,
  ItinerarySidebarAccordion,
  ItinerarySidebarContainer,
  ItinerarySidebarItemAccordionSummary,
  ItinerarySidebarItemEmoji,
  ItinerarySidebarTitle,
  ItinerarySidebarListTitle,
} from './itinerary-sidebar.styles';

interface ItinerarySidebarProps {
  trip: Trip,
  canEdit?: boolean,
}

function ItinerarySidebar({ trip, canEdit }: ItinerarySidebarProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useMobileMediaQuery();
  const orderedLists = useOrderedLists(trip.lists, trip.listOrder);

  // so far seems to correctly load the first list on initial render of the page.
  const [expanded, setExpanded] = useState<string | false>(
    trip.lists.length ? orderedLists[0].id : false
  );

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      searchParams.set('list', panel);
    } else {
      searchParams.delete('list');
    }
    setSearchParams(searchParams);
  };

  // Handle url navigation
  useEffect(() => {
    setExpanded(searchParams.get('list') || false);
  }, [searchParams, setSearchParams]);

  return (
    <ItinerarySidebarContainer>
      <ItinerarySidebarTitle isMobile={isMobile}>Lists</ItinerarySidebarTitle>
      {orderedLists
        .filter(
          (list) =>
            list.cardOrder?.filter((cardId) => !trip.cards[cardId].isInItinerary ?? false).length
        )
        .map((list) => (
          <ItinerarySidebarAccordion
            key={list.id}
            expanded={expanded === list.id}
            onChange={handleChange(list.id)}
            disableGutters
          >
            <ItinerarySidebarItemAccordionSummary key={list.id}>
              <ItinerarySidebarListTitle>
                <ItinerarySidebarItemEmoji>{list.emoji}</ItinerarySidebarItemEmoji>{' '}
                <span>{list.name}</span>
              </ItinerarySidebarListTitle>
            </ItinerarySidebarItemAccordionSummary>
            <AccordionDetails>
              <ItineraryCardsContainer>
                {trip &&
                  list.cardOrder &&
                  expanded === list.id &&
                  list.cardOrder.map((cardId) => {
                    if (trip.cards[cardId] && !trip.cards[cardId].isInItinerary && list.cardOrder) {
                      return (
                        <Card
                          key={cardId}
                          card={trip.cards[cardId]}
                          listId={list.id}
                          tripId={trip.id}
                          cardOrder={list.cardOrder}
                          canEdit={canEdit}
                          isItinerarySidebarCard
                        />
                      );
                    }
                    return undefined;
                  })}
              </ItineraryCardsContainer>
            </AccordionDetails>
          </ItinerarySidebarAccordion>
        ))}
    </ItinerarySidebarContainer>
  );
}

ItinerarySidebar.displayName = 'ItinerarySidebar';
export default ItinerarySidebar;
