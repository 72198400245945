import styled, { css } from 'styled-components';
import Box from '@mui/material/Box';
import { TabList, TabPanel } from '@mui/lab';

type MobileProp = {
  isMobile: boolean,
};

export const TripContainer = styled.div<MobileProp>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 20px;
  padding: ${(props) => (props.isMobile ? '' : '20px 40px')};
`;

export const TripHeaderContainer = styled.div<MobileProp>`
  grid-column: 1 / -1;

  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  justify-content: space-between;
  align-content: center;
  padding: ${(props) => (props.isMobile ? '0px 16px' : '')};
`;

export const TripMainContainer = styled(Box)`
  grid-column: 1 / -1;
  grid-row: 2;
`;

export const TripTabList = styled(TabList)`
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;

  & .Mui-selected {
    font-weight: 600;
    letter-spacing: 0.08rem;
  }

  & .Mui-disabled {
    letter-spacing: 0.08rem;
  }

  & .MuiTabs-indicator {
    background-color: black;
  }

  & .MuiTabs-scroller {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-x: auto !important;

    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }
`;

export const TripTabPanel = styled(TabPanel)`
  padding: 0px;
`;

type TripNotesContainerProps = {
  toolbar: boolean,
};

export const TripNotesContainer = styled.div<TripNotesContainerProps>`
  display: flex;
  flex-flow: column nowrap;
  height: 70vh;
  background: white;
  overflow: auto;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.0225rem;

  ${(props) => {
    if (props.toolbar) {
      return css`
        > div:not(:first-child) {
          flex-grow: 1;
          margin: 12px 16px;
        }
      `;
    }
    return css`
      > div {
        height: 100%;
        margin: 12px 16px;
      }
    `;
  }}
`;
