import styled from 'styled-components';
import { Button, ListItemText, Menu, TextField } from '@mui/material';

type MobileProp = {
  isMobile: boolean,
};

export const TripShareModalContainer = styled.div<MobileProp>`
  width: ${(props) => (props.isMobile ? '92vw' : '500px')};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: ${(props) => (props.isMobile ? '80vh' : 'unset')}; /* Set max-height for mobile */
  overflow-y: auto; /* Enable vertical scrolling */
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
`;

export const TripShareModalSubcontainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  padding: 16px 24px;
  background-color: #fafafa;
  border-radius: 10px;
`;

export const TripShareHeader = styled.h3`
  font-size: 1rem;
  font-weight: 600;
`;

export const TripShareAddForm = styled.form`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 0;
  text-transform: none;
`;

export const TripShareTextField = styled(TextField)`
  && {
    .MuiFormHelperText-root {
      font-size: 0.875rem;
    }
    width: 100%;
    padding-right: 65px;
  }
`;

export const TripShareAddButton = styled(Button)`
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
  position: absolute;
  right: -14px;
  transform: translate(-20px, 5px);
  border-radius: 10px;
  padding: 4px 12px;
`;

export const TripShareModalRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export const TripShareModalRowGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;

export const TripShareModalRowGroupEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

export const TripShareModalSwitchContainer = styled(TripShareModalRowGroup)`
  gap: 4px;

  & span {
    font-size: 0.875rem;
  }
`;

type TripShareSwitchLabelProp = {
  isPublic: boolean,
  canEdit: boolean,
};

export const TripShareSwitchLabel = styled(TripShareModalRowGroup)<TripShareSwitchLabelProp>`
  color: ${(props) => (props.isPublic ? '#17A8B2' : 'default')};
  opacity: ${(props) => (props.canEdit ? '1' : '0.5')};
`;

export const TripShareModalSpan = styled.span<MobileProp>`
  font-size: ${(props) => (props.isMobile ? '0.8rem' : '0.875rem')};
  line-height: 1.5;
`;

export const TripShareEmailSpan = styled(TripShareModalSpan)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: ${(props) => (props.isMobile ? '190px' : 'unset')};
`;

export const TripShareModalSpanItalic = styled(TripShareModalSpan)`
  font-style: italic;
`;

export const TripShareCollaboratorRole = styled(TripShareModalSpan)`
  font-style: italic;
  text-transform: capitalize;
  white-space: nowrap;
`;

export const TripShareLinkSettingsSpan = styled(TripShareModalSpan)`
  flex: 3;
`;

export const TripShareRoleButton = styled(Button)<MobileProp>`
  text-transform: capitalize;
  white-space: nowrap;
  margin-right: ${(props) => (props.isMobile ? '-10px' : '0')};
  border-radius: 10px;
`;

export const TripShareRoleListItemText = styled(ListItemText)`
  span {
    font-size: 0.875rem;
    text-transform: capitalize;
  }
`;

export const TripShareContainer = styled.div`
  display: flex;
`;

export const TripShareButton = styled(Button)`
  height: 44px;
  min-width: 100px;
  border-radius: 10px;
  white-space: nowrap;
`;

export const TripSaveDoneButton = styled(Button)`
  min-width: 88px;
  align-self: flex-end;
  border-radius: 10px;
`;

export const TripCopyLinkButton = styled(Button)`
  color: #5033ff;
  border-radius: 10px;
`;

export const TripRoleMenu = styled(Menu)`
  .MuiMenu-list {
    width: 136px;
  }
`;
