import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { DateRange, SelectRangeEventHandler } from 'react-day-picker';
import { parseISO, addDays } from 'date-fns';
import { ClickAwayListener, IconButton } from '@mui/material';
import { EditRounded } from '@mui/icons-material';
import { defaultStartDate } from '../trip/constants';
import { Trip } from '../../redux/trip/trip.types';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectChangedDefaultDates } from '../../redux/trip/trip.selectors';
import { updateDates } from '../../redux/trip/trip.operations';
import {
  getNumDaysInDateRange,
  getNumberOfDaysWithEvents,
  doesNewDateRangeExcludeEvents,
  doesNewDateRangeOverlapOriginal,
} from './date-range-picker.utils';

import {
  StyledDayPicker,
  DateRangePickerContainer,
  MainDayPickerContainer,
  ButtonsContainer,
  CancelButton,
  SaveButton,
  ClearDatesButton,
  SelectedDatesTextContainer,
  SelectedDateArrow,
  SelectedStartDate,
  SelectedEndDate,
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
  StyledDialogText,
  StyledDialogSubtext,
  StyledDialogActions,
  DialogCancelButton,
  DialogSaveButton,
} from './date-range-picker.styles';
import 'react-day-picker/dist/style.css';

interface DateRangePickerProps {
  trip: Trip,
  initialDateRange: DateRange,
  setIsPickerOpen: (value: boolean) => void,
  // if true, moving mouse away from date-picker won't close it.
  // negates handleMouseLeave() from Trip-Title-Header.component.
  // handleMouseLeave() is there to show/hide date-picker on hover of date in Trip-Title-Header
}

function DateRangePicker({ trip, initialDateRange, setIsPickerOpen }: DateRangePickerProps) {
  const dispatch = useAppDispatch();
  const isChangedDefaultDates = useAppSelector(selectChangedDefaultDates);
  const [range, setRange] = useState<DateRange | undefined>(initialDateRange);
  const [newDateRange, setNewDateRange] = useState<DateRange | undefined>(undefined);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isPickerShowing, setIsPickerShowing] = useState(false); // used to open & close date picker
  const [dateChangeConfirmed, setDateChangeConfirmed] = useState(false); // helps update selected range displayed after date change or canceling date change.

  const defaultNumDays = 4;
  const startDate = trip.startDateISO ? parseISO(trip.startDateISO) : defaultStartDate;
  let defaultMonth = new Date(startDate.getFullYear(), startDate.getMonth());

  // Check if defaultMonth equals the startDate's month
  if (defaultMonth.getMonth() === defaultStartDate.getMonth()) {
    // If they are the same, set defaultMonth to current day's month on open.
    defaultMonth = new Date();
  }

  const endDate = trip.endDateISO
    ? parseISO(trip.endDateISO)
    : addDays(defaultStartDate, defaultNumDays - 1);

  const tripDateRange = useMemo(() => ({ from: startDate, to: endDate }), [startDate, endDate]);

  const handleOpenDateRangePicker = () => {
    if (isPickerShowing) {
      setIsPickerShowing(false);
      setIsPickerOpen?.(false);
      setRange(initialDateRange);
    } else {
      setIsPickerShowing(true);
      setIsPickerOpen?.(true);
      if (startDate.toString() === defaultStartDate.toString()) {
        setRange(undefined);
      }
    }
  };

  const changeDates = useCallback(() => {
    if (newDateRange?.from && newDateRange?.to) {
      dispatch(
        updateDates({
          tripId: trip.id,
          oldDateRange: tripDateRange,
          newDateRange,
          days: trip.days,
        })
      );
      setDateChangeConfirmed(true);
      setNewDateRange(undefined);
    }
  }, [dispatch, trip.id, trip.days, tripDateRange, newDateRange]);

  useEffect(() => {
    if (isChangedDefaultDates && newDateRange) {
      changeDates();
    }
  }, [isChangedDefaultDates, changeDates, newDateRange]);

  const handleOnDateRangeSelected = (dateRange: DateRange) => {
    if (dateRange.from && dateRange.to) {
      setNewDateRange(dateRange);
      setDialogOpen(true);
    }
    setIsPickerShowing(false);
  };

  const handleDateRangeSelected: SelectRangeEventHandler = (selectedRange) => {
    setRange(selectedRange);
  };

  const handleSaveDateRange = () => {
    if (isPickerShowing && range) {
      handleOnDateRangeSelected(range);
    }
    setIsPickerShowing(false);
  };

  const handleCancelDateChange = () => {
    setIsPickerShowing(false);
    setIsPickerOpen(false);
    setNewDateRange(undefined);

    // resets selected range displayed to initial if no date change confirmation
    if (!dateChangeConfirmed) {
      setRange(initialDateRange);
      // sets selected range displayed to new date range after confirmation
    } else {
      setRange(range);
    }
    // no matter what, reset state once dates changed or action is canceled
    setDateChangeConfirmed(false);
  };

  const handleClearDates = () => {
    setNewDateRange(undefined);
    setRange(undefined);
  };

  const handleConfirmDateChangeFromDialog = () => {
    setDialogOpen(false);
    changeDates();
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  /** Render different Dialog Popup when confirming date change when:
   * (LONGER DIALOG WITH WARNING) - itinerary causes date change conflict
   *  - new date range is completely different from initial date range && there are itinerary events
   *
   * (SHORTER BASIC DIALOG) - itinerary isn't affected
   *  - there are no itinerary events
   *  - initial date range has less days than new date range
   *  - initial date range has more days but # of days with itinerary events is less than # of days in new date range
   *
   */
  const numDaysWithEvents = getNumberOfDaysWithEvents(trip);

  // Calculate the number of days in initialDateRange and newDateRange to compare difference
  const numDaysInitialRange = useMemo(
    () => getNumDaysInDateRange(initialDateRange || tripDateRange),
    [initialDateRange, tripDateRange]
  );
  const numDaysNewRange = useMemo(
    () => getNumDaysInDateRange(newDateRange || tripDateRange),
    [newDateRange, tripDateRange]
  );

  let thereIsConflict: boolean;
  if (newDateRange) {
    thereIsConflict = doesNewDateRangeExcludeEvents(trip, initialDateRange, newDateRange);
  } else {
    thereIsConflict = false;
  }

  // display different copy for dialog popup if first time setting dates or updating existnig dates
  const originalDefaultStart = new Date(
    'Thu Jan 01 1970 00:00:00 GMT-0800 (Pacific Standard Time)'
  );
  const isFirstTimeSettingDates = startDate.getTime() === originalDefaultStart.getTime();
  const simpleChangeDateMsg = isFirstTimeSettingDates
    ? 'Just double checking those dates are correct. Happy adventuring!'
    : 'Are you sure you want to change the dates to the selected range?';

  let dialogContent;
  const hasEventsOutsideNewRange = (!doesNewDateRangeOverlapOriginal(initialDateRange, newDateRange) && numDaysWithEvents !== 0) || thereIsConflict;
  const hasNoEventsOrNewRangeIsLarger = Object.keys(trip.events).length === 0 || numDaysInitialRange <= numDaysNewRange || (numDaysInitialRange > numDaysNewRange && numDaysWithEvents <= numDaysNewRange) || initialDateRange.from === defaultStartDate;
  const warningMessage = (
    <>
      <StyledDialogText>
        You currently have places added in your itinerary that do not fall within the new date
        range. These events will be removed from the itinerary.
      </StyledDialogText>
      <StyledDialogSubtext>
        *To avoid any unwanted changes, check the Itinerary tab before changing the date.
      </StyledDialogSubtext>
    </>
  );

  if (hasEventsOutsideNewRange || !hasNoEventsOrNewRangeIsLarger) {
    dialogContent = warningMessage;
  } else {
    dialogContent = <StyledDialogText>{simpleChangeDateMsg}</StyledDialogText>;
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleCancelDateChange}>
        <DateRangePickerContainer>
          <IconButton aria-label="edit" onClick={handleOpenDateRangePicker}>
            <EditRounded />
          </IconButton>
          {isPickerShowing && (
            <MainDayPickerContainer>
              <SelectedDatesTextContainer>
                <SelectedStartDate
                  hasStartDate={range?.from !== undefined && range?.from !== defaultStartDate}
                >
                  {range?.from && range?.from !== defaultStartDate
                    ? range.from.toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                      })
                    : 'Start Date'}
                </SelectedStartDate>
                <SelectedDateArrow />
                <SelectedEndDate
                  hasEndDate={range?.to !== undefined && range?.from !== defaultStartDate}
                >
                  {range?.to && range?.from !== defaultStartDate
                    ? range.to.toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric',
                      })
                    : 'End Date'}
                </SelectedEndDate>
              </SelectedDatesTextContainer>
              <StyledDayPicker
                mode="range"
                selected={range}
                onSelect={handleDateRangeSelected}
                numberOfMonths={2}
                defaultMonth={defaultMonth}
                modifiersClassNames={{
                  range_start: 'rangeStart',
                  range_middle: 'rangeMiddle',
                  range_end: 'rangeEnd',
                }}
                modifiersStyles={{
                  range_start: {
                    fontWeight: 'bold',
                    backgroundColor: 'rgb(242, 201, 76)',
                    borderRadius: '10px',
                    color: 'black',
                  },
                  range_middle: {
                    backgroundColor: 'rgb(242, 201, 76 ,0.5)',
                    color: 'black',
                    borderRadius: '0',
                    height: '65%',
                  },
                  range_end: {
                    fontWeight: 'bold',
                    backgroundColor: 'rgb(242, 201, 76)',
                    borderRadius: '10px',
                    color: 'black',
                  },
                }}
              />
              <ButtonsContainer>
                <CancelButton onClick={handleCancelDateChange}>Cancel</CancelButton>
                <SaveButton
                  onClick={handleSaveDateRange}
                  disabled={
                    JSON.stringify(range) === JSON.stringify(initialDateRange) ||
                    range === undefined
                  }
                >
                  Save
                </SaveButton>
              </ButtonsContainer>
              <ClearDatesButton onClick={handleClearDates}>Clear Dates</ClearDatesButton>
            </MainDayPickerContainer>
          )}
        </DateRangePickerContainer>
      </ClickAwayListener>
      {dialogOpen && (
        <StyledDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          PaperProps={{ sx: { borderRadius: '10px' } }}
        >
          <StyledDialogTitle>
            {isFirstTimeSettingDates ? 'Confirm trip dates?' : 'Update trip dates?'}
          </StyledDialogTitle>
          <StyledDialogContent>{dialogContent}</StyledDialogContent>
          <StyledDialogActions>
            <DialogCancelButton onClick={handleDialogClose}>Cancel</DialogCancelButton>
            <DialogSaveButton onClick={handleConfirmDateChangeFromDialog}>
              {isFirstTimeSettingDates ? 'Confirm' : 'Update'}
            </DialogSaveButton>
          </StyledDialogActions>
        </StyledDialog>
      )}
    </>
  );
}

DateRangePicker.displayName = 'DateRangePicker';
export default DateRangePicker;
