import styled, { css } from 'styled-components';
import { Button } from '@mui/material';

export const TripPlaygroundContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  align-items: start;
  gap: 20px;
  padding-top: 20px;
`;

export const TripSidebarContainer = styled.div`
  grid-column: 1 / span 2;
  grid-row: 1 / span 3;
`;

const cardsWithoutMapStyles = (canEdit: boolean) => css`
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  grid-column: 3 / -1;
  grid-row: ${canEdit ? '2' : '1'};
  gap: 24px;
`;

const cardsWithMapStyles = css`
  grid-column: 3 / span 3;
  grid-row: 1 / span 3;
  display: grid;
  align-content: start;
  gap: 16px;
`;

type TripCardsContainerProps = {
  isMapVisible: boolean,
  canEdit: boolean,
};

const getCardsContainerStyles = (props: TripCardsContainerProps) => {
  if (props.isMapVisible) {
    return cardsWithMapStyles;
  }

  return cardsWithoutMapStyles(props.canEdit);
};

export const TripCardsScrollableContainer = styled.div<TripCardsContainerProps>`
  width: 100%;
  max-height: 70vh;
  overflow: auto;
  padding: 0.5em 0 0.5em 0.2em;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 100% 54px, 100% 54px, 100% 18px, 100% 18px;

  ${getCardsContainerStyles}
`;

const mapTopContainerWithCardsAndMapVisibleStyles = css`
  grid-column: 6 / -1;
`;

const mapTopContainerDefaultStyles = css`
  grid-column: 3 / -1;
`;

type TripMapContainerProps = {
  canEdit: boolean,
  isMapVisible: boolean,
  hasCards: boolean,
};

const getMapTopContainerStyles = (props: TripMapContainerProps) => {
  if (props.isMapVisible && props.hasCards) {
    return mapTopContainerWithCardsAndMapVisibleStyles;
  }
  return mapTopContainerDefaultStyles;
};

export const TripMapTopContainer = styled.div<TripMapContainerProps>`
  ${getMapTopContainerStyles}
  grid-row: 1;
  align-self: start;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.canEdit ? 'space-between' : 'flex-end')};
`;

export const TripAutocompleteContainer = styled.div`
  width: 444px;
`;

export const TripPlaygroundMapContainer = styled.div`
  grid-column: 3 / -1;
  grid-row: 2;
  height: 60vh;
`;

export const TripWithCardsMapContainer = styled(TripPlaygroundMapContainer)`
  grid-column: 6 / -1;
`;

export const TripMapContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 70vh;
  background: white;
  margin-top: 20px;
`;

export const ViewMapButton = styled(Button)`
  border-radius: 10px;
`;
