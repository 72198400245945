import { createSelector } from 'reselect';
import { RootState } from '../store';
import { TripState } from './trip.slice';
import { TripCard } from './trip.types';
import { getList } from './trip.utils';

export const selectTripReducer = (state: RootState): TripState => state.trip;

export const selectTripsList = createSelector([selectTripReducer], (trip) => trip.trips);

export const selectTripCreationStatus = createSelector(
  [selectTripReducer],
  (trip) => trip.tripCreationStatus
);

export const selectError = createSelector([selectTripReducer], (trip) => trip.error);

export const selectCurrentTrip = createSelector([selectTripReducer], (trip) => trip.currentTrip);

export const selectActiveListId = createSelector([selectCurrentTrip], (trip) =>
  trip ? trip.activeListId : undefined
);

export const selectActiveList = createSelector([selectCurrentTrip], (trip) =>
  trip && trip.activeListId ? getList(trip.lists, trip.activeListId) : undefined
);

export const selectActiveListCardOrder = createSelector([selectActiveList], (list) =>
  list ? list.cardOrder : undefined
);

export const selectActiveListOriginalCardOrder = createSelector([selectActiveList], (list) =>
  list ? list.originalCardOrder : undefined
);

export const selectCurrentTripId = createSelector([selectCurrentTrip], (trip) =>
  trip ? trip.id : undefined
);

export const selectTripTitle = createSelector([selectCurrentTrip], (trip) =>
  trip ? trip.title : undefined
);

export const selectTripLocation = createSelector([selectCurrentTrip], (trip) =>
  trip ? { lat: trip.location.latitude, lng: trip.location.longitude } : undefined
);

export const selectLists = createSelector([selectCurrentTrip], (trip) =>
  trip ? trip.lists : undefined
);

export const selectListOrder = createSelector([selectCurrentTrip], (trip) =>
  trip ? trip.listOrder : undefined
);

export const selectCards = createSelector([selectCurrentTrip], (trip) =>
  trip ? trip.cards : undefined
);

export const selectMapCenter = createSelector([selectCurrentTrip], (trip) =>
  trip ? trip.mapCenter : undefined
);

export const selectMapZoom = createSelector([selectCurrentTrip], (trip) =>
  trip ? trip.mapZoom : undefined
);

export const selectUserEmails = createSelector([selectCurrentTrip], (trip) =>
  trip ? trip.userEmails : undefined
);

export const selectActiveListCards = createSelector(
  [selectActiveListCardOrder, selectCards],
  (listCardOrder, cards) => {
    const listCards: TripCard[] = [];
    if (listCardOrder && cards) {
      listCardOrder.forEach((cardId) => {
        if (cards[cardId]) {
          listCards.push(cards[cardId]);
        }
      });
    }
    return listCards;
  }
);

export const selectDeletedEvents = createSelector([selectCurrentTrip], (trip) =>
  trip ? trip.deletedEvents : undefined
);

export const selectChangedDefaultDates = createSelector([selectCurrentTrip], (trip) =>
  trip ? trip.isChangedDefaultDates : undefined
);

export const selectLastOpened = createSelector([selectCurrentTrip], (trip) =>
  trip ? trip.lastOpened : undefined
);
