import styled from 'styled-components';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

type RenameListProps = {
  isEditingName: boolean,
};

export const RenameListDiv = styled.div<RenameListProps>`
  transform: translate(0, -5px);
  width: 1.875rem;
  height: 1.875rem;
  cursor: pointer;
  margin-bottom: ${(props) => (props.isEditingName ? '50px' : '')};
`;

export const StyledInput = styled.input`
  position: relative;
  font-size: 16px;
  color: #141414;
  text-transform: capitalize;
  border: none;
  border-radius: 0;
  border-bottom: 0.1rem solid black;
  outline: none;
  margin-top: 1rem;
  width: 21rem;
  height: 2rem;

  ::placeholder {
    text-transform: none;
  }
`;

export const RenameListButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
  margin-left: 350px;
`;

export const CancelRenameListDiv = styled.div`
  margin-top: 0.25em;
  margin-right: 0.5em;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const CancelRenameListButton = styled(CloseRoundedIcon)`
  width: 1.25em;
  color: #ed7474;
`;

export const CancelSpan = styled.span`
  color: #ed7474;
  margin-right: 0.5rem;
  font-size: 12px;
  display: inline-block;
  width: 4.5em;
`;

export const SaveRenameListDiv = styled.div`
  margin-top: 0.25em;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const SaveRenameListButton = styled(CheckRoundedIcon)`
  width: 1.25em;
  color: #219fa8;
`;

export const RenameSpan = styled.span`
  color: #219fa8;
  margin-right: 0.5rem;
  font-size: 12px;
  display: inline-block;
  width: 4em;
`;
