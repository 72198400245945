import styled from 'styled-components';

type MobileProp = {
  isMobile: boolean,
};

export const TripsListHeader = styled.div<MobileProp>`
  width: 100%;
  height: 1.25em;
  font-weight: 700;
  font-size: ${(props) => (props.isMobile ? '2.85' : '9')}rem;
  padding: ${(props) => (props.isMobile ? '6px 14px' : '12px')};
  margin-bottom: 16px;
`;

export const TripsListInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TripCount = styled.h1<MobileProp>`
  font-size: ${(props) => (props.isMobile ? '1' : '1.5')}em;
  margin: 0 16px;
`;

export const TripsListSortMenuContainer = styled.div<MobileProp>`
  display: flex;
  align-items: center;
  border-radius: 12px;
`;

export const SortBySpan = styled.span<MobileProp>`
  color: #828282;
  text-transform: uppercase;
  font-size: ${(props) => (props.isMobile ? '14px' : '18px')};
`;

export const TripsListContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  padding: 12px;
`;
