import styled from 'styled-components';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DayPicker } from 'react-day-picker';

export const StyledDayPicker = styled(DayPicker)`
  margin-left: 10px;
  width: 710px;
  height: 425px;
  position: absolute;
  border-radius: 10px;
  background-color: white;
  z-index: 5;
  padding: 75px 65px;
  font-size: 14px;
  box-shadow: rgba(128, 128, 128, 0.6) 2px 2px 6px 1px;

  & .rdp-nav_button {
    background-color: black;
    color: white;
    font-size: 20px;
    width: 25px;
    height: 25px;
    box-shadow: grey 0px 4px 4px;
    margin: 8px;

    &:hover {
      color: black;
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const DateRangePickerContainer = styled.div`
  position: relative;
`;

export const MainDayPickerContainer = styled.div`
  position: relative;
`;

export const SelectedDatesTextContainer = styled.div`
  position: absolute;
  z-index: 6;
  top: 36px;
  width: 235px;
  margin-left: 90px;
  marign-top: -35px;
`;

interface SelectedStartDateProps {
  hasStartDate: boolean,
}

export const SelectedStartDate = styled.span<SelectedStartDateProps>`
  font-size: 14px;
  color: ${(props) => (props.hasStartDate ? '1E1E1E' : '#D2D2D2')};
  font-weight: ${(props) => (props.hasStartDate ? 'bold' : '')};
  font-style: ${(props) => (props.hasStartDate ? '' : 'italic')};
  border-bottom: ${(props) =>
    props.hasStartDate ? 'rgb(242, 201, 76) 2px solid' : 'black 2px solid'};
  margin-right: 8px;
`;

export const SelectedDateArrow = styled(ArrowForwardIcon)``;

interface SelectedEndDateProps {
  hasEndDate: boolean,
}

export const SelectedEndDate = styled.span<SelectedEndDateProps>`
  font-size: 14px;
  color: ${(props) => (props.hasEndDate ? '1E1E1E' : '#D2D2D2')};
  font-weight: ${(props) => (props.hasEndDate ? 'bold' : '')};
  font-style: ${(props) => (props.hasEndDate ? '' : 'italic')};
  border-bottom: ${(props) =>
    props.hasEndDate ? 'rgb(242, 201, 76) 2px solid' : 'black 2px solid'};
  margin-left: 8px;
`;

export const ButtonsContainer = styled.div`
  margin-bottom: -170px;
  display: flex;
`;

export const ClearDatesButton = styled(Button)`
  z-index: 6;
  top: 156px;
  left: 568px;
  text-decoration: underline;
  text-transform: capitalize;
  font-size: 14px;
  border-radius: 10px;
`;

export const CancelButton = styled(Button)`
  z-index: 6;
  top: 380px;
  left: 490px;
  text-decoration: underline;
  text-transform: capitalize;
  border-radius: 10px;
`;

export const SaveButton = styled(Button)`
  z-index: 6;
  top: 380px;
  left: 510px;
  border: solid black 1px;
  border-radius: 10px;
  padding: 4px 32px;

  ${({ disabled }) =>
    disabled &&
    `
    color: rgba(0, 0, 0, 0.26);
    border: 1px solid rgba(0, 0, 0, 0.26);
  `}
`;

export const StyledDialog = styled(Dialog)`
  width: 500px;
  margin: auto;
`;

export const StyledDialogContent = styled(DialogContent)`
  padding: 20px 24px 4px 24px;
`;
export const StyledDialogTitle = styled(DialogTitle)`
  font-weight: bold;
  text-align: center;
`;
export const StyledDialogText = styled(DialogContentText)`
  font-size: 14px;
  color: black;
`;

export const StyledDialogSubtext = styled.p`
  font-style: italic;
  font-size: 12px;
`;

export const StyledDialogActions = styled(DialogActions)`
  margin: auto;
  gap: 72px;
  margin-bottom: 8px;
`;
export const DialogCancelButton = styled(Button)`
  color: #eb5757;
  border-radius: 10px;
  padding: 12px 24px;
`;
export const DialogSaveButton = styled(Button)`
  color: #219fa8;
  border-radius: 10px;
  padding: 12px 24px;
`;
