import styled from 'styled-components';

export const NoListsMessage = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.06em;
  text-align: center;
  color: black;
  margin-bottom: 22px;
  margin-top: 32px;
`;

export const ListContainerDiv = styled.div`
  width: 100%;
  position: relative;
  display: grid;
  transform: scale(1);
  z-index: 4;
`;

export const ListScrollableDiv = styled.div`
  position: relative;
  left: -3.1em;
  width: 115%;
  max-height: 67vh;
  overflow: auto;
  padding-bottom: 1em;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 100% 54px, 100% 54px, 100% 18px, 100% 18px;
`;

export const ListAutocompleteContainer = styled.div`
  margin: 0 auto 1rem auto;
  z-index: 99;
`;

export const ListInputButton = styled.input`
  height: 48px;
  width: 100%;
  background: url(/svgs/marker.svg) 16px 13px no-repeat scroll rgb(224, 224, 224);
  border: none;
  font-size: 0.875rem;
  padding-left: 48px;
  border-radius: 10px;

  &::placeholder {
    color: rgba(0, 0, 0);
  }

  &[disabled] {
    pointer-events: none;
  }
`;

export const MobilePlacesInputContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  box-sizing: border-box;
`;

export const MobileListHeader = styled.p`
  font-weight: 600;
  font-size: 1.3rem;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 4px;
`;

export const MobileListTitleContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin: 2px 4px 6px 4px;
`;

export const MobileListTitleEmoji = styled.span`
  font-size: 1.15rem;
  padding-right: 8px;
`;

export const MobileListTitle = styled.p`
  color: #219fa8;
  font-size: 1rem;
  text-transform: capitalize;
  font-weight: 600;
`;
