import styled from 'styled-components';
import { Button } from '@mui/material';

type MobileProp = {
  isMobile: boolean,
};

export const NotFoundContainer = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 60%;
`;

export const NotFoundImage = styled.img<MobileProp>`
  width: 37vw;
  margin: -135px 0px -110px 15px;
  transform: translate(${(props) => (props.isMobile ? '16px, 0px' : '33px, 10px')});
`;

export const NotFoundMessage = styled.h2<MobileProp>`
  font-weight: 600;
  font-size: ${(props) => (props.isMobile ? '1.5' : '2')}rem;
  text-transform: uppercase;
`;

export const NotFoundRedirectButton = styled(Button)<MobileProp>`
  font-size: 14px;
  border-radius: 10px;
  background: #f7d476;
  box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.1);
  padding: 16px 32px;

  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;
