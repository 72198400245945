import React from 'react';
import Grow from '@material-ui/core/Grow';
import { useAppDispatch } from '../../../../redux/hooks';
import { TripList } from '../../../../redux/trip/trip.types';
import { updateList } from '../../../../redux/trip/trip.slice';
import {
  RenameListDiv,
  StyledInput,
  RenameListButtons,
  CancelRenameListDiv,
  CancelRenameListButton,
  CancelSpan,
  SaveRenameListDiv,
  SaveRenameListButton,
  RenameSpan,
} from './rename-list-input.styles';

type RenameListInputProps = {
  tripId: string,
  list: TripList,
  canEdit: boolean,
  isEditingName: boolean,
  setIsEditingName: (newValue: boolean) => void,
};

function RenameListInput({
  tripId,
  list,
  canEdit,
  isEditingName,
  setIsEditingName,
}: RenameListInputProps) {
  const dispatch = useAppDispatch();

  const onUpdateListName = (input: HTMLInputElement) => {
    const newListName = input.value;
    if (newListName.length !== 0 && newListName !== list.name)
      dispatch(
        updateList({
          tripId,
          listId: list.id,
          field: 'name',
          value: newListName,
        })
      );
    setIsEditingName(false);
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const maxCharacterCount = 100;
    const listName = event.target as HTMLInputElement;
    const text = listName.value;

    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent creating a new line
      onUpdateListName(listName);
    }

    if (
      text.length >= maxCharacterCount &&
      event.key !== 'Backspace' &&
      event.key !== 'ArrowLeft' &&
      event.key !== 'ArrowRight'
    ) {
      event.preventDefault(); // Prevent further input
    }
  };

  const cancelRenameList = () => {
    setIsEditingName(false);
  };

  const onSaveButtonClick = () => {
    const inputElement = document.getElementById('input-element-id') as HTMLInputElement;
    if (inputElement) {
      onUpdateListName(inputElement);
    }
  };

  return (
    <RenameListDiv isEditingName={isEditingName}>
      <Grow
        in={isEditingName}
        mountOnEnter
        unmountOnExit
        timeout={{
          enter: 800,
          exit: 400,
        }}
      >
        <>
          <StyledInput
            id="input-element-id"
            defaultValue={list.name}
            onKeyDown={handleOnKeyDown}
            onClick={(e) => e.stopPropagation()}
            autoFocus
            readOnly={!canEdit}
          />
          <RenameListButtons>
            <CancelRenameListDiv onClick={cancelRenameList}>
              <CancelRenameListButton />
              <CancelSpan>CANCEL</CancelSpan>
            </CancelRenameListDiv>
            <SaveRenameListDiv onClick={onSaveButtonClick}>
              <SaveRenameListButton />
              <RenameSpan>SAVE</RenameSpan>
            </SaveRenameListDiv>
          </RenameListButtons>
        </>
      </Grow>
    </RenameListDiv>
  );
}

RenameListInput.displayName = 'RenameListInput';
export default RenameListInput;
