import { eachDayOfInterval, parseISO } from 'date-fns';
import { User } from 'firebase/auth';
import { EAddressComponentsType, getLocalityFromAdrString } from '../../components/card/card.utils';
import { DayData, EventData, TripRole } from '../../firebase/firebase.types';
import { getDaysDocs, getEventsDocs } from '../../firebase/firebase.utils';
import { getISODate } from '../utils';
import {
  DateISO,
  EventID,
  Trip,
  TripCard,
  TripDay,
  TripEvent,
  TripID,
  TripList,
} from './trip.types';

export const tripTitleForPlace = (place: string) => {
  if (place) {
    return `Adventure to ${place.split(',')[0]}`;
  }
  return 'Adventure';
};

export const getList = (lists: TripList[], listId: string) =>
  lists.find((list) => list.id === listId);

export const isPlaceAlreadyInList = (
  list: TripList,
  cards: { [key: string]: TripCard },
  googlePlaceId: string
) =>
  !!list.cardOrder
    ?.map((cardId) => cards[cardId]?.googlePlaceId)
    .find((cardGooglePlaceId) => cardGooglePlaceId === googlePlaceId);

export const getElementByIdInArray = (array: { id: string }[], id: string) =>
  array.find((item) => item.id === id);

export const canEditTrip = (trip?: Trip, user?: User | null) =>
  trip !== undefined &&
  user !== undefined &&
  user !== null &&
  Object.keys(trip.roles).includes(user.uid) &&
  [TripRole.OWNER, TripRole.EDITOR].includes(trip.roles[user.uid]);

export const isOwner = (trip?: Trip, user?: User | null) =>
  trip !== undefined &&
  user !== undefined &&
  user !== null &&
  Object.keys(trip.roles).includes(user.uid) &&
  [TripRole.OWNER].includes(trip.roles[user.uid]);

export const getCardLocalities = (cards: TripCard[]) => {
  const localities = new Set<string>();
  cards.forEach((card) => {
    if (card.addressComponents) {
      let doesLocalityExist = false;
      let hasSublocality = false;

      // eslint-disable-next-line no-restricted-syntax
      for (const addressComponent of card.addressComponents) {
        if (addressComponent.types.find((type) => type === EAddressComponentsType.locality)) {
          doesLocalityExist = true;
          localities.add(addressComponent.long_name);
          break;
        }
        if (addressComponent.types.find((type) => type === EAddressComponentsType.sublocality)) {
          hasSublocality = true;
          localities.add(addressComponent.long_name);
        }
      }
      if (!hasSublocality && !doesLocalityExist && card.formattedAddress) {
        const result: string | undefined = getLocalityFromAdrString(
          card.addressComponents,
          card.formattedAddress
        );
        if (!result) {
          return;
        }
        localities.add(result);
      }
    }
  });
  return localities.size > 0 ? Array.from(localities) : undefined;
};

export const getDaysMapSnapshot = async (
  tripId: TripID,
  startDateISO?: DateISO,
  endDateISO?: DateISO
) => {
  const days: { [key: DateISO]: TripDay } = {};
  if (startDateISO && endDateISO) {
    const startDate = parseISO(startDateISO);
    const endDate = parseISO(endDateISO);
    eachDayOfInterval({ start: startDate, end: endDate }).forEach((date) => {
      const dateISO = getISODate(date);
      days[dateISO] = { dateISO };
    });
    const daysDocs = await getDaysDocs(tripId);
    daysDocs.forEach((dayDoc) => {
      const data = dayDoc.data() as DayData;
      days[data.dateISO] = {
        id: dayDoc.id,
        ...data,
      };
    });
  }
  return days;
};

export const getEventsMapSnapshot = async (tripId: TripID) => {
  const eventsDocs = await getEventsDocs(tripId);
  const events: { [key: EventID]: TripEvent } = {};
  eventsDocs.forEach((eventDoc) => {
    events[eventDoc.id] = {
      id: eventDoc.id,
      isConfirmed: true,
      ...(eventDoc.data() as EventData),
    };
  });
  return events;
};
