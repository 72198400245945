import styled from 'styled-components';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

type MobileProp = {
  isMobile?: boolean,
};

export const ListsLabelDiv = styled.div<MobileProp>`
  display: flex;
  font-weight: 600;
  font-size: ${(props) => (props.isMobile ? '16px' : '1')}rem;
  margin-left: ${(props) => (props.isMobile ? '1rem' : '')};
  text-transform: uppercase;
`;

export const AddListDiv = styled.div<MobileProp>`
  transform: translate(0, -5px);
  width: 1.675rem;
  height: 1.675rem;
  cursor: pointer;
  margin-left: 0.75rem;
`;

export const StyledInput = styled.input<MobileProp>`
  position: relative;
  font-size: 16px;
  color: #141414;
  text-transform: capitalize;
  border: none;
  border-bottom: 0.1rem solid black;
  border-radius: 0;
  outline: none;
  margin-top: 1rem;
  left: ${(props) => (props.isMobile ? '1rem' : '')};
  padding-top: 0;
  margin-bottom: 0.58rem;
  width: ${(props) => (props.isMobile ? '93' : '100')}%;
  height: 2rem;

  ::placeholder {
    text-transform: none;
  }
`;

export const NewListButtons = styled.div<MobileProp>`
  display: flex;
  justify-content: end;
  margin-top: ${(props) => (props.isMobile ? '' : '-0.35em')};
  margin-right: ${(props) => (props.isMobile ? '' : '-0.95em')};
`;

export const PlusSvg = styled.svg`
  width: 60%;
  height: 60%;
  margin-top: 0.3rem;
  fill: rgba(42, 29, 2, 1) !important;

  &:hover {
    fill: rgba(42, 29, 2, 1);
  }
`;

export const CancelAddItemDiv = styled.div`
  margin-top: 0.25em;
  cursor: pointer;
  margin-bottom: 8px;
`;

export const CancelAddItemButton = styled(CloseRoundedIcon)`
  width: 0.85em;
  margin-top: 0.075rem;
  color: #ed7474;
`;

export const CancelSpan = styled.span`
  color: #ed7474;
  margin-top: 0.45rem;
  margin-right: 1.2rem;
  margin-left: 0.125rem;
  font-size: 12px;
`;

export const AddItemDiv = styled.div`
  margin-top: 0.25em;
  cursor: pointer;
`;

export const SaveAddItemButton = styled(CheckRoundedIcon)`
  width: 0.85em;
  margin-top: 0.075rem;
  color: #219fa8;
`;

export const AddSpan = styled.span`
  color: #219fa8;
  margin-top: 0.45rem;
  margin-right: 1.2rem;
  margin-left: 0.125rem;
  font-size: 12px;
`;
