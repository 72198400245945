import { DateRange } from 'react-day-picker';
import { differenceInDays } from 'date-fns';
import { Trip } from '../../redux/trip/trip.types';

/**
 * Determines if Dialog Popup is needed when confirming date change
 *
 * Don't display dialog if:
 *  - there are no events within the itinerary
 *  - the current date range is less than or equal to the new date range
 *  - the current date range is greater than the new date range but the number of days with Itinerary events is less than the new date range. No conflict with dates and events.
 *
 * Display dialog if:
 *  - the current date range is greater than the new date range AND the number of days with Itinerary events is greater than the new date range. There is conflict with dates and events.
 */

export function getNumDaysInDateRange(dateRange: DateRange) {
  if (dateRange.from && dateRange.to) {
    return differenceInDays(dateRange.to, dateRange.from) + 1;
  }
  return 0;
}

export function getNumberOfDaysWithEvents(trip: Trip) {
  let numDaysWithEvents = 0;
  const tripDays = Object.keys(trip.days);

  tripDays.forEach((day) => {
    if (trip.days[day] && trip.days[day].eventOrder?.length) {
      const length = trip.days[day].eventOrder?.length;
      if (length && length > 0) {
        numDaysWithEvents += 1;
      }
    }
  });

  return numDaysWithEvents;
}

export function doesNewDateRangeExcludeEvents(
  trip: Trip,
  originalDateRange: DateRange,
  newDateRange: DateRange
) {
  const tripDays = Object.keys(trip.days);

  return tripDays.some((day) => {
    const currentDate = new Date(day);
    const dayData = trip.days[day];

    return (
      originalDateRange.from &&
      originalDateRange.to &&
      currentDate >= originalDateRange.from &&
      currentDate <= originalDateRange.to &&
      dayData?.eventOrder?.length &&
      newDateRange.from &&
      newDateRange.to &&
      (currentDate < newDateRange.from || currentDate > newDateRange.to)
    );
  });
}

/** determines whether the new date range selected by the user overlaps with the initial date range */
export function doesNewDateRangeOverlapOriginal(
  initialDateRange: DateRange,
  newDateRange?: DateRange
) {
  if (
    newDateRange &&
    newDateRange.from &&
    newDateRange.to &&
    initialDateRange.from &&
    initialDateRange.to
  ) {
    const newFromDate = newDateRange.from.getTime();
    const newToDate = newDateRange.to.getTime();
    const originalFromDate = initialDateRange.from.getTime();
    const originalToDate = initialDateRange.to.getTime();

    // Check if newFromDate falls between originalFromDate and originalToDate
    if (newFromDate >= originalFromDate && newFromDate <= originalToDate) {
      return true;
    }

    // Check if newToDate falls between originalFromDate and originalToDate
    if (newToDate >= originalFromDate && newToDate <= originalToDate) {
      return true;
    }

    // Check if originalFromDate falls between newFromDate and newToDate
    if (originalFromDate >= newFromDate && originalFromDate <= newToDate) {
      return true;
    }

    // Check if originalToDate falls between newFromDate and newToDate
    if (originalToDate >= newFromDate && originalToDate <= newToDate) {
      return true;
    }
  }

  return false;
}
