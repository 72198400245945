import { useMemo } from 'react';
import { TripList } from '../../redux/trip/trip.types';

// eslint-disable-next-line import/prefer-default-export
export function useOrderedLists(
  lists: TripList[] | undefined,
  listOrder: string[] | undefined,
  excludeEmpty: boolean | undefined = undefined
) {
  return useMemo(() => {
    if (lists && listOrder) {
      return listOrder.reduce((previousValue, currentValue) => {
        const currentList = lists.find((list) => list.id === currentValue);
        if (currentList) {
          if (!excludeEmpty || (currentList.cardOrder && currentList.cardOrder.length > 0)) {
            return previousValue.concat([currentList]);
          }
        }
        return previousValue;
      }, [] as TripList[]);
    }
    return [];
  }, [lists, listOrder, excludeEmpty]);
}
