import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { Tooltip } from '@mui/material';
import { getDetails } from 'use-places-autocomplete';

import useMobileMediaQuery from '../../../utils/media-query.utils';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { selectCurrentUser } from '../../../redux/user/user.selectors';
import { selectCurrentTrip, selectTripCreationStatus } from '../../../redux/trip/trip.selectors';
import { TripCreationStatus } from '../../../redux/trip/trip.types';
import { resetTripCreationStatus, createTrip } from '../../../redux/trip/trip.slice';
import { LatLng } from '../../../firebase/firebase.types';
import PlacesAutocomplete from '../../places-autocomplete/places-autocomplete.component';

import {
  CreateTripContainer,
  StyledDialog,
  DialogTitleContainer,
  DialogSubtitle,
  DialogMainTitle,
  StyledDialogContent,
  StyledDialogActions,
  CreateTripButton,
  ToggleModalButton,
  CustomOverlay,
} from './create-trip-modal.styles';

function hasGeometry(
  result: google.maps.places.PlaceResult | string
): result is google.maps.places.PlaceResult {
  return (result as google.maps.places.PlaceResult).geometry !== undefined;
}

type Place = {
  description: string,
  location: LatLng,
};

function CreateTripModal() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useMobileMediaQuery();
  const currentUser = useAppSelector(selectCurrentUser);
  const currentTrip = useAppSelector(selectCurrentTrip);
  const tripCreationStatus = useAppSelector(selectTripCreationStatus);

  const [place, setPlace] = useState<Place | null>(null);
  const [hasError, setHasError] = useState(false);
  const [createTripModalOpen, setCreateTripModalOpen] = useState(false);

  const handleModalToggle = useCallback(() => {
    setCreateTripModalOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    if (tripCreationStatus === TripCreationStatus.CREATED) {
      dispatch(resetTripCreationStatus());
      if (currentTrip) {
        navigate(`/trips/${currentTrip.id}`);
      }
      handleModalToggle(); // Close modal after creating trip
    }
  }, [currentTrip, dispatch, navigate, tripCreationStatus, handleModalToggle]);

  const handleSuggestionSelect = (suggestion: google.maps.places.AutocompletePrediction) => {
    const { place_id, description } = suggestion;
    getDetails({ placeId: place_id, fields: ['geometry.location'] })
      .then((details) => {
        if (hasGeometry(details) && details.geometry?.location) {
          const latitude = details.geometry.location.lat();
          const longitude = details.geometry.location.lng();
          setPlace({
            description,
            location: { latitude, longitude },
          });
        }
      })
      .catch((error) => {
        console.log('Error ', error);
      });
  };

  const handleStartPlanning = () => {
    if (place) {
      if (currentUser) {
        dispatch(
          createTrip({ place: place.description, location: place.location, user: currentUser })
        );
      }
      setHasError(false);
    } else {
      setHasError(true);
    }
  };

  return (
    <CreateTripContainer>
      {createTripModalOpen && <CustomOverlay onClick={handleModalToggle} />}
      <StyledDialog
        open={createTripModalOpen}
        onClose={handleModalToggle}
        BackdropProps={{ invisible: true }} // Disable the default overlay
        PaperProps={{
          sx: {
            margin: isMobile ? '-80px 0px 0 72px' : '32px',
            borderRadius: '50%',
            width: isMobile ? '550px' : '500px',
            height: isMobile ? '550px' : '500px',
            boxShadow: 'none',
            background: isMobile ? '' : 'linear-gradient(to bottom,#ffd643 40%,white 30%)',
            overflow: 'visible',
            position: 'absolute',
            right: isMobile ? '' : '94px',
            bottom: isMobile ? '' : '60px',
          },
        }}
      >
        <DialogTitleContainer>
          <DialogSubtitle isMobile={isMobile}>Start a</DialogSubtitle>
          <DialogMainTitle isMobile={isMobile}>New Adventure!</DialogMainTitle>
        </DialogTitleContainer>
        <StyledDialogContent isMobile={isMobile}>
          <PlacesAutocomplete
            types={['(regions)']}
            placeholder="Where are you going?"
            className="tripsList-autocomplete"
            onSuggestionSelect={handleSuggestionSelect}
            hasError={hasError}
          />
        </StyledDialogContent>
        <StyledDialogActions isMobile={isMobile}>
          <CreateTripButton onClick={handleStartPlanning} isMobile={isMobile}>
            Create Trip
          </CreateTripButton>
        </StyledDialogActions>
      </StyledDialog>
      <ToggleModalButton
        onClick={handleModalToggle}
        isMobile={isMobile}
        modalOpen={createTripModalOpen}
      >
        {createTripModalOpen ? (
          <ClearIcon />
        ) : (
          <Tooltip title="Create a Trip" placement="top">
            <AddIcon />
          </Tooltip>
        )}
      </ToggleModalButton>
    </CreateTripContainer>
  );
}

CreateTripModal.displayName = 'CreateTripModal';
export default CreateTripModal;
