/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { ThemeProvider } from '@mui/material';
import { useAppDispatch, useAppSelector } from './redux/hooks';

import Navigation from './routes/navigation/navigation.component';
import Home from './routes/home/home.component';
import SignInAndSignUp from './routes/sign-in-and-sign-up/sign-in-and-sign-up.component';
import Trips from './routes/trips/trips.component';
import ForbiddenError403 from './routes/403/forbidden-error-403.component';
import NotFound404 from './routes/404/not-found-404.component';

import { selectCurrentUser } from './redux/user/user.selectors';
import { setCurrentUser } from './redux/user/user.slice';
import { createUserDocumentFromAuth, onAuthStateChangedListener } from './firebase/firebase.utils';
import { DEFAULT_THEME } from './material-ui.theme';
import { GlobalStyle } from './global.styles';
import 'react-day-picker/dist/style.css';
import PasswordProtect from './routes/password-protect/password-protect';

function App() {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const isAuthenticated = localStorage.getItem('isAuthenticated') || false;

  useEffect(() => {
    const unsubscribe = onAuthStateChangedListener((user) => {
      if (user) {
        createUserDocumentFromAuth(user);
      }
      dispatch(setCurrentUser(user));
    });

    return unsubscribe;
  }, [dispatch]);

  return !isAuthenticated ? (
    <PasswordProtect />
  ) : (
    <ThemeProvider theme={DEFAULT_THEME}>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<Navigation />}>
          <Route index element={<Home />} />
          <Route path="trips/*" element={<Trips />} />
          <Route
            path="login"
            element={currentUser ? <Navigate replace to="/trips" /> : <SignInAndSignUp />}
          />
          <Route path="/forbidden" element={<ForbiddenError403 />} />
          <Route path="*" element={<NotFound404 />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

App.displayName = 'App';
export default App;
