import styled from 'styled-components';
import { Button } from '@mui/material';

export const SignInContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 24px 0;
`;

export const LoginWithGoogleButton = styled(Button)`
  font-size: 1rem;
  border-radius: 10px;
`;

export const SeparatorContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  font-weight: 700;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
  }

  &:not(:empty)::before {
    margin-right: 0.25em;
  }

  &:not(:empty)::after {
    margin-left: 0.25em;
  }
`;

export const FormInstructions = styled.span`
  font-weight: unset;
`;

export const LoginButton = styled(Button)`
  border-radius: 10px;
`;
