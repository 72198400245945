import styled from 'styled-components';

type ItinerarySidebarContainerProps = {
  isMobile: boolean,
};

export const ItinerarySidebarDefaultContainer = styled.div<ItinerarySidebarContainerProps>`
  height: 100%;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${(props) => (props.isMobile ? '8rem' : '')};
`;

export const ItinerarySidebarDefaultParagraph = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.06em;
  text-align: center;
  color: black;
  margin-bottom: 22px;
`;
