import styled, { css } from 'styled-components';
import { ClearRounded } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { TabList, TabPanel } from '@mui/lab';
import { Button } from '@mui/material';
import SkeletonTheme from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const swipeableCardContainerSmallStyle = css`
  width: 100%;
  aspect-ratio: 3 / 1;
  align-items: center;
`;

const cardContainerSmallStyle = css`
  width: 95%;
  aspect-ratio: 3 / 1;
  align-items: center;
`;

const cardContainerLargeStyle = css`
  width: 14vw;
  flex-flow: column wrap;
`;

type CardContainerProps = {
  isDragging: boolean,
  isLarge: boolean,
  isMobile: boolean,
  canEdit: boolean,
};

const getCardContainerStyles = (props: CardContainerProps) => {
  if (props.isLarge) {
    return cardContainerLargeStyle;
  }
  if (props.isMobile && props.canEdit) {
    return swipeableCardContainerSmallStyle;
  }
  return cardContainerSmallStyle;
};

export const CardContainer = styled.div<CardContainerProps>`
  ${getCardContainerStyles}
  display: flex;
  position: relative;
  cursor: pointer;
  border-radius: 10px;

  opacity: ${(props) => (props.isDragging ? 0 : 1)};

  -moz-box-shadow: 1px 1px 4px 2px #ccc;
  -webkit-box-shadow: 1px 1px 4px 2px #ccc;
  box-shadow: 1px 1px 4px 2px #ccc;
`;

export const Delete = styled(ClearRounded)`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 8px;
  right: 8px;

  filter: drop-shadow(1px 1px 1px #fff);
`;

const cardImgContainerSmallStyle = css`
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 10px 0 0 10px;
`;

const cardImgContainerLargeStyle = css`
  width: 100%;
  aspect-ratio: 5 / 4;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
`;

type CardImgProps = {
  isLarge: boolean,
};

const getCardImgContainerStyles = (props: CardImgProps) => {
  if (props.isLarge) {
    return cardImgContainerLargeStyle;
  }
  return cardImgContainerSmallStyle;
};

export const CardImgContainer = styled.div<CardImgProps>`
  ${getCardImgContainerStyles}
`;

export const CardImg = styled.img<CardImgProps>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: rgb(183, 183, 183);
  background-position: center;
  background-size: 90%;
  border-radius: ${(props) => (props.isLarge ? '10px 10px 0 0' : '10px 0 0 10px;')};
`;

export const CardImgSkeleton = styled(SkeletonTheme)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  background-size: 90%;
  --base-color: rgb(183, 183, 183);
  --highlight-color: rgb(204 205 205 / 75%);

  border-radius: 0;
  line-height: normal;
`;

const cardDescriptionContainerSmall = css`
  padding: 0px 16px;
`;

const cardDescriptionContainerLargerStyle = css`
  padding: 16px;
`;

type CardDescriptionContainerProps = {
  isLarge: boolean,
};

const getCardDescriptionContainerStyles = (props: CardDescriptionContainerProps) => {
  if (props.isLarge) {
    return cardDescriptionContainerLargerStyle;
  }
  return cardDescriptionContainerSmall;
};

export const CardDescriptionContainer = styled.div<CardDescriptionContainerProps>`
  ${getCardDescriptionContainerStyles}
`;

type CardNameProps = {
  isItinerarySidebarCard: boolean,
};

export const CardName = styled.h3<CardNameProps>`
  font-size: ${(props) => (props.isItinerarySidebarCard ? '12px' : '14px')};
  font-weight: bold;
  line-height: 1.2;
`;

export const CardDetailsModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px 24px;

  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: repeat(4, fit-content);
  gap: 16px;
  align-items: start;
  background-color: #fafafa;
`;

export const CardDetailsModalHeader = styled.h1`
  grid-row: 1;
  grid-column: 1 / -1;
  font-weight: bold;
`;

export const CardDetailsModalImagesContainer = styled.div`
  grid-row: 2 / span 3;
  grid-column: 1;

  width: 250px;
  display: flex;
  flex-flow: row wrap;
`;

export const CardDetailsModalImageSmall = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;

  &:first-child {
    margin-right: 5px;
  }

  &:nth-child(3) {
    margin-left: 5px;
  }
`;

export const CardDetailsModalImageLarge = styled.img`
  width: 250px;
  height: 200px;
  object-fit: cover;
  margin-top: 5px;
`;

export const CardDetailsModalTabList = styled(TabList)`
  grid-row: 2;
  grid-column: 2 / -1;

  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;

  & .Mui-selected {
    color: black;
  }

  & .MuiTabs-indicator {
    background-color: black;
  }
`;

export const CardDetailsModalTabPanel = styled(TabPanel)`
  grid-row: 3;
  grid-column: 2 / -1;

  display: flex;
  flex-flow: row nowrap;
  padding: 0px 24px;
`;

export const CardDetailsModalInfoContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;

  &:first-child {
    padding-right: 16px;
  }
`;

export const CardDetailsModalInfoSpan = styled.span`
  font-size: 0.8rem;
  line-height: 1.5;
`;

export const CardDetailsModalOptions = styled.div`
  grid-row: 4;
  grid-column: 2 / -1;
  align-self: end;

  display: flex;
  flex-flow: column wrap-reverse;
`;

export const CardDetailsModalDeleteButton = styled(Button)`
  max-width: 120px;
  color: black;
`;

export const SwipeToDeleteContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #db5858;
  border-radius: 10px 0 0 10px;
`;

type SwipeToDeleteProps = {
  isLargeIcon: boolean,
};

export const SwipeToDeleteIcon = styled(DeleteIcon)<SwipeToDeleteProps>`
  color: #f8f8f8;
  font-size: ${(props) => (props.isLargeIcon ? '32px' : '16px')};
  transition: font-size 0.3s ease;
  margin: auto;
`;
