import { configureStore, Middleware } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';
import { rootReducer } from './root-reducer';
import { setCurrentUser } from './user/user.slice';

export const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['user', 'map'],
};

const middlewares = [process.env.NODE_ENV === 'development' && logger].filter(
  (middleware): middleware is Middleware => Boolean(middleware)
);

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          setCurrentUser.toString(),
        ],
        ignoredPaths: ['user.currentUser', 'map.map'],
      },
    }).concat(middlewares),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
