import { formatISO } from 'date-fns';

export const cleanObject = (obj: Record<string, unknown>) =>
  // eslint-disable-next-line no-param-reassign
  Object.keys(obj).forEach((key) => obj[key] === undefined && delete obj[key]);

export const getISODate = (date: Date) => formatISO(date, { representation: 'date' });

export function getIntersection<T>(setA: Set<T>, setB: Set<T>) {
  const intersection = new Set(Array.from(setA).filter((x) => setB.has(x)));
  return intersection;
}
