import { ForwardedRef, useEffect, useRef } from 'react';

// eslint-disable-next-line import/prefer-default-export
export function useShareForwardedRef<T>(forwardedRef: ForwardedRef<T>) {
  const innerRef = useRef<T>(null);

  useEffect(() => {
    if (!forwardedRef) {
      return;
    }
    if (typeof forwardedRef === 'function') {
      forwardedRef(innerRef.current);
    } else {
      // eslint-disable-next-line no-param-reassign
      forwardedRef.current = innerRef.current;
    }
  });

  return innerRef;
}
