import styled from 'styled-components';
import { Button } from '@mui/material';

type MobileProp = {
  isMobile: boolean,
};

export const CardDetailsGalleryModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardDetailsGalleryButton = styled(Button)`
  border-radius: 10px;
`;

export const CardDetailsGalleryModalContainer = styled.div<MobileProp>`
  background-color: #fafafa;
  width: ${(props) => (props.isMobile ? '100%' : '925px')};
  height: ${(props) => (props.isMobile ? '100%' : '')};
  padding: ${(props) => (props.isMobile ? '22px 0' : '22px 10px')};
  overflow-y: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: ${(props) => (props.isMobile ? '0' : '10px')};
`;

export const CardDetailsGalleryImagesContainer = styled.div`
  margin-top: 14px;
  flex-wrap: wrap;
`;

export const CardDetailsGalleryImage = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px;
  cursor: pointer;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
`;
