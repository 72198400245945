import styled from 'styled-components';

export const ItineraryContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 20px;
  padding: 0px;
`;

export const ItinerarySidebarContainer = styled.div`
  width: 20%;
`;

interface ItineraryMainContainerProps {
  canEdit: boolean,
  isMobile: boolean,
}
export const ItineraryMainContainer = styled.div<ItineraryMainContainerProps>`
  width: ${(props) => (props.canEdit && !props.isMobile ? '80%' : '100%')};
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  padding-left: ${(props) => (props.isMobile ? '16px' : '')};
`;

export const ItineraryDateHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const ItineraryDateHeader = styled.h2``;

export const ItineraryCalendarKeyContainer = styled.div`
  width: 2em;
  display: grid;
  grid-template-rows: 40px 40px 50px minmax(40vh, auto);
  grid-template-columns: 2em;
  gap: 20px;
`;

interface ItineraryCalendarContainerProps {
  isMultidayEvents: boolean,
  isMobile: boolean,
}
export const ItineraryCalendarContainer = styled.div<ItineraryCalendarContainerProps>`
  margin-top: 36px;
  width: calc(100% - 20px); //calc(100% - 2em - 20px);
  min-height: 100% !important;

  display: grid;

  grid-template-rows: 40px ${(props) => props.isMultidayEvents && 'max-content'} minmax(40vh, auto);
  // grid-template-rows: 40px 40px 50px minmax(40vh, auto);
  grid-auto-columns: minmax(${(props) => (props.isMobile ? '200px' : '250px')}, 20%);
  grid-auto-flow: column;
  gap: 5px;
  overflow-x: scroll;
`;

export const ItineraryMultiDaysEvents = styled.div`
  grid-column: 1 / span ${(props: { amountOfColumns: number }) => props.amountOfColumns};
  grid-row: 2 / 3;
  display: grid;
  grid-template-columns: repeat(
    ${(props: { amountOfColumns: number }) => props.amountOfColumns},
    1fr
  );
  grid-column-gap: 12px;
  grid-row-gap: 2px;
  height: fit-content;
`;

export const ItineraryMultiDaysEventsItem = styled.div`
  grid-column: ${(props: { startDate: number, spanDate: number }) => props.startDate} /
    ${(props: { startDate: number, spanDate: number }) =>
      props.spanDate < 0 ? props.spanDate : `span ${props.spanDate}`};
  height: fit-content;
`;

interface ItineraryCalendarLabelProps {
  row: number,
}

export const ItineraryCalendarLabel = styled.span`
  grid-column: 1 / span 1;
  grid-row: ${(props: ItineraryCalendarLabelProps) => props.row} / span 1;
  align-self: start;
  font-size: 2em;
`;

interface ItineraryCalendarDayProps {
  col: number,
  row?: number,
}

export const ItineraryCalendarDayTitle = styled.h4`
  grid-column: ${(props: ItineraryCalendarDayProps) => props.col} / span 1;
  grid-row: 1 / span 1;
  justify-self: center;
  align-self: center;
  margin: 0;
`;

export const ItineraryCalendarDayLodging = styled.div`
  grid-column: ${(props: ItineraryCalendarDayProps) => props.col} / span 1;
  grid-row: 2 / span 1;

  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.25);
`;

export const ItineraryCalendarDayNotes = styled.div`
  grid-column: ${(props: ItineraryCalendarDayProps) => props.col} / span 1;
  grid-row: 3 / span 1;

  background: #efefef;
`;
