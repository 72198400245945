import { MouseEventHandler } from 'react';
import MarkerEmoji from './marker-emoji/marker-emoji.component';

export enum MarkerType {
  EMOJI = 'emoji',
}

export const getCustomMarker = (markerType: MarkerType) =>
  ({ [MarkerType.EMOJI]: MarkerEmoji }[markerType]);

export interface Marker {
  id: string,
  markerType: MarkerType,
  location: {
    lat: number,
    lng: number,
  },
  data: MarkerEmojiData,
  onClick?: MouseEventHandler,
}

export interface MarkerEmojiData {
  emoji: string,
  description: string,
  isHighlighted: boolean,
}
