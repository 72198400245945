import styled, { css } from 'styled-components';
import { TabList, TabPanel } from '@mui/lab';
import { Button } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LaunchIcon from '@mui/icons-material/Launch';

type CardDetailsModalContainerProps = {
  canEdit: boolean,
};

export const CardDetailsModalContainer = styled.div<CardDetailsModalContainerProps>`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 700px;
  padding: ${(props) => (props.canEdit ? '16px 24px' : '16px 24px 45px 24px')};

  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fafafa;
  border-radius: 10px 0 0 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: auto;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BackToPlaygroundButton = styled(Button)`
  border-radius: 10px;
`;

export const OptionsMenuDiv = styled.div`
  cursor: pointer;
`;

export const OptionsMenuButton = styled(MoreHorizIcon)``;

export const CardDetailsModalOptions = styled.div`
  align-self: flex-end;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
`;

export const OpenPhotoGalleryButton = styled.button`
  position: absolute;
  color: white;
  background-color: rgba(41, 41, 41, 0.8);
  border: none;
  border-radius: 10px;
  bottom: 1em;
  right: 1em;
  padding: 12px 12px;
  z-index: 1;
  cursor: pointer;
  text-transform: capitalize;
`;

export const MainCoverImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
`;

export const CoverImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: rgb(0, 0, 0, 0.2);
`;

export const StaticMapContainer = styled.div`
  width: 100%;
  height: 45%;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: rgb(0, 0, 0, 0.2);
`;

export const StaticMap = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

type CanEditProps = {
  canEdit: boolean,
};

export const CardTitle = styled.h1<CanEditProps>`
  font-weight: bold;
  margin: 0.25em 0;
  padding-left: 12px;
  ${(props) => props.canEdit && '&:hover { cursor: text; }'}
  :focus {
    border-bottom: 0.15rem solid #17a8b2;
    outline: none;
  }
`;

export const SubheaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 22px;
  margin-left: 14px;
`;

export const PriceLevelText = styled.span`
  margin: 0;
  padding: 0;
`;

export const BoldDollar = styled.span`
  font-weight: bold;
  color: black;
`;

export const LightDollar = styled.span`
  opacity: 0.5;
  font-weight: bold;
  color: gray;
`;

export const LocationTypeText = styled.span`
  text-transform: capitalize;
  margin: 0;
  padding: 0;
`;

export const RatingsText = styled.span`
  margin: 0;
  padding: 0;
  margin-right: -12px;
`;

export const GoogleReviewsLinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
`;

export const GoogleReviewsAnchor = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
`;

export const GoogleReviewsText = styled.span`
  text-decoration: underline;
  margin: 0;
  padding: 0;
  margin-right: 4px;
`;

export const GoogleReviewsLaunchIcon = styled(LaunchIcon)`
  font-size: 20px;
  margin: 0;
  padding: 0;
`;

export const CardDetailsTabList = styled(TabList)`
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;

  & .Mui-selected {
    color: black;
  }

  & .MuiTabs-indicator {
    background-color: black;
  }
`;

export const CardDetailsTabPanel = styled(TabPanel)`
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow-y: auto;
`;

type TextEditorProps = {
  toolbar: boolean,
};

export const TextEditorContainer = styled.div<TextEditorProps>`
  display: flex;
  flex-direction: column;
  min-height: 225px;
  background: white;
  border-radius: 10px
    ${(props) =>
      props.toolbar &&
      css`
        div:nth-child(2) {
          flex-grow: 1;
          overflow-y: auto;
          overflow-x: hidden;
          font-size: 0.875rem;
          line-height: 1.25rem;
          letter-spacing: 0.0225rem;
        }
      `};
`;

export const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: start;
  place-content: start;
  gap: 48px;
  padding: 0 12px;
`;

export const StyledAnchor = styled.a`
  &:hover {
    color: #2077de;
  }

  &.website {
    color: #2077de;
  }
`;

export const ContactInfoContainer = styled.div`
  width: 100%;
  grid-column: 1;
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding-left: 6px;
`;

export const HoursInfoContainer = styled.div`
  width: 100%;
  grid-column: 2;
  padding-left: 20px;
`;

export const HoursInfoSubcontainer = styled.div`
  display: grid;
  grid-template-columns: 60px auto;
  row-gap: 2px;
`;

type HoursInfoProps = {
  row: number,
};

export const TimeInfoContainer = styled.div<HoursInfoProps>`
  grid-column: 2;
  grid-row: ${(props) => props.row};
  display: flex;
  flex-direction: column;
`;

export const InfoHeading = styled.h4`
  font-size: 16px;
  margin: 0 0 6px;
`;

export const InfoSpan = styled.span`
  font-size: 16px;
  line-height: 1.5;
`;

type InfoGridSpanProps = {
  row: number,
};

export const InfoGridSpan = styled(InfoSpan)<InfoGridSpanProps>`
  grid-column: 1;
  grid-row: ${(props) => props.row};
`;
