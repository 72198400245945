import React, { useState } from 'react';
import { Modal } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import { PhotoAlbum, RenderPhoto, RenderPhotoProps } from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/counter.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

import useMobileMediaQuery from '../../../utils/media-query.utils';

import {
  CardDetailsGalleryButton,
  CardDetailsGalleryModalButtonContainer,
  CardDetailsGalleryModalContainer,
  CardDetailsGalleryImage,
  CardDetailsGalleryImagesContainer,
} from './card-image-gallery.styles';

type CardImageGalleryProps = {
  imgUrls: string[],
  isEdit: boolean,
  galleryOpen?: boolean,
  handleGalleryClose?: () => void,
  originalSelectedImgIndex?: number | null,
  submitCoverImageChange?: (newCoverImage: number) => void,
};

function CardImageGallery({
  imgUrls,
  isEdit,
  galleryOpen = false,
  handleGalleryClose,
  originalSelectedImgIndex = 0,
  submitCoverImageChange,
}: CardImageGalleryProps) {
  const [index, setIndex] = useState(-1);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(originalSelectedImgIndex || 0);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const isMobile = useMobileMediaQuery();

  const photos = imgUrls.map((imgUrl) => {
    const width = 500;
    const height = 500;
    return {
      src: imgUrl,
      width,
      height,
    };
  });

  const handlePhotoClick = (current: number) => {
    setIndex(current);
    if (isEdit) {
      setSelectedIndex(current === selectedIndex ? null : current);
    }
  };

  // used to style the image and image container
  const renderPhoto: RenderPhoto = ({ imageProps, photo, layout }: RenderPhotoProps) => {
    const currentIndex = layout.index;
    return (
      <div
        style={{ position: 'relative', alignItems: 'center' }}
        onMouseEnter={() => isEdit && setHoveredIndex(currentIndex)}
        onMouseLeave={() => isEdit && setHoveredIndex(null)}
        onClick={() => handlePhotoClick(currentIndex)}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handlePhotoClick(currentIndex);
          }
        }}
        role="button"
        tabIndex={0}
      >
        <img
          style={{
            ...imageProps.style,
            width: isMobile ? '200px' : '225px',
            height: isMobile ? '200px' : '225px',
            objectFit: 'cover',
            padding: isMobile ? '.5px 1px' : '',
          }}
          src={imageProps.src}
          alt={photo.alt}
          draggable={imageProps.draggable}
          onLoad={imageProps.onLoad}
          onError={imageProps.onError}
        />
        {isEdit && selectedIndex === currentIndex && (
          <CardDetailsGalleryImage>
            <div style={{
              backgroundColor: 'white',
              borderRadius: '50%',
            }}>
              <CheckCircleIcon style={{ color: '#2c969d', fontSize: '24px' }} />
            </div>
          </CardDetailsGalleryImage>
        )}
        {isEdit && !isMobile && hoveredIndex === currentIndex && selectedIndex !== currentIndex && (
          <CardDetailsGalleryImage>
            <div style={{ alignItems: 'center' }}>
              <span style={{ color: 'white', fontSize: '14px', fontWeight: 'bold', paddingRight: '8px' }}>Select</span>
              <CircleTwoToneIcon style={{ color: 'white', fontSize: '24px' }} />
            </div>
          </CardDetailsGalleryImage>
        )}
      </div>
    );
  };


  return (
    <Modal open={galleryOpen} onClose={handleGalleryClose}>
      <CardDetailsGalleryModalContainer isMobile={isMobile}>
        <CardDetailsGalleryModalButtonContainer>
          <CardDetailsGalleryButton startIcon={<ArrowBackIcon />} onClick={handleGalleryClose}>
            {isEdit ? 'Cancel' : 'Back to Details'}
          </CardDetailsGalleryButton>
          {isEdit && (
            <CardDetailsGalleryButton onClick={() => submitCoverImageChange?.(selectedIndex ?? 0)} disabled={selectedIndex === originalSelectedImgIndex}>
              Save
            </CardDetailsGalleryButton>
          )}
        </CardDetailsGalleryModalButtonContainer>
        {isEdit && <h3>Choose a cover photo:</h3>}
        <CardDetailsGalleryImagesContainer>
          <PhotoAlbum
            layout="rows"
            photos={photos}
            targetRowHeight={250}
            spacing={4}
            onClick={({ index: current }) => handlePhotoClick(current)}
            renderPhoto={renderPhoto}
          />
          {!isEdit && (
            <Lightbox
              index={index}
              slides={photos}
              open={index >= 0}
              close={() => setIndex(-1)}
              plugins={[Counter, Zoom, Thumbnails]}
              thumbnails={{
                position: 'bottom',
                width: 150,
                height: 120,
                border: 1,
                borderRadius: 10,
                padding: 4,
                gap: 16,
              }}
              counter={{ container: { style: { top: isMobile ? 'unset' : 0, bottom: isMobile ? 0 : '' } } }}
              zoom={{
                scrollToZoom: true,
                maxZoomPixelRatio: 5,
              }}
              styles={{
                container: { backgroundColor: 'rgba(0, 0, 0, 0.95)' },
                slide: { objectFit: 'cover' },
              }}
              controller={{ closeOnBackdropClick: true }}
              render={{
                buttonPrev: () => null,
                buttonNext: () => null,
              }}
            />
          )}
        </CardDetailsGalleryImagesContainer>
      </CardDetailsGalleryModalContainer>
    </Modal>
  );
}

CardImageGallery.displayName = 'CardImageGallery';
export default CardImageGallery;