/**
 *
 * Sidebar
 *
 */

import React, { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { canEditTrip, getElementByIdInArray } from '../../../redux/trip/trip.utils';
import { TripList } from '../../../redux/trip/trip.types';

import {
  selectActiveListId,
  selectCurrentTrip,
  selectListOrder,
  selectLists,
} from '../../../redux/trip/trip.selectors';
import { moveCardToList, updateActiveListId } from '../../../redux/trip/trip.slice';
import { selectCurrentUser } from '../../../redux/user/user.selectors';
import { useOrderedLists } from '../../../hooks/trip/trip-list.hooks';

import AddListInput from './add-list-input/add-list-input.component';
import ListItem from './list-item/list-item.component';

import { ListContainerDiv, ListScrollableDiv, NoListsMessage } from './trip-sidebar.styles';

function TripSidebar() {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const trip = useAppSelector(selectCurrentTrip);
  const canEdit = Boolean(canEditTrip(trip, currentUser));
  const activeListId = useAppSelector(selectActiveListId);
  const lists = useAppSelector(selectLists);
  const listOrder = useAppSelector(selectListOrder);
  const orderedLists = useOrderedLists(lists, listOrder, !canEdit);

  const moveCard = useCallback(
    (cardId: string, fromListId: string, toListId: string) => {
      if (trip) {
        const fromList = getElementByIdInArray(trip.lists, fromListId);
        const toList = getElementByIdInArray(trip.lists, toListId);
        if (fromList && toList) {
          const fromListCardOrder = (fromList as TripList).cardOrder;
          if (fromListCardOrder) {
            const toListCardOrder = (toList as TripList).cardOrder || [];
            dispatch(
              moveCardToList({
                tripId: trip.id,
                cardId,
                fromListId,
                toListId,
                fromListCardOrder,
                toListCardOrder,
              })
            );
          }
        }
      }
    },
    [dispatch, trip]
  );

  // Handle url navigation
  useEffect(() => {
    if (searchParams.has('list')) {
      dispatch(updateActiveListId(searchParams.get('list') || ''));
    }
  }, [searchParams, dispatch]);

  return (
    <ListContainerDiv>
      <AddListInput tripId={trip?.id} canEdit={canEdit} listOrder={listOrder} />
      {trip && trip.lists.length === 0 ? (
        <NoListsMessage>
          {' '}
          Create a list by clicking the &apos;+&apos; button above. Once created, a search bar will
          appear above the map for you to add places to your list!
        </NoListsMessage>
      ) : (
        <ListScrollableDiv>
          {trip &&
            orderedLists.map((list) => (
              <ListItem
                key={list.id}
                tripId={trip.id}
                list={list}
                listOrder={listOrder || []}
                isActive={list.id === activeListId}
                canEdit={canEdit}
                moveCard={moveCard}
              />
            ))}
        </ListScrollableDiv>
      )}
    </ListContainerDiv>
  );
}

TripSidebar.displayName = 'TripSidebar';
export default TripSidebar;
