import { format, parseISO } from 'date-fns';
import { DateRange } from 'react-day-picker';
import { DateISO } from '../redux/trip/trip.types';
import { defaultStartDate } from '../components/trip/constants';

/* eslint-disable import/prefer-default-export */
export const getTripDates = (
  startDateISO: DateISO | undefined,
  endDateISO: DateISO | undefined,
  isShortDate: boolean
) => {
  const startDate = startDateISO ? parseISO(startDateISO) : undefined;
  const endDate = endDateISO ? parseISO(endDateISO) : undefined;
  const dateRange: DateRange | undefined =
    startDate && endDate ? { from: startDate, to: endDate } : undefined;

  if (
    dateRange &&
    dateRange.from &&
    startDate?.toISOString() !== defaultStartDate.toISOString() &&
    dateRange.to
  ) {
    const isSameDay = dateRange.from.toISOString() === dateRange.to.toISOString();

    if (isSameDay) {
      return format(dateRange.from, 'MMMM d, Y');
    }
    if (isShortDate) {
      const startYear = format(dateRange.from, 'yyyy');
      const endYear = format(dateRange.to, 'yyyy');
      const startMonth = format(dateRange.from, 'MMMM');
      const endMonth = format(dateRange.to, 'MMMM');

      if (startYear === endYear && startMonth === endMonth) {
        return `${format(dateRange.from, 'MMM d')} - ${format(dateRange.to, 'd, Y')}`;
      }
      if (startYear === endYear && startMonth !== endMonth) {
        return `${format(dateRange.from, 'MMM d')} - ${format(dateRange.to, 'MMM d, Y')}`;
      }
      return `${format(dateRange.from, 'MMM d, Y')} - ${format(dateRange.to, 'MMM d, Y')}`;
    }
    // dates not shortened on desktop
    return `${format(dateRange.from, 'MMMM d, Y')} - ${format(dateRange.to, 'MMMM d, Y')}`;
  }
  return undefined;
};
