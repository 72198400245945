import React, { ForwardedRef, forwardRef, MutableRefObject, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { getCustomMarker, Marker } from '../marker/marker.types';
import { MapContainer } from './map.styles';

const defaultCenter = { lat: 40.7127753, lng: -74.0059728 }; // New York, NY
const defaultZoom = 14;

type MapProps = {
  center?: { lat: number, lng: number },
  zoom?: number,
  markers?: Marker[],
  markerOnClick?: (marker: Marker) => void,
  onCenterChanged?: (lat: number, lng: number) => void,
  onZoomChanged?: (zoom: number) => void,
};

function setMapOnRef(map: any, ref: ForwardedRef<any>) {
  if (typeof ref === 'function') {
    ref(map);
  } else if (ref) {
    // eslint-disable-next-line no-param-reassign
    (ref as MutableRefObject<any>).current = map;
  }
}

const Map = forwardRef<any, MapProps>(
  (
    {
      center = defaultCenter,
      zoom = defaultZoom,
      markers = [],
      markerOnClick,
      onCenterChanged,
      onZoomChanged,
    },
    ref
  ) => {
    const handleApiLoaded = (map: google.maps.Map) => {
      setMapOnRef(map, ref);

      map.addListener('center_changed', () => {
        const mapCenter = map.getCenter() as google.maps.LatLng;
        if (onCenterChanged) onCenterChanged(mapCenter?.lat(), mapCenter?.lng());
      });
      map.addListener('zoom_changed', () => {
        const mapZoom = map.getZoom() as number;
        if (onZoomChanged) onZoomChanged(mapZoom);
      });
    };

    useEffect(
      () => () => {
        setMapOnRef(undefined, ref);
      },
      [ref]
    );

    return (
      <MapContainer>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_API_KEY!,
            libraries: ['places'],
            id: 'google-maps-script',
          }}
          center={center}
          zoom={zoom}
          options={{ mapId: '5f2483670fd46e10' }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => handleApiLoaded(map)}
        >
          {markers.map((marker) => {
            const CustomMarker = getCustomMarker(marker.markerType);
            return (
              <CustomMarker
                key={marker.id}
                lat={marker.location.lat}
                lng={marker.location.lng}
                data={marker.data}
                onClick={() => markerOnClick && markerOnClick(marker)}
              />
            );
          })}
        </GoogleMapReact>
      </MapContainer>
    );
  }
);

Map.displayName = 'Map';
export default Map;
