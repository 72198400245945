import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const SpinnerOverlay = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
