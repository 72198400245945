import React, { ReactElement } from 'react';
import useMobileMediaQuery from '../../../../../utils/media-query.utils';
import {
  ItinerarySidebarDefaultContainer,
  ItinerarySidebarDefaultParagraph,
} from './itinerary-sidebar-default.styles';

function ItinerarySidebarDefault(): ReactElement {
  const isMobile = useMobileMediaQuery();
  return (
    <ItinerarySidebarDefaultContainer isMobile={isMobile}>
      {isMobile ? (
        <ItinerarySidebarDefaultParagraph>
          Create a list by clicking the &apos;+&apos; button above. Once created, a search bar will
          appear above the map for you to add places to your list!
        </ItinerarySidebarDefaultParagraph>
      ) : (
        <>
          <ItinerarySidebarDefaultParagraph>
            Start by adding places you’re thinking of going to in the Playground tab.
          </ItinerarySidebarDefaultParagraph>
          <ItinerarySidebarDefaultParagraph>
            You’ll see your lists and places saved here. To build your itinerary, drag and drop the
            cards from the lists. To remove cards from the itinerary, simply drag and drop the card
            away from the itinerary and back to its list.
          </ItinerarySidebarDefaultParagraph>
        </>
      )}
    </ItinerarySidebarDefaultContainer>
  );
}

ItinerarySidebarDefault.displayName = 'ItinerarySidebarDefault';
export default ItinerarySidebarDefault;
