import styled from 'styled-components';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type MobileProp = {
  isMobile: boolean,
};

export const PlacesAutocompleteContainer = styled.div<MobileProp & { className?: string }>`
  position: relative;

  &.home-autocomplete {
    width: ${(props) => (props.isMobile ? '90vw' : '28vw')};
  }

  &.tripsList-autocomplete {
    width: 100%;
    height: 44px;
  }
`;

type ErrorProp = {
  hasError?: boolean,
};

export const PlacesAutocompleteInput = styled.input<
  ErrorProp & MobileProp & { className?: string }
>`
  border: none;

  &:focus {
    outline: none;
  }

  &.home-autocomplete {
    height: 100%;
    width: ${(props) => (props.isMobile ? '100%' : '80%')};
    background-color: rgb(0 0 0 / 0%);

    font-size: ${(props) => (props.isMobile ? '1.5rem' : '2rem')};
    text-transform: capitalize;
    border-bottom: 4px solid #${(props) => (props.hasError ? 'eb5757' : 'fed265')};
    padding-left: 4px;
    cursor: pointer;
  }

  &.playground-autocomplete {
    height: 48px;
    width: 100%;
    background: scroll #f0f0f0
      ${(props) => (props.isMobile ? '' : 'url(/svgs/marker.svg) 16px 13px no-repeat')};

    font-size: 0.875rem;
    padding-left: 48px;
    padding-right: 48px;
    border-radius: 10px;
  }

  &.tripsList-autocomplete {
    width: calc(100% - 14px);
    border-bottom: 2px solid #ffd643;
    padding: 12px 30px 10px 38px;
    background: url(/svgs/marker.svg) 6px 8px no-repeat;
  }
`;

export const ClearInputButton = styled(HighlightOffIcon)`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const CancelInputButton = styled(ArrowBackIcon)`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const PlacesAutocompleteSuggestionsContainer = styled.div<
  MobileProp & { className?: string }
>`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-items: center;
  position: absolute;
  z-index: 10;
  background: rgba(255, 255, 255);
  filter: drop-shadow(1px 2px 2px grey);
  opacity: 92%;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  &.home-autocomplete {
    width: ${(props) => (props.isMobile ? '100%' : '80%')};
    margin-left: ${(props) => (props.isMobile ? '' : '46px')};
  }

  &.tripsList-autocomplete {
    width: 96%;
    top: 100%; /* Position it below the input */
    left: 0;
    opacity: 96%;
    z-index: 99;
  }
`;

export const PlacesAutocompleteSuggestion = styled.div`
  padding: 14px 22px;
  font-size: 0.875rem;
  letter-spacing: 0.0225rem;
  line-height: 1.2;
  text-align: left;

  &:hover {
    font-weight: 600;
    color: rgba(33, 159, 168, 1);
  }
`;
