export const formatPhoneNumberForLink = (phoneNumber: string) => phoneNumber.replace(/-|\s+/g, '');

export enum EAddressComponentsType {
  country = 'country',
  postal_code = 'postal_code',
  street_number = 'street_number',
  route = 'route',
  locality = 'locality',
  sublocality = 'sublocality',
}

export const getOpeningHours = (openingHours: string[]) =>
  openingHours.map((dayHours) => {
    const indexOfDayDelimiter = dayHours.indexOf(':');
    const dayOfWeek = dayHours.substring(0, indexOfDayDelimiter + 1).substring(0, 3);
    const hours = dayHours.substring(indexOfDayDelimiter + 2);
    const hoursArray = hours.split(',');
    return { [dayOfWeek]: hoursArray };
  });

export const getLocalityFromAdrString = (
  addressComponents: google.maps.GeocoderAddressComponent[],
  formattedAddress: string
) => {
  let addressString = formattedAddress;

  // eslint-disable-next-line no-restricted-syntax
  for (const item of addressComponents) {
    if (
      item.types.includes(EAddressComponentsType.country) ||
      item.types.includes(EAddressComponentsType.postal_code) ||
      item.types.includes(EAddressComponentsType.street_number) ||
      item.types.includes(EAddressComponentsType.route)
    ) {
      addressString = addressString?.replace(item.long_name, '');
    }
  }
  const result = addressString
    .trim()
    .replace(/\d/g, '')
    .split(',')
    .filter((el: string) => !!el.trim() && el)
    .map((el) => el.trim())
    .at(-1);
  return result;
};

export const getLocalityOrSublocality = ({
  addressComponents,
  formattedAddress,
}: {
  addressComponents?: google.maps.GeocoderAddressComponent[],
  formattedAddress?: string | undefined,
}) => {
  if (!addressComponents) return undefined;

  let sublocality: string | undefined;
  // eslint-disable-next-line no-restricted-syntax
  for (const addressComponent of addressComponents) {
    if (addressComponent.types.find((type) => type === 'locality')) {
      return addressComponent.long_name;
    }
    if (addressComponent.types.find((type) => type === 'sublocality')) {
      return addressComponent.long_name;
    }
  }
  if (formattedAddress) {
    sublocality = getLocalityFromAdrString(addressComponents, formattedAddress);
  }
  return sublocality;
};
