import { DateRange } from 'react-day-picker';
import { format, isSameDay } from 'date-fns';

function getItineraryTitle(dateRange: DateRange | undefined, mobile: boolean) {
  if (dateRange && dateRange.from && dateRange.to) {
    const fromDate = dateRange.from;
    const toDate = dateRange.to;

    if (isSameDay(fromDate, toDate)) {
      return format(fromDate, 'MMMM d, Y');
    }

    if (mobile) {
      const startYear = format(fromDate, 'yyyy');
      const endYear = format(toDate, 'yyyy');
      const startMonth = format(fromDate, 'MMMM');
      const endMonth = format(toDate, 'MMMM');

      if (startYear === endYear && startMonth === endMonth) {
        return `${format(fromDate, 'MMM d')} - ${format(toDate, 'd, Y')}`;
      }
      if (startYear === endYear && startMonth !== endMonth) {
        return `${format(fromDate, 'MMM d')} - ${format(toDate, 'MMM d, Y')}`;
      }
      return `${format(fromDate, 'MMM d, Y')} - ${format(toDate, 'MMM d, Y')}`;
    }
    // dates not shortened on desktop
    return `${format(fromDate, 'MMMM d, Y')} - ${format(toDate, 'MMMM d, Y')}`;
  }
  return 'Set your trip dates';
}

export default getItineraryTitle;
