import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { SpinnerOverlay } from './spinner.styles';

function Spinner() {
  return (
    <SpinnerOverlay>
      <CircularProgress />
    </SpinnerOverlay>
  );
}

Spinner.displayName = 'Spinner';
export default Spinner;
