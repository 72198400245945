import React from 'react';
import { Checkbox, FormGroup } from '@mui/material';
import { TripList } from '../../../../redux/trip/trip.types';
import { getElementByIdInArray } from '../../../../redux/trip/trip.utils';
import {
  TripMapFilterHeading,
  TripMapSelectAllLink,
  TripMapFormControlLabel,
  TripMapFilterItemContainer,
  TripMapFilterItemEmoji,
} from './trip-map-filter.styles';

interface TripMapFilterProps {
  selectedLists: TripList[],
  listsWithCards: TripList[],
  handleUnselectAll: () => void,
  handleSelectAll: () => void,
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
}

function TripMapFilter({
  selectedLists,
  listsWithCards,
  handleUnselectAll,
  handleSelectAll,
  handleChange,
}: TripMapFilterProps) {
  return (
    <>
      <TripMapFilterHeading>Filter</TripMapFilterHeading>
      <TripMapSelectAllLink
        onClick={
          selectedLists.length === listsWithCards.length ? handleUnselectAll : handleSelectAll
        }
      >
        {selectedLists.length === listsWithCards.length ? 'Unselect All' : 'Select All'}
      </TripMapSelectAllLink>
      <FormGroup>
        {listsWithCards.map((list) => (
          <TripMapFormControlLabel
            key={list.id}
            control={
              <Checkbox
                id={list.id}
                checked={getElementByIdInArray(selectedLists, list.id) !== undefined}
                onChange={handleChange}
                style={{ alignItems: 'flex-start' }}
              />
            }
            label={
              <TripMapFilterItemContainer>
                <TripMapFilterItemEmoji>{list.emoji}</TripMapFilterItemEmoji>
                <div>{list.name}</div>
              </TripMapFilterItemContainer>
            }
          />
        ))}
      </FormGroup>
    </>
  );
}

TripMapFilter.displayName = 'TripMapFilter';
export default TripMapFilter;
