import styled from 'styled-components';
import { Button } from '@mui/material';

type MobileProp = {
  isMobile: boolean,
};

export const ForbiddenErrorContainer = styled.div<MobileProp>`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: ${(props) => (props.isMobile ? '85%' : '60%')};
`;

export const ForbiddenErrorImage = styled.img<MobileProp>`
  width: ${(props) => (props.isMobile ? '65' : '36')}vw;
  margin: ${(props) => (props.isMobile ? '-50px auto' : '-150px 0 -75px 0')};
  transform: translate(${(props) => (props.isMobile ? '5px, 0px' : '33px, 10px')});
`;

export const ForbiddenErrorMessage = styled.h2<MobileProp>`
  font-weight: 600;
  font-size: ${(props) => (props.isMobile ? '1.5' : '2')}rem;
  text-transform: uppercase;
`;

export const ForbiddenErrorSubText = styled.p<MobileProp>`
  font-style: italic;
  font-size: ${(props) => (props.isMobile ? '0.85' : '1.25')}rem;
  text-transform: capitalize;
`;

export const ForbiddenErrorButton = styled(Button)<MobileProp>`
  font-size: 14px;
  border-radius: 10px;
  background: #f7d476;
  box-shadow: 2px 2px 16px 0px rgba(0, 0, 0, 0.1);
  padding: 16px 32px;

  &:hover {
    background-color: #000000;
    color: #ffffff;
  }
`;
