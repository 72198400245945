import React, { useState, MouseEvent, forwardRef, useEffect } from 'react';
import { Fade, Tooltip } from '@mui/material';
import { useDrag } from 'react-dnd';
import { useSearchParams } from 'react-router-dom';
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';

import { useAppDispatch } from '../../redux/hooks';
import useMobileMediaQuery from '../../utils/media-query.utils';
import { removeCardFromList } from '../../redux/trip/trip.slice';
import { isNotesEmpty } from '../../utils/slate.utils';

import { TripCard } from '../../redux/trip/trip.types';
import { ItemType } from '../dnd/dnd.types';
import { useShareForwardedRef } from '../../hooks/refs.hooks';
import CardDetailsModal from '../card-details-modal/card-details-modal.component';
import CardDetailsModalMobile from '../card-details-modal/card-details-modal-mobile/card-details-modal-mobile.component';
import { usePlaceImgUrlsForCard } from '../../hooks/trip/trip.hooks';

import {
  CardContainer,
  CardImgContainer,
  CardDescriptionContainer,
  CardImg,
  CardImgSkeleton,
  CardName,
  Delete,
  SwipeToDeleteContainer,
  SwipeToDeleteIcon,
} from './card.styles';

export interface CardProps {
  card: TripCard,
  listId: string,
  tripId: string,
  cardOrder: string[],
  isLarge?: boolean,
  canEdit?: boolean,
  canDelete?: boolean,
  onCardMouseEnter?: (card: TripCard) => void,
  onCardMouseLeave?: (card: TripCard) => void,
  isItinerarySidebarCard?: boolean,
}

const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      card,
      listId,
      tripId,
      cardOrder,
      isLarge = false,
      canEdit = false,
      canDelete = false,
      onCardMouseEnter,
      onCardMouseLeave,
      isItinerarySidebarCard = false,
    },
    forwardedRef
  ) => {
    const dispatch = useAppDispatch();
    const isMobile = useMobileMediaQuery();
    const [searchParams, setSearchParams] = useSearchParams();
    const [open, setOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [swipeProgress, setSwipeProgress] = useState(0);

    const ref = useShareForwardedRef<HTMLDivElement>(forwardedRef);
    const index = cardOrder.indexOf(card.id);
    const isLoading = usePlaceImgUrlsForCard(tripId, card);
    const mapId = '5f2483670fd46e10';

    // sets image source to Static Google Map or imgUrl
    const imgUrl =
      card.imgUrls && card.imgUrls.length > 0
        ? card.imgUrls[card.defaultImage ?? 0]
        : `https://maps.googleapis.com/maps/api/staticmap?center=${card?.location?.latitude},${
            card?.location?.longitude
          }&zoom=15&size=${
            isLarge ? '200x200&scale=4' : '100x100&scale=2'
          }&maptype=roadmap&markers=size:mid%7Ccolor:red%7Clabel:o%7C${card?.location?.latitude},${
            card?.location?.longitude
          }&map_id=${mapId}&key=${process.env.REACT_APP_GOOGLE_API_KEY!}`;

    const [{ isDragging }, dragRef] = useDrag({
      item: { type: ItemType.CARD, id: card.id, card, listId, index },
      canDrag: () => canEdit === true,
      collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
    });

    const handleOpen = () => {
      searchParams.set('card', card.id);
      searchParams.set('card-tab', !isNotesEmpty(card) ? '1' : '2');
      setSearchParams(searchParams);
    };
    const handleClose = () => {
      searchParams.delete('card');
      searchParams.delete('card-tab');
      setSearchParams(searchParams);
    };

    // Handle url navigation
    useEffect(() => {
      setOpen(searchParams.get('card') === card.id);
    }, [searchParams, card.id]);

    const handleMouseEnter = () => () => {
      setIsHovered(true);
      if (onCardMouseEnter) {
        onCardMouseEnter(card);
      }
    };

    const handleMouseLeave = () => () => {
      setIsHovered(false);
      if (onCardMouseLeave) {
        onCardMouseLeave(card);
      }
    };

    // handles delete inside card-details-mobile options menu
    const handleDeleteClick = (event: MouseEvent<EventTarget>) => {
      event.stopPropagation();

      setOpen(false);
      dispatch(removeCardFromList({ tripId, listId, cardId: card.id, cardOrder }));
    };

    // handles swipe-to-delete
    const handleSwipeProgress = (progress: number) => {
      setSwipeProgress(progress);
    };

    const handleSwipeToDelete = () => {
      const cardContainer = ref.current;
      if (cardContainer) {
        cardContainer.style.transition = 'opacity 0.5s ease';
        cardContainer.style.opacity = '0';
        setTimeout(() => {
          setOpen(false);
          dispatch(removeCardFromList({ tripId, listId, cardId: card.id, cardOrder }));
        }, 250); // Wait for the animation to complete before actually removing the card
      }
    };

    const leadingActions = () => (
      <LeadingActions>
        <SwipeAction onClick={handleSwipeToDelete}>
          <SwipeToDeleteContainer>
            <SwipeToDeleteIcon isLargeIcon={swipeProgress >= 50} />
          </SwipeToDeleteContainer>
        </SwipeAction>
      </LeadingActions>
    );

    dragRef(ref);

    return (
      <>
        {isMobile && canEdit ? (
          <SwipeableList
            style={{
              width: '98%',
              borderRadius: '10px',
              MozBoxShadow: '1px 1px 4px 2px #ccc',
              WebkitBoxShadow: '1px 1px 4px 2px #ccc',
              boxShadow: '1px 1px 4px 2px #ccc',
            }}
          >
            <SwipeableListItem
              leadingActions={leadingActions()}
              threshold={0.5}
              onSwipeProgress={handleSwipeProgress}
            >
              <CardContainer
                ref={ref}
                onClick={handleOpen}
                onMouseEnter={handleMouseEnter()}
                onMouseLeave={handleMouseLeave()}
                isLarge={isLarge}
                isDragging={isDragging}
                isMobile={isMobile}
                canEdit={canEdit}
              >
                <Fade in={canDelete && isHovered}>
                  <Tooltip title="Remove from List">
                    <Delete onClick={handleDeleteClick} />
                  </Tooltip>
                </Fade>
                <CardImgContainer isLarge={isLarge}>
                  {isLoading ? <CardImgSkeleton /> : <CardImg src={imgUrl} isLarge={isLarge} />}
                </CardImgContainer>
                <CardDescriptionContainer isLarge={isLarge}>
                  <CardName isItinerarySidebarCard={isItinerarySidebarCard}>
                    {((card.customName ?? card.name) || '').length > 57
                      ? `${((card.customName ?? card.name) || '').substring(0, 57)}...`
                      : card.customName ?? card.name}
                  </CardName>
                </CardDescriptionContainer>
              </CardContainer>
            </SwipeableListItem>
          </SwipeableList>
        ) : (
          <CardContainer
            ref={ref}
            onClick={handleOpen}
            onMouseEnter={handleMouseEnter()}
            onMouseLeave={handleMouseLeave()}
            isLarge={isLarge}
            isDragging={isDragging}
            isMobile={isMobile}
            canEdit={canEdit}
          >
            <Fade in={canDelete && isHovered}>
              <Tooltip title="Remove from List">
                <Delete onClick={handleDeleteClick} />
              </Tooltip>
            </Fade>
            <CardImgContainer isLarge={isLarge}>
              {isLoading ? <CardImgSkeleton /> : <CardImg src={imgUrl} isLarge={isLarge} />}
            </CardImgContainer>
            <CardDescriptionContainer isLarge={isLarge}>
              <CardName isItinerarySidebarCard={isItinerarySidebarCard}>
                {((card.customName ?? card.name) || '').length > 57
                  ? `${((card.customName ?? card.name) || '').substring(0, 57)}...`
                  : card.customName ?? card.name}
              </CardName>
            </CardDescriptionContainer>
          </CardContainer>
        )}
        {open &&
          (!isMobile ? (
            <CardDetailsModal
              card={card}
              tripId={tripId}
              canEdit={canEdit}
              open={open}
              handleClose={handleClose}
              handleDeleteClick={handleDeleteClick}
            />
          ) : (
            <CardDetailsModalMobile
              card={card}
              tripId={tripId}
              canEdit={canEdit}
              open={open}
              handleClose={handleClose}
              handleDeleteClick={handleDeleteClick}
            />
          ))}
      </>
    );
  }
);

Card.displayName = 'Card';
export default Card;
