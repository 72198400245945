import React, { Dispatch, SetStateAction } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { SnackbarMessage } from '../../redux/trip/trip.types';

type Props = {
  message: SnackbarMessage,
  setSnackbarMessage: Dispatch<SetStateAction<SnackbarMessage | null>>,
};

function Toast({
  message,
  setSnackbarMessage,
}: Props): JSX.Element {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={!!message}
      autoHideDuration={5000}
      onClose={() => setSnackbarMessage(null)}
    >
    <Alert severity={message.severity}>{message.text}</Alert>
    </Snackbar>
  );
}

Toast.displayName = 'Toast';
export default Toast;