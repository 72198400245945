/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { Editable } from 'slate-react';

type MobileProp = {
  isMobile: boolean,
};

export const StyledEditable = styled(Editable)<MobileProp>`
  cursor: text;
  padding: ${(props) => (props.isMobile ? '4px 8px' : '16px 24px')};
  max-height: 100%;
`;

export const StyledLink = styled.a`
  cursor: pointer;
  color: blue;
`;

export const EditorToolbar = styled.div<MobileProp>`
  position: relative;
  padding: ${(props) => (props.isMobile ? '14px 14px' : '14px 20px')};
  border-bottom: 2px solid #eee;
  overflow-x: auto;
  white-space: nowrap;

  & > * {
    display: inline-block;
  }

  & > * + * {
    margin-left: 15px;
  }
`;

type EditorButtonProps = {
  reversed?: boolean,
  active?: boolean,
};

export const EditorButton = styled.span<EditorButtonProps>`
  cursor: pointer;
  color: ${(props) =>
    props.reversed ? (props.active ? 'white' : '#aaa') : props.active ? 'black' : '#ccc'};
`;

export const EditorIcon = styled.span`
  font-size: 18px;
  vertical-align: text-bottom;
`;
