import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { useSearchParams } from 'react-router-dom';

import { useAppDispatch } from '../../../../redux/hooks';
import { changeEventLength } from '../../../../redux/trip/trip.operations';
import { EventID, TripCard, TripEvent, TripID } from '../../../../redux/trip/trip.types';
import CardDetailsModal from '../../../card-details-modal/card-details-modal.component';
import { ItemType } from '../../../dnd/dnd.types';
import { EItineraryEvents } from '../itinerary-events/itinerary-events.types';
import CardDetailsModalMobile from '../../../card-details-modal/card-details-modal-mobile/card-details-modal-mobile.component';
import useMobileMediaQuery from '../../../../utils/media-query.utils';
import { useShareForwardedRef } from '../../../../hooks/refs.hooks';

import {
  ItineraryEventContainer,
  ItineraryEventContainerResizer,
  ItineraryEventIcon,
  ItineraryEventTitle,
} from './itinerary-event.styles';

export interface ItineraryEventProps {
  tripId: TripID,
  event: TripEvent,
  card: TripCard,
  emoji: string,
  color: string,
  eventOrder?: EventID[],
  canEdit?: boolean,
  eventLength?: number,
  isMultidayEvent?: boolean,
}

const ItineraryEvent = forwardRef<HTMLDivElement, ItineraryEventProps>(
  (
    {
      tripId,
      event,
      card,
      emoji,
      color,
      eventOrder,
      canEdit,
      eventLength,
      isMultidayEvent,
    },
    forwardedRef
  ) => {
    const dispatch = useAppDispatch();
    const isMobile = useMobileMediaQuery();
    const [searchParams, setSearchParams] = useSearchParams();
    const [open, setOpen] = useState(searchParams.get('card') === card.id);
    const [isResizingActive, setIsResizingActive] = useState(false);
    const [targetStartWidth, setTargetStartWidth] = useState<undefined | number>(undefined);
    const [forceUpdate, setForceUpdate] = useState(false);
    const index = eventOrder && event.id ? eventOrder.indexOf(event.id) : -1;
    const ref = useShareForwardedRef<HTMLDivElement>(forwardedRef);
    const handleOpen = () => {
      if (event.id !== card.id) {
        searchParams.set('card', card.id);
        setSearchParams(searchParams);
      }
    };
    const handleClose = () => {
      searchParams.delete('card');
      setSearchParams(searchParams);
    };

    // Handle url navigation
    useEffect(() => {
      setOpen(searchParams.get('card') === card.id);
    }, [searchParams, card.id]);

    const [{ isDragging }, dragRef] = useDrag({
      item: { type: ItemType.EVENT, event, card, index },
      canDrag: () => (!isResizingActive && canEdit) === true,
      collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
    });

    dragRef(ref);

    const incrementResizing = (
      targetElement: HTMLElement,
      startWidth: number,
      idOfEvent: string
    ) => {
      if (isMultidayEvent && eventLength) {
        const sizeOfOneChunk = Math.floor(startWidth / eventLength);
        const amountOfDaysToAdd = Math.ceil(
          (targetElement.clientWidth - startWidth) / sizeOfOneChunk
        );
        const newEventLength = eventLength + amountOfDaysToAdd;
        dispatch(
          changeEventLength({
            tripId,
            eventId: idOfEvent,
            eventLength: newEventLength,
          })
        );
      } else {
        const newEventLength = Math.ceil(
          Math.ceil(
            targetElement.clientWidth -
              Math.ceil(targetElement.clientWidth / startWidth) *
                (+EItineraryEvents.gridGap + +EItineraryEvents.containerPadding * 2)
          ) / startWidth
        );
        dispatch(
          changeEventLength({
            tripId,
            eventId: idOfEvent,
            eventLength: newEventLength,
          })
        );
      }
    };

    const decrementResizing = (
      targetElement: HTMLElement,
      startWidth: number,
      idOfEvent: string
    ) => {
      const target = targetElement;
      if (!isMultidayEvent) {
        target.style.width = '100%';
      }
      if (isMultidayEvent && eventLength) {
        const sizeOfOneChunk = Math.floor(startWidth / eventLength);
        const amountOfDaysToSubtract = Math.floor(
          (startWidth - target.clientWidth) / sizeOfOneChunk
        );
        if (!amountOfDaysToSubtract) {
          target.style.width = '100%';
        }
        const newEventLength = eventLength - amountOfDaysToSubtract;

        if (newEventLength <= 1) {
          dispatch(
            changeEventLength({
              tripId,
              eventId: idOfEvent,
              eventLength: undefined,
            })
          );
        } else {
          dispatch(
            changeEventLength({
              tripId,
              eventId: idOfEvent,
              eventLength: newEventLength,
            })
          );
        }
        setForceUpdate(!forceUpdate);
      }
    };

    const handleMouseMove = useCallback(
      (e: MouseEvent) => {
        if (event.id) {
          const resizable = document.getElementById(event.id);
          if (resizable) {
            resizable.style.width = `${resizable.offsetWidth + e.movementX}px`;
          }
        }
      },
      [event.id]
    );

    const handleResizeCommit = () => {
      setIsResizingActive(false);
      if (!event.id) {
        return;
      }
      const target = document.getElementById(event.id);
      if (target instanceof HTMLElement && targetStartWidth) {
        if (targetStartWidth > target.clientWidth) {
          decrementResizing(target, targetStartWidth, event.id);
        } else {
          incrementResizing(target, targetStartWidth, event.id);
        }
      }
      document.removeEventListener('mouseup', handleResizeCommit);
      document.removeEventListener('mousemove', handleMouseMove);
    };

    const handleResize = (e: React.MouseEvent) => {
      if (e.type === 'mousedown') {
        setIsResizingActive(true);
        document.addEventListener('mouseup', handleResizeCommit);
        document.addEventListener('mousemove', handleMouseMove);
      }
      if (e.type === 'mouseup') {
        document.removeEventListener('mouseup', handleResizeCommit);
        document.removeEventListener('mousemove', handleMouseMove);
      }
    };

    useEffect(() => {
      if (!event.id) {
        return;
      }
      const target = document.getElementById(event.id);
      if (target) {
        target.style.width = '100%';
        setTargetStartWidth(target.clientWidth);
      }
    }, [event.id, targetStartWidth, eventLength]);

    return (
      <>
        <ItineraryEventContainer
          id={event.id}
          ref={ref}
          onClick={handleOpen}
          color={color}
          isConfirmed={event.isConfirmed}
          isDragging={isDragging}
        >
          <ItineraryEventIcon>{emoji}</ItineraryEventIcon>
          <ItineraryEventTitle backgroundColor={color}>
            {((card.customName ?? card.name) || '').length > 70
              ? `${((card.customName ?? card.name) || '').substring(0, 70)}...`
              : card.customName ?? card.name}
          </ItineraryEventTitle>
          <ItineraryEventContainerResizer
            draggable="true"
            onMouseDown={handleResize}
            onMouseUp={handleResize}
            onClick={(e) => e.stopPropagation()}
          />
        </ItineraryEventContainer>

        {open &&
          (isMobile ? (
            <CardDetailsModalMobile
              card={card}
              tripId={tripId}
              open={open}
              canEdit={canEdit}
              handleClose={handleClose}
            />
          ) : (
            <CardDetailsModal
              card={card}
              tripId={tripId}
              open={open}
              canEdit={canEdit}
              handleClose={handleClose}
            />
          ))}
      </>
    );
  }
);

ItineraryEvent.displayName = 'ItineraryEvent';
export default ItineraryEvent;
