/* eslint-disable camelcase */
import React, { ChangeEvent, useState } from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import useMobileMediaQuery from '../../utils/media-query.utils';
import {
  PlacesAutocompleteContainer,
  PlacesAutocompleteInput,
  CancelInputButton,
  ClearInputButton,
  PlacesAutocompleteSuggestion,
  PlacesAutocompleteSuggestionsContainer,
} from './places-autocomplete.styles';

type PlacesAutocompleteProps = {
  location?: { lat: number, lng: number },
  radius?: number,
  types?: string[],
  placeholder?: string,
  className?: string,
  onSuggestionMouseEnter?: (suggestion: google.maps.places.AutocompletePrediction) => void,
  onSuggestionMouseLeave?: (suggestion: google.maps.places.AutocompletePrediction) => void,
  onSuggestionSelect?: (suggestion: google.maps.places.AutocompletePrediction) => void,
  setIsAddingPlace?: (value: boolean) => void,
  shouldClearValueOnSelect?: boolean,
  isMobilePlayground?: boolean,
  isHomeInput?: boolean,
  hasError?: boolean,
};

function PlacesAutocomplete({
  location = { lat: 40.7127753, lng: -74.0059728 },
  radius = 10000,
  types,
  placeholder,
  className,
  onSuggestionMouseEnter,
  onSuggestionMouseLeave,
  onSuggestionSelect,
  setIsAddingPlace,
  shouldClearValueOnSelect,
  isMobilePlayground,
  isHomeInput,
  hasError,
}: PlacesAutocompleteProps) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: location ? new window.google.maps.LatLng(location.lat, location.lng) : undefined,
      radius,
      types,
    },
    debounce: 300,
  });
  const [clearInputOnClickAway, setClearInputOnClickAway] = useState(true);
  const isMobile = useMobileMediaQuery();
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
    if (clearInputOnClickAway) {
      setValue('');
    }
  });

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = (suggestion: google.maps.places.AutocompletePrediction) => () => {
    setValue(shouldClearValueOnSelect ? '' : suggestion.description, false);
    clearSuggestions();

    if (onSuggestionSelect) {
      setClearInputOnClickAway(false);
      onSuggestionSelect(suggestion);
      if (setIsAddingPlace) {
        setIsAddingPlace(false);
      }
    }
  };

  const handleMouseEnter = (suggestion: google.maps.places.AutocompletePrediction) => () => {
    if (onSuggestionMouseEnter) {
      onSuggestionMouseEnter(suggestion);
    }
  };

  const handleMouseLeave = (suggestion: google.maps.places.AutocompletePrediction) => () => {
    if (onSuggestionMouseLeave) {
      onSuggestionMouseLeave(suggestion);
    }
  };

  const handleCancelInput = () => {
    setValue('');
    clearSuggestions();
    setIsAddingPlace?.(false);
  };

  const handleClearInput = () => {
    setValue('');
    clearSuggestions();
  };

  const renderSuggestions = () => (
    <PlacesAutocompleteSuggestionsContainer isMobile={isMobile} className={className}>
      {data.map((suggestion, index) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { place_id, description } = suggestion;

        return (
          <div key={place_id}>
            <PlacesAutocompleteSuggestion
              onMouseEnter={handleMouseEnter(suggestion)}
              onMouseLeave={handleMouseLeave(suggestion)}
              onClick={handleSelect(suggestion)}
            >
              <span>{description}</span>
            </PlacesAutocompleteSuggestion>
            {index !== data.length - 1}
          </div>
        );
      })}
    </PlacesAutocompleteSuggestionsContainer>
  );

  return (
    <PlacesAutocompleteContainer ref={ref} isMobile={isMobile} className={className}>
      <PlacesAutocompleteInput
        value={value}
        onChange={handleInput}
        disabled={!ready && !isMobilePlayground}
        placeholder={placeholder}
        isMobile={isMobile}
        className={className}
        hasError={hasError}
        autoFocus={isMobilePlayground}
      />
      {isMobilePlayground && <CancelInputButton onClick={handleCancelInput} />}
      {value.length !== 0 && !isHomeInput && <ClearInputButton onClick={handleClearInput} />}
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && renderSuggestions()}
    </PlacesAutocompleteContainer>
  );
}

PlacesAutocomplete.displayName = 'PlacesAutocomplete';
export default PlacesAutocomplete;
