import styled from 'styled-components';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ListItemText } from '@mui/material';

type MobileProp = {
  isMobile: boolean,
};

export const SortByButton = styled(Button)<MobileProp>`
  text-transform: capitalize;
  font-size: ${(props) => (props.isMobile ? '12px' : '16px')};
  width: ${(props) => (props.isMobile ? '160' : '172')}px;
  justify-content: left;
  border-radius: 10px;
  margin-right: ${(props) => (props.isMobile ? '-26px' : '20px')};
`;

export const StyledMenu = styled(Menu)<MobileProp>`
  width: ${(props) => (props.isMobile ? '275px' : '325px')};
  .MuiPaper-root {
    border-radius: 10px;
  }
  margin-left: ${(props) => (props.isMobile ? '-10px' : '-30px')};
`;

export const StyledMenuItem = styled(MenuItem)<MobileProp>`
  padding: ${(props) => (props.isMobile ? '12px 18px' : '12px 24px')};
`;

export const StyledListItemText = styled(ListItemText)<MobileProp>`
  .MuiTypography-root {
    font-size: ${(props) => (props.isMobile ? '14px' : '18px')};
  }
`;
