import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from '@mui/material';
import { FormatListBulleted } from '@mui/icons-material';
import Slide from '@mui/material/Slide';
import { CardID, TripCard, TripList } from '../../../redux/trip/trip.types';
import Map from '../../map/map.component';
import { getMarkersForCardsInListsAndExtendBounds } from '../../map/map.utils';
import { Marker } from '../../marker/marker.types';
import { useOrderedLists } from '../../../hooks/trip/trip-list.hooks';
import useMobileMediaQuery from '../../../utils/media-query.utils';
import TripMapFilter from './trip-map-filter/trip-map-filter';

import {
  TripMapContainer,
  TripMapFilterFab,
  TripMapFilterContainerMobile,
  TripMapFilterContainer,
} from './trip-map.styles';

interface TripMapProps {
  center?: { lat: number, lng: number },
  zoom?: number,
  cards: { [key: CardID]: TripCard },
  lists: TripList[],
  listOrder: string[],
  markerOnClick?: (marker: Marker) => void,
  onCenterChanged?: (lat: number, lng: number) => void,
  onZoomChanged?: (zoom: number) => void,
  fitBounds: boolean,
}

function TripMap({
  center,
  zoom,
  cards,
  lists,
  listOrder,
  markerOnClick,
  onCenterChanged,
  onZoomChanged,
  fitBounds,
}: TripMapProps) {
  const isMobile = useMobileMediaQuery();
  const listsWithCards = useOrderedLists(
    lists.filter((list) => list.cardOrder && list.cardOrder.length > 0),
    listOrder
  );
  // Initialize selectedLists from sessionStorage if it exists
  const storedValue = sessionStorage.getItem('selectedLists');
  const initialSelectedLists = storedValue ? JSON.parse(storedValue) : listsWithCards;
  const [selectedLists, setSelectedLists] = useState<TripList[]>(initialSelectedLists);
  const [filterOpen, setFilterOpen] = useState(false);
  const [shouldFitBounds, setShouldFitBounds] = useState(false);
  const ref = useRef<any>(null);

  const bounds = new window.google.maps.LatLngBounds();
  const { markers, didBoundsUpdate } = getMarkersForCardsInListsAndExtendBounds(
    selectedLists,
    cards,
    bounds
  );

  function useHookWithRefCallback() {
    const setRef = useCallback((node) => {
      if (ref.current) {
        // Make sure to cleanup any events/references added to the last instance
      }

      if (node) {
        // Check if a node is actually passed. Otherwise node would be null.
        // You can now do what you need to, addEventListeners, measure, etc.
        if (fitBounds && didBoundsUpdate) {
          node.fitBounds(bounds);
          if (node.getZoom() > 14) {
            node.setZoom(14);
          }
        }
      }

      // Save a reference to the node
      ref.current = node;
    }, []);

    return [setRef];
  }
  const [mapRef] = useHookWithRefCallback();

  if (shouldFitBounds && ref.current) {
    ref.current.fitBounds(bounds);
    if (ref.current.getZoom() > 14) {
      ref.current.setZoom(14);
    }
    setShouldFitBounds(false);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const selectedList = lists.find((list) => list.id === event.target.id);
      if (selectedList) {
        setSelectedLists(selectedLists.concat([selectedList]));
      }
    } else {
      setSelectedLists(selectedLists.filter((list) => list.id !== event.target.id));
    }
  };

  const handleSelectAll = () => {
    setSelectedLists(listsWithCards);
    setShouldFitBounds(true);
  };

  const handleUnselectAll = () => {
    setSelectedLists([]);
    setShouldFitBounds(false);
  };

  useEffect(() => {
    // Save selectedLists to sessionStorage whenever it changes
    sessionStorage.setItem('selectedLists', JSON.stringify(selectedLists));
  }, [selectedLists]);

  return (
    <TripMapContainer>
      <Map
        ref={mapRef}
        center={center}
        zoom={zoom}
        markers={markers}
        markerOnClick={markerOnClick}
        onCenterChanged={onCenterChanged}
        onZoomChanged={onZoomChanged}
      />

      {isMobile &&
        (!filterOpen ? (
          <TripMapFilterFab onClick={() => setFilterOpen(true)}>
            <FormatListBulleted fontSize="large" />
          </TripMapFilterFab>
        ) : (
          <Modal open={filterOpen} onClose={() => setFilterOpen(false)}>
            <Slide direction="up" in={filterOpen} mountOnEnter unmountOnExit>
              <TripMapFilterContainerMobile>
                <TripMapFilter
                  selectedLists={selectedLists}
                  listsWithCards={listsWithCards}
                  handleUnselectAll={handleUnselectAll}
                  handleSelectAll={handleSelectAll}
                  handleChange={handleChange}
                />
              </TripMapFilterContainerMobile>
            </Slide>
          </Modal>
        ))}

      {!isMobile && listsWithCards.length > 0 && (
        <TripMapFilterContainer>
          <TripMapFilter
            selectedLists={selectedLists}
            listsWithCards={listsWithCards}
            handleUnselectAll={handleUnselectAll}
            handleSelectAll={handleSelectAll}
            handleChange={handleChange}
          />
        </TripMapFilterContainer>
      )}
    </TripMapContainer>
  );
}

TripMap.displayName = 'TripMap';
export default TripMap;
