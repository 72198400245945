import { TripList } from '../redux/trip/trip.types';

const colors = ['#f0d279', '#3656b7', '#f4bceb', '#dff0f0', '#b3c99f', '#a53436'];
// eslint-disable-next-line
export const getEmojiAndColorForCard = (cardId: string, lists: TripList[]) => {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < lists.length; i++) {
    const list = lists[i];
    if (list.cardOrder && list.cardOrder.indexOf(cardId) !== -1) {
      return { emoji: list.emoji, color: list.color ?? colors[i % colors.length] };
    }
  }
  return { emoji: '🙂', color: '#ffffff' };
};
