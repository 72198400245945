/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { forwardRef, useCallback } from 'react';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { Transforms } from 'slate';

import { ChecklistItemContainer, ChecklistItemInputSpan, ChecklistItemLabelSpan } from './checklist.styles';

/** Slate checklist component for rich-text-editor
 *
 * Using forwardRef solves issue with resolving a Slate point from a DOM point caused by a mismatch between the position of the cursor in the DOM and the corresponding position in the Slate editor
 */
const ChecklistItemElement = forwardRef(({
  attributes,
  children,
  element,
  readOnly
}, ref) => {
  const editor = useSlateStatic();
  const { checked } = element;

  const handleCheck = useCallback((event) => {
    const path = ReactEditor.findPath(editor, element);
    const newProperties = { checked: event.target.checked };
    Transforms.setNodes(editor, newProperties, { at: path });
  }, [editor, element]);

  return (
    <ChecklistItemContainer {...attributes}>
      <ChecklistItemInputSpan contentEditable={false}>
        <input
          type="checkbox"
          checked={checked}
          onChange={handleCheck}
          ref={ref}
          disabled={readOnly}
        />
      </ChecklistItemInputSpan>
      <ChecklistItemLabelSpan suppressContentEditableWarning checked={checked}>
        {children}
      </ChecklistItemLabelSpan>
    </ChecklistItemContainer>
  );
});

ChecklistItemElement.displayName = 'ChecklistItemElement';
export default ChecklistItemElement;
