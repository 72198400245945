import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  writeBatch,
  serverTimestamp,
  query,
  where,
  updateDoc,
  documentId,
  deleteField,
  addDoc,
} from 'firebase/firestore';
import {
  getAuth,
  onAuthStateChanged,
  GoogleAuthProvider,
  User,
  NextOrObserver,
} from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Descendant } from 'slate';
import {
  CardID,
  DateISO,
  DayID,
  EventID,
  ListID,
  TripID,
} from '../redux/trip/trip.types';
import {
  AdditionalInformation,
  CardData,
  DayData,
  DeletableRolesMap,
  EventData,
  ListData,
  NewTripData,
  TripRole,
  UserToRole,
} from './firebase.types';

const firebaseConfig = {
  apiKey: 'AIzaSyBfBMxBkAGqkDBwAuHRNO0exf3C1cgy9Xs',
  authDomain: 'wander-e4f0d.firebaseapp.com',
  projectId: 'wander-e4f0d',
  storageBucket: 'wander-e4f0d.appspot.com',
  messagingSenderId: '930668014304',
  appId: '1:930668014304:web:2582f667b0cf844b0a3e85',
  measurementId: 'G-SR6VJ4M943',
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore(app);
export const functions = getFunctions(app);

export const createUserDocumentFromAuth = async (
  userAuth: User,
  additionalInformation: AdditionalInformation = {} as AdditionalInformation
) => {
  if (!userAuth) return;

  const userRef = doc(db, `users/${userAuth.uid}`);

  const userSnapshot = await getDoc(userRef);

  if (!userSnapshot.exists()) {
    const { displayName, email } = userAuth;
    try {
      await setDoc(userRef, {
        displayName,
        email,
        createdAt: serverTimestamp(),
        ...additionalInformation,
      });
    } catch (error) {
      console.log('error creating user', error);
    }
  }
};

export const getUserDocumentsWithEmails = async (emails: string[]) => {
  const usersRef = collection(db, 'users');
  const q = query(usersRef, where('email', 'in', emails));
  return getDocs(q);
};

export const getUserDocumentsWithIds = async (userIds: string[]) => {
  const usersRef = collection(db, 'users');
  const q = query(usersRef, where(documentId(), 'in', userIds));
  return getDocs(q);
};

export const getTripsForUser = async (userId: string) => {
  const tripsRef = collection(db, 'trips');
  const q = query(tripsRef, where(`roles.${userId}`, 'in', ['owner', 'editor', 'viewer']));
  return getDocs(q);
};

export const getTripDocument = async (id: TripID) => {
  const tripRef = doc(db, 'trips', id);
  return getDoc(tripRef);
};

export const updateUserRoleInTrip = async (tripId: TripID, userId: string, role: TripRole) => {
  const tripRef = doc(db, 'trips', tripId);
  await updateDoc(tripRef, { [`roles.${userId}`]: role });
};

export const removeUserFromTrip = async (tripId: TripID, userId: string) => {
  const tripRef = doc(db, 'trips', tripId);
  await updateDoc(tripRef, { [`roles.${userId}`]: deleteField() });
};

export const updateRolesInTrip = async (
  tripId: TripID,
  rolesToUpdate: UserToRole[],
  usersToRemove: string[]
) => {
  const tripRef = doc(db, 'trips', tripId);
  const roles = rolesToUpdate.reduce((previousValue, currentValue) => {
    const newValue = {
      ...previousValue,
      [`roles.${currentValue.userId}`]: currentValue.role,
    };
    return newValue;
  }, {} as DeletableRolesMap);
  usersToRemove.forEach((userId) => {
    roles[`roles.${userId}`] = deleteField();
  });
  await updateDoc(tripRef, roles);
};

const createListObject = (name: string, emoji: string) => ({
  name,
  emoji,
  createdAt: serverTimestamp(),
});

export const createTripDocumentAndLists = async (userId: string, data: NewTripData) => {
  const batch = writeBatch(db);

  const newTripRef = doc(collection(db, 'trips'));
  const foodAndDrinksListRef = doc(collection(db, 'trips', newTripRef.id, 'lists'));
  const thingsToDoListRef = doc(collection(db, 'trips', newTripRef.id, 'lists'));
  const lodgingRef = doc(collection(db, 'trips', newTripRef.id, 'lists'));
  const listOrder = [foodAndDrinksListRef.id, thingsToDoListRef.id, lodgingRef.id];

  batch.set(newTripRef, {
    roles: { [userId]: 'owner' },
    public: false,
    listOrder,
    ...data,
    createdAt: serverTimestamp(),
  });
  batch.set(foodAndDrinksListRef, createListObject('Food and drinks', '🍲'));
  batch.set(thingsToDoListRef, createListObject('Things to do', '🚲'));
  batch.set(lodgingRef, createListObject('Lodging', '🏨'));

  await batch.commit();

  return getDoc(newTripRef);
};

export const deleteTripDocument = async (tripId: TripID) => {
  const recursiveDeleteTrip = httpsCallable(functions, 'recursiveDeleteTrip');
  recursiveDeleteTrip({ tripId, path: '' });
};

export const getListsDocs = async (tripId: TripID) =>
  getDocs(collection(db, 'trips', tripId, 'lists'));

export const updateTripField = async (tripId: TripID, field: string, value: any) => {
  const tripRef = doc(db, 'trips', tripId);
  await updateDoc(tripRef, { [field]: value });
};

export const createListInTrip = async (tripId: TripID, list: ListData, listOrder: ListID[]) => {
  const batch = writeBatch(db);
  const tripRef = doc(db, 'trips', tripId);
  const newListRef = doc(collection(db, 'trips', tripId, 'lists'));

  batch.set(newListRef, {
    ...list,
    createdAt: serverTimestamp(),
  });
  batch.update(tripRef, { listOrder: [newListRef.id].concat(listOrder) });
  await batch.commit();

  return getDoc(newListRef);
};

export const updateListInTrip = async (
  tripId: TripID,
  listId: ListID,
  field: string,
  value: any
) => {
  const listRef = doc(db, 'trips', tripId, 'lists', listId);
  await updateDoc(listRef, { [field]: value });
};

export const removeListInTrip = async (
  tripId: TripID,
  listIdToRemove: ListID,
  listOrder: ListID[],
  cardIds: CardID[],
  eventIds: EventID[],
  daysToUpdate: { [key: DayID]: EventID[] }
) => {
  const batch = writeBatch(db);
  const tripRef = doc(db, 'trips', tripId);
  const listRef = doc(db, 'trips', tripId, 'lists', listIdToRemove);

  batch.delete(listRef);
  batch.update(tripRef, { listOrder: listOrder.filter((listId) => listId !== listIdToRemove) });
  Object.keys(daysToUpdate).forEach((dayId) => {
    batch.update(doc(db, 'trips', tripId, 'days', dayId), { eventOrder: daysToUpdate[dayId] });
  });
  cardIds.forEach((cardId) => {
    batch.delete(doc(db, 'trips', tripId, 'cards', cardId));
  });
  eventIds.forEach((eventId) => {
    batch.delete(doc(db, 'trips', tripId, 'events', eventId));
  });
  await batch.commit();
};

export const updateTripListOrder = async (tripId: TripID, listOrder: ListID[]) => {
  const tripRef = doc(db, 'trips', tripId);
  await updateDoc(tripRef, { listOrder });
};

export const getCardsDocs = async (tripId: TripID) =>
  getDocs(collection(db, 'trips', tripId, 'cards'));

export const createCardInList = async (
  tripId: TripID,
  listId: ListID,
  card: CardData,
  cardOrder: CardID[]
) => {
  const batch = writeBatch(db);
  const listRef = doc(db, 'trips', tripId, 'lists', listId);
  const newCardRef = doc(collection(db, 'trips', tripId, 'cards'));

  // Ensure dateAdded is set to serverTimestamp
  const cardWithDateAdded = {
    ...card,
    dateAdded: serverTimestamp(),
  };

  batch.set(newCardRef, cardWithDateAdded);
  batch.update(listRef, { cardOrder: [newCardRef.id].concat(cardOrder) });
  await batch.commit();

  return getDoc(newCardRef);
};

export const deleteCardFromList = async (
  tripId: TripID,
  listId: ListID,
  cardIdToRemove: CardID,
  cardOrder: CardID[],
  eventIdToDelete?: EventID,
  dayToUpdate?: { dayId: DayID, eventOrder: EventID[] }
) => {
  const batch = writeBatch(db);
  const listRef = doc(db, 'trips', tripId, 'lists', listId);
  const cardRef = doc(db, 'trips', tripId, 'cards', cardIdToRemove);

  batch.delete(cardRef);
  batch.update(listRef, { cardOrder: cardOrder.filter((cardId) => cardId !== cardIdToRemove) });
  if (eventIdToDelete) {
    batch.delete(doc(db, 'trips', tripId, 'events', eventIdToDelete));
  }
  if (dayToUpdate) {
    batch.update(doc(db, 'trips', tripId, 'days', dayToUpdate.dayId), { eventOrder: dayToUpdate.eventOrder });
  }
  await batch.commit();
};

export const moveCard = async (
  tripId: TripID,
  cardIdToMove: CardID,
  fromListId: ListID,
  toListId: ListID,
  fromListCardOrder: CardID[],
  toListCardOrder: CardID[]
) => {
  const batch = writeBatch(db);
  const fromListRef = doc(db, 'trips', tripId, 'lists', fromListId);
  const toListRef = doc(db, 'trips', tripId, 'lists', toListId);

  batch.update(fromListRef, { cardOrder: fromListCardOrder.filter((cardId) => cardId !== cardIdToMove) });
  batch.update(toListRef, { cardOrder: toListCardOrder.concat([cardIdToMove]) });
  await batch.commit();
};

export const updateCardInTrip = async (
  tripId: TripID,
  cardId: CardID,
  field: string,
  value: any
) => {
  const cardRef = doc(db, 'trips', tripId, 'cards', cardId);
  await updateDoc(cardRef, { [field]: value });
};

export const deleteFieldInCardInTrip = async (tripId: TripID, cardId: CardID, field: string) => {
  const cardRef = doc(db, 'trips', tripId, 'cards', cardId);
  await updateDoc(cardRef, { [field]: deleteField() });
};

export const updateNotesForCardInList = async (
  tripId: TripID,
  listId: ListID,
  cardId: CardID,
  notes: Descendant[]
) => {
  const cardRef = doc(db, 'trips', tripId, 'lists', listId, 'cards', cardId);
  await updateDoc(cardRef, { notes });
};

export const updateNotesForCard = async (tripId: TripID, cardId: CardID, notes: Descendant[]) => {
  const cardRef = doc(db, 'trips', tripId, 'cards', cardId);
  await updateDoc(cardRef, { notes });
};

export const updateCardOrderInList = async (
  tripId: TripID,
  listId: ListID,
  cardOrder: CardID[]
) => {
  const listRef = doc(db, 'trips', tripId, 'lists', listId);
  await updateDoc(listRef, { cardOrder });
};

export const updateTripDates = async (
  tripId: TripID,
  startDateISO: DateISO,
  endDateISO: DateISO,
  daysToDelete: DayID[],
  eventsToDelete: EventID[]
) => {
  const batch = writeBatch(db);
  const tripRef = doc(db, 'trips', tripId);

  batch.update(tripRef, { startDateISO, endDateISO });
  daysToDelete.forEach((dayId) => {
    batch.delete(doc(db, 'trips', tripId, 'days', dayId));
  });
  eventsToDelete.forEach((eventId) => {
    batch.delete(doc(db, 'trips', tripId, 'events', eventId));
  });

  await batch.commit();
};

export const createDayInTrip = async (tripId: TripID, day: DayData) => {
  const newDayRef = await addDoc(collection(db, 'trips', tripId, 'days'), day);
  return getDoc(newDayRef);
};

export const createDayAndAddEventInTrip = async (
  tripId: TripID,
  day: DayData,
  event: EventData
) => {
  const batch = writeBatch(db);
  const newDayRef = doc(collection(db, 'trips', tripId, 'days'));
  const newEventRef = doc(collection(db, 'trips', tripId, 'events'));

  batch.set(newEventRef, event);
  batch.set(newDayRef, { ...day, eventOrder: [newEventRef.id] });

  await batch.commit();

  return {
    dayId: newDayRef.id,
    eventId: newEventRef.id,
  };
};

export const addEventToDayInTrip = async (
  tripId: TripID,
  dayId: DayID,
  eventOrder: EventID[],
  event: EventData
) => {
  const batch = writeBatch(db);
  const dayRef = doc(db, 'trips', tripId, 'days', dayId);
  const newEventRef = doc(collection(db, 'trips', tripId, 'events'));

  const newEventOrder = eventOrder.concat([newEventRef.id]);
  batch.update(dayRef, { eventOrder: newEventOrder });
  batch.set(newEventRef, event);

  await batch.commit();

  return { eventId: newEventRef.id, eventOrder: newEventOrder };
};

export const moveEventInTrip = async (
  tripId: TripID,
  fromDayId: DayID,
  toDayId: DayID,
  toDateISO: DateISO,
  eventId: EventID,
  fromDayEventOrder: EventID[],
  toDayEventOrder: EventID[]
) => {
  const batch = writeBatch(db);
  const fromDayRef = doc(db, 'trips', tripId, 'days', fromDayId);
  const toDayRef = doc(db, 'trips', tripId, 'days', toDayId);
  const eventRef = doc(db, 'trips', tripId, 'events', eventId);

  batch.update(fromDayRef, { eventOrder: fromDayEventOrder });
  batch.update(toDayRef, { eventOrder: toDayEventOrder });
  batch.update(eventRef, { startDateISO: toDateISO });

  await batch.commit();
};

export const moveEventToNewDayInTrip = async (
  tripId: TripID,
  fromDayId: DayID,
  toDateISO: DateISO,
  eventId: EventID,
  fromDayEventOrder: EventID[],
  toDayEventOrder: EventID[]
) => {
  const batch = writeBatch(db);
  const fromDayRef = doc(db, 'trips', tripId, 'days', fromDayId);
  const newDayRef = doc(collection(db, 'trips', tripId, 'days'));
  const eventRef = doc(db, 'trips', tripId, 'events', eventId);

  batch.update(fromDayRef, { eventOrder: fromDayEventOrder });
  batch.set(newDayRef, {
    dateISO: toDateISO,
    eventOrder: toDayEventOrder,
  });
  batch.update(eventRef, { startDateISO: toDateISO});

  await batch.commit();

  return { toDayId: newDayRef.id };
};

export const deleteEventInTrip = async (
  tripId: TripID,
  dayId: DayID,
  eventOrder: EventID[],
  eventId: EventID
) => {
  const batch = writeBatch(db);
  const dayRef = doc(db, 'trips', tripId, 'days', dayId);
  const eventRef = doc(db, 'trips', tripId, 'events', eventId);

  batch.update(dayRef, { eventOrder });
  batch.delete(eventRef);

  await batch.commit();
};

export const updateDayInTrip = async (tripId: TripID, dayId: DayID, field: string, value: any) => {
  const dayRef = doc(db, 'trips', tripId, 'days', dayId);
  await updateDoc(dayRef, { [field]: value });
};

export const getDaysDocs = async (tripId: TripID) =>
  getDocs(collection(db, 'trips', tripId, 'days'));

export const getEventsDocs = async (tripId: TripID) =>
  getDocs(collection(db, 'trips', tripId, 'events'));

export const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });

export const onAuthStateChangedListener = (callback: NextOrObserver<User>) =>
  onAuthStateChanged(auth, callback);

export const updateLastOpenedForUser = async (tripId: TripID, userId: string) => {
  const tripRef = doc(db, 'trips', tripId);

  const updateData = { [`lastOpened.${userId}`]: serverTimestamp() };

  await updateDoc(tripRef, updateData);

  // Fetch the updated document to get the new timestamp
  const updatedTripSnapshot = await getDoc(tripRef);
  const updatedTripData = updatedTripSnapshot.data();

  // Return the new timestamp for the specified user
  return updatedTripData?.lastOpened?.[userId];
};
