import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const breakpoint = '744px';

export const NavigationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`;

export const MenuIconContainer = styled.div`
  padding-right: 1em;
`;

export const MenuButton = styled(MenuIcon)`
  color: black;
  cursor: pointer;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  margin-left: 148px;
  margin-top: 10px;
`;

export const CloseButtonText = styled.span`
  font-size: 16px;
  color: black;
`;

export const CloseButtonIcon = styled(CloseIcon)`
  font-size: 22px;
  margin-right: 2px;
  margin-bottom: 2px;
`;

export const LogoContainer = styled(Link)`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 2em;

  @media (max-width: ${breakpoint}) {
    padding: 2em 1em;
  }
`;

export const NavLinks = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const NavLink = styled(Link)`
  cursor: pointer;
  padding: 2em;

  @media (max-width: ${breakpoint}) {
    padding: 0.5em 0.5em;
  }

  &.trips {
    font-size: 32px;
    margin-top: 40px;
  }

  &.logout {
    color: #e45656;
    bottom: 80px;
    position: absolute;
    font-size: 24px;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 998; /* Ensure overlay is above other content */
`;
