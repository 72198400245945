import { FormControlLabel, Link } from '@mui/material';
import styled from 'styled-components';

export const TripMapFilterHeading = styled.h4`
  text-transform: uppercase;
`;

export const TripMapSelectAllLink = styled(Link)`
  position: absolute;
  right: 16px;
  top: 40px;
`;

export const TripMapFormControlLabel = styled(FormControlLabel)`
  text-transform: capitalize;
`;

export const TripMapFilterItemContainer = styled.div`
  display: flex;
  margin: 10px 0 8px 0;
`;

export const TripMapFilterItemEmoji = styled.div`
  padding-right: 10px;
`;
