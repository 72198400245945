/* eslint-disable import/prefer-default-export */
import { DateRange } from 'react-day-picker';
import { AlertColor } from '@mui/material';
import { Descendant } from 'slate';
import { LatLng, RolesMap, TripRole } from '../../firebase/firebase.types';

export enum TripCreationStatus {
  NOT_CREATED = 'trip/NOT_CREATED',
  CREATING = 'trip/CREATING',
  CREATED = 'trip/CREATED',
}

export const TripRolesAssignable = [TripRole.EDITOR, TripRole.VIEWER];

export interface Trip extends Record<string, unknown> {
  id: TripID,
  activeListId?: ListID, // ID of list currently selected in playground
  listOrder: ListID[],
  originalListOrder?: ListID[],
  location: LatLng,
  notes: Descendant[],
  public: boolean,
  roles: RolesMap,
  title: string,
  lists: TripList[],
  cards: { [key: CardID]: TripCard },
  cardIdToListId: { [key: CardID]: ListID },
  startDateISO?: DateISO,
  endDateISO?: DateISO,
  days: { [key: DateISO]: TripDay },
  events: { [key: EventID]: TripEvent },
  cardIdToEventId: { [key: CardID]: EventID },
  createdAt: string | null,
  lastOpened?: { [userId: string]: string },
}

export interface CurrentTrip extends Trip {
  isChangedDefaultDates?: boolean,
  deletedEvents?: TripEvent[],
  mapCenter?: { lat: number, lng: number },
  mapZoom?: number,
}

export interface TripList extends Record<string, unknown> {
  id: ListID,
  emoji: string,
  color?: string,
  name: string,
  cardOrder?: CardID[],
  originalCardOrder?: CardID[],
  createdAt: string | null,
}

export interface TripCard extends Record<string, unknown> {
  id: CardID,
  addressComponents?: [google.maps.GeocoderAddressComponent],
  adrAddress?: string,
  businessStatus?: google.maps.places.BusinessStatus,
  formattedAddress?: string,
  formattedPhoneNumber?: string,
  googlePlaceId: string,
  internationalPhoneNumber?: string,
  location?: LatLng,
  name?: string,
  customName?: string,
  notes?: Descendant[],
  openingHours?: string[],
  priceLevel?: number,
  rating?: number,
  types?: string[],
  url?: string,
  website?: string,
  creator: string,
  imgUrls?: string[],
  isInItinerary?: boolean,
  dateAdded: string | null,
  defaultImage?: number | null,
  lastOpened?: { [userId: string]: string },
}

export interface TripDay {
  id?: DayID, // undefined if not yet saved to DB
  dateISO: DateISO,
  notes?: Descendant[],
  eventOrder?: EventID[],
}

export interface TripEvent {
  id?: EventID, // undefined if not yet saved to DB
  cardId: CardID,
  startDateISO: DateISO,
  isConfirmed: boolean,
  endDateISO?: DateISO,
  eventLength?: number,
}

export type TripID = string;
export type ListID = string;
export type CardID = string;
export type DayID = string;
export type EventID = string;
export type DateISO = string; // e.g. 2022-12-25

export const isEventID = (item: EventID | undefined): item is EventID => !!item;

export interface ISetDefaultDaysPayload {
  days: { [key: DateISO]: TripDay },
}

export interface IChangeEventLengthPayload {
  tripId: string,
  eventId: string,
  eventLength?: number,
}

export interface IChangeDefaultDatesThunkPayload {
  tripId: string,
  newStartDate: Date,
  defaultStartDate: Date,
  defaultNumDays: number,
}

export interface IChangeDefaultDatesServicePayload {
  tripId: string,
  enumDays: Record<string, string>,
}

export interface IChangeDefaultDatesThunkResult {
  startDateISO: string,
  endDateISO: string,
  days: { [key: DateISO]: TripDay },
  events: { [key: EventID]: TripEvent },
}

export interface TripDatesUpdateSuccess {
  tripId: TripID,
  startDateISO: DateISO,
  endDateISO: DateISO,
  days: { [key: DateISO]: TripDay },
  events: { [key: EventID]: TripEvent },
  deletedEvents: TripEvent[],
}

export interface TripDatesUpdateProps {
  tripId: TripID,
  oldDateRange?: DateRange,
  newDateRange: DateRange,
  days: { [key: DateISO]: TripDay },
}

export type SnackbarMessage = {
  severity: AlertColor,
  text: string,
};
