import React, { MouseEvent } from 'react';
import { Menu, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

type CardDetailsOptionsMenuProps = {
  anchorEl: HTMLDivElement | null,
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>,
  handleGalleryOpen?: () => void,
  handleDeleteClick?: (event: MouseEvent<EventTarget>) => void,
  setIsEditCoverPhoto?: React.Dispatch<React.SetStateAction<boolean>>,
};

function CardDetailsOptionsMenu({
  anchorEl,
  setAnchorEl,
  handleGalleryOpen,
  handleDeleteClick,
  setIsEditCoverPhoto,
}: CardDetailsOptionsMenuProps) {
  const open = Boolean(anchorEl);

  const handleGalleryEditOpen = () => {
    setIsEditCoverPhoto?.(true);
    handleGalleryOpen?.();
    setAnchorEl(null)
  };

  return (
    <Menu
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{ sx: { borderRadius: '10px', boxShadow: '3px 6px 6px rgba(0,0,0,0.5)' } }}
      onBlur={() => setAnchorEl(null)}
    >
      <MenuItem onClick={handleGalleryEditOpen}>
        <ListItemIcon>
          <ImageOutlinedIcon />
        </ListItemIcon>
        <ListItemText>Change Cover Photo</ListItemText>
      </MenuItem>
      <MenuItem onClick={handleDeleteClick}>
        <ListItemIcon>
          <DeleteOutlinedIcon />
        </ListItemIcon>
        <ListItemText>Remove from List</ListItemText>
      </MenuItem>
    </Menu>
  );
}

CardDetailsOptionsMenu.displayName = 'CardDetailsOptionsMenu';
export default CardDetailsOptionsMenu;
