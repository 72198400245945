import React, { useEffect, useMemo, useState, ChangeEvent, MouseEvent } from 'react';
import produce from 'immer';
import {
  CircularProgress,
  Divider,
  FormControlLabel,
  ListItemIcon,
  MenuItem,
  Modal,
  Switch,
} from '@mui/material';
import {
  IosShare,
  CheckRounded,
  KeyboardArrowDown,
  PersonAddAltRounded,
} from '@mui/icons-material';
import { isEqual } from 'lodash';
import {
  getUserDocumentsWithEmails,
  getUserDocumentsWithIds,
  updateRolesInTrip,
} from '../../../firebase/firebase.utils';
import { selectCurrentUser } from '../../../redux/user/user.selectors';
import { updateTrip, updateTripRoles, updateTripUserEmails } from '../../../redux/trip/trip.slice';
import { SnackbarMessage, Trip, TripRolesAssignable } from '../../../redux/trip/trip.types';
import { TripRole, UserData, UserToRole } from '../../../firebase/firebase.types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import useMobileMediaQuery from '../../../utils/media-query.utils';

import LetterAvatarGroup from '../../letter-avatar/letter-avatar-group.component';
import LetterAvatar from '../../letter-avatar/letter-avatar.component';
import Toast from '../../snackbar/snackbar.component';

import {
  TripCopyLinkButton,
  TripRoleMenu,
  TripSaveDoneButton,
  TripShareButton,
  TripShareCollaboratorRole,
  TripShareContainer,
  TripShareHeader,
  TripShareLinkSettingsSpan,
  TripShareModalContainer,
  TripShareModalRowContainer,
  TripShareModalRowGroup,
  TripShareModalRowGroupEnd,
  TripShareEmailSpan,
  TripShareModalSubcontainer,
  TripShareModalSwitchContainer,
  TripShareSwitchLabel,
  TripShareRoleButton,
  TripShareRoleListItemText,
  TripShareTextField,
  TripShareAddForm,
  TripShareAddButton,
} from './trip-share.styles';

type UserAndRole = UserToRole & UserData;

type TripShareProps = {
  trip: Trip,
  canEdit: boolean,
};

function TripShare({ trip, canEdit }: TripShareProps) {
  const dispatch = useAppDispatch();
  const isMobile = useMobileMediaQuery();
  const currentUser = useAppSelector(selectCurrentUser);
  const [open, setOpen] = useState(false);
  const [userRoles, setUserRoles] = useState<UserAndRole[]>([]);
  const [originalUserRoles, setOriginalUserRoles] = useState<UserAndRole[]>([]);
  const [isPublic, setIsPublic] = useState(trip.public);
  const userEmails = userRoles.map((role) => role.email);
  const [emailInput, setEmailInput] = useState('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage | null>(null);
  const [roleMenuAnchorEl, setRoleMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const hasPendingChanges = useMemo(
    () => !isEqual(userRoles, originalUserRoles),
    [userRoles, originalUserRoles]
  );

  const roleMenuOpen = Boolean(roleMenuAnchorEl);
  const snackbarOpen = Boolean(snackbarMessage);

  const validateEmail = (email: string) =>
    email.match(
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
    );

  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text);
    }
    return Promise.reject(new Error('Browser clipboard not supported'));
  }

  const doesUserEmailAlreadyHaveAccess = (email: string) =>
    userRoles.find((userAndRole) => userAndRole.email === email);

  const getRoleForUser = (userId: string) => {
    const userRole = userRoles.find((userAndRole) => userAndRole.userId === userId);
    if (userRole) {
      return userRole.role;
    }
    return undefined;
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setUserRoles(originalUserRoles);
    setEmailInput('');
    setEmailError(null);
    setOpen(false);
  };

  const handleSaveClick = () => {
    if (hasPendingChanges) {
      setIsSaving(true);
    } else {
      handleClose();
    }
  };

  const handleRoleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setRoleMenuAnchorEl(event.currentTarget);
  };

  const handleRoleMenuItemClick = (event: MouseEvent<HTMLLIElement>, role: TripRole) => {
    const index = userRoles.findIndex(
      (userAndRole) => roleMenuAnchorEl && userAndRole.userId === roleMenuAnchorEl.id
    );
    const newUserRoles = produce(userRoles, (state) => {
      state[index].role = role;
    });
    setUserRoles(newUserRoles);
    setRoleMenuAnchorEl(null);
  };

  const handleRemoveUserClick = () => {
    const index = userRoles.findIndex(
      (userAndRole) => roleMenuAnchorEl && userAndRole.userId === roleMenuAnchorEl.id
    );
    const newUserRoles = produce(userRoles, (state) => {
      state.splice(index, 1);
    });
    setUserRoles(newUserRoles);
    setRoleMenuAnchorEl(null);
  };

  const handleRoleMenuClose = () => {
    setRoleMenuAnchorEl(null);
  };

  const handlePublicChange = () => {
    dispatch(updateTrip({ tripId: trip.id, field: 'public', value: !isPublic }));
    setIsPublic(!isPublic);
  };

  const handleSubmit = (event: MouseEvent) => {
    event.preventDefault();
    if (validateEmail(emailInput)) {
      if (doesUserEmailAlreadyHaveAccess(emailInput)) {
        setEmailError(`${emailInput} already has access to this trip`);
      } else {
        setEmailError(null);
        setIsCheckingEmail(true);
      }
    } else {
      setEmailError('Invalid email');
    }
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmailInput(event.target.value);
  };

  const handleCopyClick = () => {
    copyTextToClipboard(window.location.href)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let isSubscribed = true;

    const fetchUserRoles = async () => {
      const userIds = Object.keys(trip.roles);
      const usersDocs = await getUserDocumentsWithIds(userIds);
      const roles: UserAndRole[] = [];
      usersDocs.forEach((userDoc) => {
        const userAndRole = {
          userId: userDoc.id,
          role: trip.roles[userDoc.id],
          ...(userDoc.data() as UserData),
        };
        if (userAndRole.role === TripRole.OWNER) {
          roles.unshift(userAndRole);
        } else {
          roles.push(userAndRole);
        }
      });

      if (isSubscribed) {
        setUserRoles(roles);
        dispatch(updateTripUserEmails(roles.map((user) => user.email)));
        setOriginalUserRoles(roles);
      }
    };

    if (trip.roles) {
      fetchUserRoles();
    }

    return () => {
      isSubscribed = false;
    };
  }, [dispatch, trip.roles]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchUserWithEmailAndAddRole = async () => {
      if (isSubscribed) {
        setIsCheckingEmail(true);
      }

      const userDocs = await getUserDocumentsWithEmails([emailInput]);
      if (userDocs.empty) {
        if (isSubscribed) {
          setIsCheckingEmail(false);
          setEmailError('No user with this email address');
        }
      } else {
        const newUserRoles: UserAndRole[] = [];
        userDocs.forEach((userDoc) => {
          newUserRoles.push({
            userId: userDoc.id,
            role: TripRole.EDITOR,
            ...(userDoc.data() as UserData),
          });
        });

        if (isSubscribed) {
          setUserRoles(userRoles.concat(newUserRoles));
          setEmailInput('');
          setIsCheckingEmail(false);
        }
      }
    };

    if (isCheckingEmail) {
      fetchUserWithEmailAndAddRole();
    }

    return () => {
      isSubscribed = false;
    };
  }, [emailInput, isCheckingEmail, userRoles]);

  useEffect(() => {
    let isSubscribed = true;

    const save = async () => {
      const rolesToUpdate: UserToRole[] = [];
      userRoles.forEach((userRole) => {
        if (!(userRole.userId in trip.roles) || userRole.role !== trip.roles[userRole.userId]) {
          rolesToUpdate.push({ userId: userRole.userId, role: userRole.role });
        }
      });
      const usersToDelete: string[] = [];
      Object.keys(trip.roles).forEach((userId) => {
        if (!userRoles.find((userRole) => userRole.userId === userId)) {
          usersToDelete.push(userId);
        }
      });

      try {
        await updateRolesInTrip(trip.id, rolesToUpdate, usersToDelete);

        if (isSubscribed) {
          setOpen(false);
          setEmailInput('');
          setEmailError(null);
          setIsSaving(false);
          dispatch(updateTripRoles({ tripId: trip.id, rolesToUpdate, usersToDelete }));
          setSnackbarMessage({
            severity: 'success',
            text: 'Your trip share settings were successfully updated.',
          });
        }
      } catch (error) {
        setSnackbarMessage({
          severity: 'error',
          text: 'There was an error saving your share settings. Please try again later.',
        });
      }
    };

    if (isSaving) {
      save();
    }

    return () => {
      isSubscribed = false;
    };
  }, [dispatch, isSaving, trip.id, trip.roles, userRoles]);

  return (
    <>
      {isMobile ? (
        <IosShare onClick={handleOpen} />
      ) : currentUser && (
        <TripShareContainer>
          <LetterAvatarGroup userRoles={userEmails} />
          <TripShareButton
            variant="outlined"
            startIcon={<PersonAddAltRounded />}
            onClick={handleOpen}
          >
            Share
          </TripShareButton>
        </TripShareContainer>
      )}

      {open && (
        <Modal open={open} onClose={handleClose}>
          <TripShareModalContainer isMobile={isMobile}>
              <TripShareModalSubcontainer>
                <TripShareHeader>{canEdit && 'Add '}Collaborators</TripShareHeader>
                {canEdit &&
                  <TripShareAddForm>
                    <TripShareTextField
                      label="Email address"
                      variant="standard"
                      value={emailInput}
                      onChange={handleEmailChange}
                      error={emailError != null}
                      helperText={emailError}
                      disabled={isCheckingEmail}
                    />
                    <TripShareAddButton type="submit" variant="text" onClick={handleSubmit}>Add ↵</TripShareAddButton>
                  </TripShareAddForm>
                }
                {userRoles.map((userAndRole) => (
                  <TripShareModalRowContainer key={userAndRole.userId}>
                    <TripShareModalRowGroup>
                      <LetterAvatar string={userAndRole.email} capitalize />
                      <TripShareEmailSpan isMobile={isMobile}>
                        {userAndRole.email}
                        {currentUser && userAndRole.userId === currentUser.uid && ' (you)'}
                      </TripShareEmailSpan>
                    </TripShareModalRowGroup>
                    {userAndRole.role === TripRole.OWNER || !canEdit ? (
                      <TripShareCollaboratorRole isMobile={isMobile}>
                        {userAndRole.role}
                      </TripShareCollaboratorRole>
                    ) : (
                      <TripShareRoleButton
                        id={userAndRole.userId}
                        isMobile={isMobile}
                        onClick={handleRoleClick}
                        endIcon={<KeyboardArrowDown />}
                      >
                        {userAndRole.role}
                      </TripShareRoleButton>
                    )}
                  </TripShareModalRowContainer>
                ))}
                {(hasPendingChanges || isSaving) && (
                  <TripShareModalRowGroupEnd>
                    {isSaving ? (
                      <TripSaveDoneButton variant="outlined">
                        <CircularProgress size={24} />
                      </TripSaveDoneButton>
                    ) : (
                      <TripSaveDoneButton variant="contained" onClick={handleSaveClick}>
                        Save
                      </TripSaveDoneButton>
                    )}
                  </TripShareModalRowGroupEnd>
                )}
              </TripShareModalSubcontainer>
            <TripShareModalSubcontainer>
              <TripShareModalRowContainer>
                <TripShareHeader>Trip Access</TripShareHeader>
                <TripShareModalSwitchContainer>
                  <FormControlLabel
                    value="public"
                    control={
                      <Switch color="success" checked={isPublic} onChange={handlePublicChange} />
                    }
                    label={
                      <TripShareSwitchLabel isPublic={isPublic} canEdit={canEdit}>
                        {isPublic ? 'PUBLIC' : 'PRIVATE'}
                      </TripShareSwitchLabel>
                    }
                    labelPlacement="start"
                    disabled={!canEdit}
                  />
                </TripShareModalSwitchContainer>
              </TripShareModalRowContainer>
              <TripShareModalRowContainer>
                <TripShareLinkSettingsSpan isMobile={isMobile}>
                  {isPublic
                    ? 'Anyone has access to view this trip'
                    : 'Only invited collaborators have access to this trip'}
                </TripShareLinkSettingsSpan>
                <TripCopyLinkButton variant="text" onClick={handleCopyClick}>
                  {isCopied ? 'Copied!' : 'Copy Link'}
                </TripCopyLinkButton>
              </TripShareModalRowContainer>
            </TripShareModalSubcontainer>
          </TripShareModalContainer>
        </Modal>
      )}

      {roleMenuOpen && (
        <TripRoleMenu anchorEl={roleMenuAnchorEl} open={roleMenuOpen} onClose={handleRoleMenuClose}>
          {TripRolesAssignable.map((role) => (
            <MenuItem
              key={role}
              selected={roleMenuAnchorEl !== null && role === getRoleForUser(roleMenuAnchorEl.id)}
              onClick={(event) => handleRoleMenuItemClick(event, role)}
            >
              {roleMenuAnchorEl && role === getRoleForUser(roleMenuAnchorEl.id) && (
                <ListItemIcon>
                  <CheckRounded />
                </ListItemIcon>
              )}
              <TripShareRoleListItemText
                inset={roleMenuAnchorEl !== null && role !== getRoleForUser(roleMenuAnchorEl.id)}
              >
                {role}
              </TripShareRoleListItemText>
            </MenuItem>
          ))}
          <Divider />
          <MenuItem key="remove" onClick={handleRemoveUserClick}>
            <TripShareRoleListItemText>Remove</TripShareRoleListItemText>
          </MenuItem>
        </TripRoleMenu>
      )}

      {snackbarOpen && snackbarMessage && <Toast message={snackbarMessage} setSnackbarMessage={setSnackbarMessage} />}
    </>
  );
}

TripShare.displayName = 'TripShare';
export default TripShare;
