import React, {
  Fragment,
  KeyboardEvent,
  FocusEvent,
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useState,
  useEffect,
} from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Modal, Tab } from '@mui/material';
import { TabContext } from '@mui/lab';
import { debounce, isEqual } from 'lodash';
import { Descendant } from 'slate';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../../redux/hooks';
import { usePlaceImgUrlsForCard } from '../../../hooks/trip/trip.hooks';

import { formatPhoneNumberForLink, getOpeningHours } from '../../card/card.utils';
import { updateCard, updateCardNotes } from '../../../redux/trip/trip.slice';
import { SnackbarMessage, TripCard } from '../../../redux/trip/trip.types';

import RichTextEditor from '../../rich-text-editor/rich-text-editor.component';
import CardDetailsGallery from '../card-image-gallery/card-image-gallery.component';
import CardDetailsOptionsMenu from '../card-options-menu/card-options-menu.component';
import Toast from '../../snackbar/snackbar.component';

import {
  CardDetailsModalContainer,
  CardDetailsButtonsContainer,
  BackToPlaygroundButton,
  OptionsMenuDiv,
  OptionsMenuButton,
  ModalImagesContainer,
  CoverImage,
  OpenGalleryButton,
  CardTitle,
  SubheaderContainer,
  PriceLevelText,
  BoldDollar,
  LightDollar,
  LocationTypeText,
  RatingsText,
  GoogleReviewsLinkContainer,
  GoogleReviewsAnchor,
  GoogleReviewsText,
  GoogleReviewsLaunchIcon,
  InfoSpan,
  CardDetailsTabList,
  CardDetailsTabPanel,
  TextEditorContainer,
  InfoContainer,
  ContactInfoContainer,
  HoursInfoContainer,
  HoursInfoSubcontainer,
  TimeInfoContainer,
  InfoHeading,
  StyledAnchor,
  InfoGridSpan,
  StaticMapContainer,
  StaticMap,
} from './card-details-modal-mobile.styles';

type CardDetailsModalProps = {
  card: TripCard,
  tripId: string,
  canEdit?: boolean,
  open?: boolean,
  handleClose?: () => void,
  handleDeleteClick?: (event: MouseEvent<EventTarget>) => void,
};

function CardDetailsModalMobile({
  card,
  tripId,
  canEdit = false,
  open = false,
  handleClose,
  handleDeleteClick,
}: CardDetailsModalProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { notes } = card;
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [tabValue, setTabValue] = useState(notes ? '1' : '2');
  const [showToolbar, setShowToolbar] = useState(false);
  const [galleryOpen, setGalleryOpen] = useState(searchParams.has('photos'));
  const [isEditCoverPhoto, setIsEditCoverPhoto] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage | null>(null);
  const snackbarOpen = Boolean(snackbarMessage);
  const imgUrls = card.imgUrls ?? [];

  usePlaceImgUrlsForCard(tripId, card);

  const handleOptionsOpen = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleGalleryOpen = () => {
    searchParams.set('photos', 'true');
    setSearchParams(searchParams);
  };
  const handleGalleryClose = () => {
    searchParams.delete('photos');
    setSearchParams(searchParams);
    setIsEditCoverPhoto(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSaveNotes = useCallback(
    debounce((newNotes) => {
      if (!isEqual(newNotes, card.notes)) {
        dispatch(
          updateCardNotes({
            tripId,
            cardId: card.id,
            notes: newNotes,
          }),
        );
      }
    }, 500),
    [dispatch, card, tripId],
  );

  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    searchParams.set('card-tab', newValue);
    setSearchParams(searchParams);
  };

  // Handle url navigation
  useEffect(() => {
    setTabValue(searchParams.get('card-tab') || tabValue);
    setGalleryOpen(searchParams.has('photos'));
  }, [searchParams, tabValue]);

  const handleEditorClick = () => {
    if (canEdit) {
      setShowToolbar(true);
    }
  };
  const handleEditorBlur = () => setShowToolbar(false);

  const makeNameEditable = (event: MouseEvent<HTMLHeadingElement>) => {
    const cardNameHeading = event.target as HTMLHeadingElement;
    cardNameHeading.setAttribute('contentEditable', 'true');
    cardNameHeading.focus();
  };

  const submitNameChanges = (
    event: FocusEvent<HTMLHeadingElement> | KeyboardEvent<HTMLHeadingElement>,
  ) => {
    const cardNameHeading = event.target as HTMLHeadingElement;
    cardNameHeading.setAttribute('contentEditable', 'false');
    const text = cardNameHeading.innerText;

    // Early return if text is not changed
    if (text === (card.customName ?? card.name ?? '')) return;

    if (text.length === 0 || !text.trim()) {
      cardNameHeading.innerText = card.name ?? '';
      if (card.customName) {
        dispatch(updateCard({ tripId, cardId: card.id, field: 'customName', value: null }));
      }
      return;
    }

    dispatch(updateCard({ tripId, cardId: card.id, field: 'customName', value: text }));
    setSnackbarMessage({
      severity: 'success',
      text: 'Card name updated!',
    });
  };

  const submitCoverImageChange = (newCoverImage: number) => {
    dispatch(updateCard({ tripId, cardId: card.id, field: 'defaultImage', value: newCoverImage }));
    handleGalleryClose();
    setSnackbarMessage({
      severity: 'success',
      text: 'Cover photo updated!',
    });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLHeadingElement>) => {
    const maxCharacterCount = 200;
    const cardNameHeading = event.target as HTMLHeadingElement;
    const text = cardNameHeading.innerText;

    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent creating a new line
      submitNameChanges(event);
    }

    if (
      text.length >= maxCharacterCount &&
      event.key !== 'Backspace' &&
      event.key !== 'ArrowLeft' &&
      event.key !== 'ArrowRight'
    ) {
      event.preventDefault(); // Prevent further input
    }
  };

  const mapId = '5f2483670fd46e10';
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${
    card?.location?.latitude
  },${
    card?.location?.longitude
  }&zoom=16&size=350x350&scale=4&maptype=roadmap&markers=size:mid%7Ccolor:red%7Clabel:o%7C${
    card?.location?.latitude
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  },${card?.location?.longitude}&map_id=${mapId}&key=${process.env.REACT_APP_GOOGLE_API_KEY!}`;

  const currentDay = new Date().toLocaleDateString('en-US', { weekday: 'short' });

  function formatType(type: string) {
    return type
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  const cardType = card.types ? formatType(card.types[0]) : '';
  function getPriceLevel(priceLevel: number) {
    const totalDollars = 4;
    const dollars = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < totalDollars; i++) {
      dollars.push(
        i < priceLevel ? <BoldDollar key={i}>$</BoldDollar> : <LightDollar key={i}>$</LightDollar>,
      );
    }
    return dollars;
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <CardDetailsModalContainer>
        <CardDetailsButtonsContainer>
          <BackToPlaygroundButton startIcon={<ArrowBackIcon />} onClick={handleClose}>
            Back to {searchParams.get('tab') || 'playground'}
          </BackToPlaygroundButton>

          {/* OPTIONS MENU FOR CARD DETAILS  */}
          {canEdit && (
            <OptionsMenuDiv onClick={handleOptionsOpen}>
              <OptionsMenuButton />
            </OptionsMenuDiv>
          )}
          <CardDetailsOptionsMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            handleGalleryOpen={handleGalleryOpen}
            handleDeleteClick={handleDeleteClick}
            setIsEditCoverPhoto={setIsEditCoverPhoto}
          />
        </CardDetailsButtonsContainer>

        {imgUrls && imgUrls.length > 0 && (
          <ModalImagesContainer>
            <CoverImage src={imgUrls[card.defaultImage || 0]} />
            {imgUrls.length > 1 && (
              <OpenGalleryButton onClick={handleGalleryOpen}>See all photos</OpenGalleryButton>
            )}
          </ModalImagesContainer>
        )}
        {galleryOpen && (
          <CardDetailsGallery
            imgUrls={imgUrls}
            isEdit={isEditCoverPhoto}
            galleryOpen={galleryOpen}
            handleGalleryClose={handleGalleryClose}
            originalSelectedImgIndex={card.defaultImage}
            submitCoverImageChange={submitCoverImageChange}
          />
        )}

        <CardTitle
          onClick={canEdit ? makeNameEditable : undefined}
          onBlur={canEdit ? submitNameChanges : undefined}
          onKeyDown={canEdit ? handleKeyDown : undefined}
        >
          {card.customName ?? card.name}
        </CardTitle>
        <SubheaderContainer>
          {card.priceLevel && <PriceLevelText> {getPriceLevel(card.priceLevel)}</PriceLevelText>}
          {card.types && <LocationTypeText>{cardType}</LocationTypeText>}
          {card.rating && <RatingsText>{card.rating}</RatingsText>}
          {card.rating && card.url && (
            <GoogleReviewsLinkContainer>
              <GoogleReviewsAnchor href={card.url} target="_blank">
                <GoogleReviewsText>Google Reviews</GoogleReviewsText>
                <GoogleReviewsLaunchIcon />
              </GoogleReviewsAnchor>
            </GoogleReviewsLinkContainer>
          )}
        </SubheaderContainer>

        <TabContext value={tabValue}>
          <CardDetailsTabList onChange={handleTabChange}>
            {(notes || canEdit) && <Tab label="Notes" value="1" />}
            <Tab label="Info" value="2" />
          </CardDetailsTabList>

          {(notes || canEdit) && (
            <CardDetailsTabPanel value="1" onClick={handleEditorClick} onBlur={handleEditorBlur}>
              <TextEditorContainer toolbar={showToolbar}>
                <RichTextEditor
                  initialValue={notes}
                  onChange={(newNotes: Descendant[]) => {
                    debouncedSaveNotes(newNotes);
                  }}
                  toolbar={showToolbar}
                  placeholder={canEdit ? 'Add notes/tips for this place...' : undefined}
                  readOnly={!canEdit}
                />
              </TextEditorContainer>
            </CardDetailsTabPanel>
          )}

          <CardDetailsTabPanel value="2">
            <InfoContainer>
              <ContactInfoContainer>
                {card.formattedPhoneNumber && (
                  <div>
                    <InfoHeading>Phone</InfoHeading>
                    <InfoSpan>
                      {card.internationalPhoneNumber ? (
                        <StyledAnchor
                          href={`tel:${formatPhoneNumberForLink(card.internationalPhoneNumber)}`}
                        >
                          {card.internationalPhoneNumber}
                        </StyledAnchor>
                      ) : (
                        card.formattedPhoneNumber
                      )}
                    </InfoSpan>
                  </div>
                )}
              </ContactInfoContainer>
              {card.openingHours && (
                <HoursInfoContainer>
                  <InfoHeading>Hours</InfoHeading>
                  <HoursInfoSubcontainer>
                    {getOpeningHours(card.openingHours).map((openingHours, dayIndex) => {
                      const dayOfWeek = Object.keys(openingHours)[0];
                      const isToday = dayOfWeek === currentDay;

                      return (
                        <Fragment key={`${card.id}-${dayOfWeek}`}>
                          <InfoGridSpan
                            row={dayIndex + 1}
                            style={{ fontWeight: isToday ? '600' : 'normal' }}
                          >
                            {dayOfWeek}:
                          </InfoGridSpan>
                          <TimeInfoContainer row={dayIndex + 1}>
                            {openingHours[dayOfWeek].map((hours, index) => (
                              <InfoSpan
                                // eslint-disable-next-line react/no-array-index-key
                                key={`${card.id}-hours-${dayOfWeek}-${index}`}
                                style={{ fontWeight: isToday ? '600' : 'normal' }}
                              >
                                {hours}
                              </InfoSpan>
                            ))}
                          </TimeInfoContainer>
                        </Fragment>
                      );
                    })}
                  </HoursInfoSubcontainer>
                </HoursInfoContainer>
              )}

              <InfoSpan>
                {card.website && (
                  <div>
                    <InfoHeading>Website</InfoHeading>
                    <StyledAnchor href={card.website} target="_blank" rel="noreferrer">
                      {card.website}
                    </StyledAnchor>
                  </div>
                )}
              </InfoSpan>

              {card.formattedAddress && (
                <div>
                  <InfoHeading>Address</InfoHeading>
                  <InfoSpan>
                    {card.url ? (
                      <StyledAnchor href={card.url} target="_blank" rel="noreferrer">
                        {card.formattedAddress}
                      </StyledAnchor>
                    ) : (
                      card.formattedAddress
                    )}
                  </InfoSpan>
                  <StaticMapContainer>
                    <a href={card.url} target="_blank" rel="noreferrer">
                      <StaticMap src={mapUrl} />
                    </a>
                  </StaticMapContainer>
                </div>
              )}
            </InfoContainer>
          </CardDetailsTabPanel>
        </TabContext>
        {snackbarOpen && snackbarMessage && (
          <Toast message={snackbarMessage} setSnackbarMessage={setSnackbarMessage} />
        )}
      </CardDetailsModalContainer>
    </Modal>
  );
}

CardDetailsModalMobile.displayName = 'CardDetailsModalMobile';
export default CardDetailsModalMobile;
