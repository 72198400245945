import React, { MouseEventHandler, useState } from 'react';
import useMobileMediaQuery from '../../../utils/media-query.utils';

import {
  MarkerDescriptionContainer,
  MarkerEmojiContainer,
  MarkerEmojiText,
  MarkerEmojiPin,
  MarkerEmojiPinHighlighted,
} from './marker-emoji.styles';

type MarkerEmojiProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  lat: number,
  // eslint-disable-next-line react/no-unused-prop-types
  lng: number,
  data: {
    emoji: string,
    description: string,
    isHighlighted: boolean | undefined,
  },
  onClick?: MouseEventHandler<HTMLDivElement>,
};

function MarkerEmoji({
  data: { emoji, description, isHighlighted = false },
  onClick,
}: MarkerEmojiProps) {
  const isMobile = useMobileMediaQuery();
  const [showDescription, setShowDescription] = useState(false);

  const handleMouseEnter = () => setShowDescription(true);
  const handleMouseLeave = () => setShowDescription(false);

  return (
    <>
      <MarkerEmojiContainer
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
        bringToFront={showDescription || isHighlighted}
      >
        {isHighlighted ? <MarkerEmojiPinHighlighted /> : <MarkerEmojiPin />}
        <MarkerEmojiText isMobile={isMobile}>{emoji}</MarkerEmojiText>
      </MarkerEmojiContainer>
      {(showDescription || isHighlighted) && (
        <MarkerDescriptionContainer>
          <span>
            {description.length > 37 ? `${description.substring(0, 37)}...` : description}
          </span>{' '}
        </MarkerDescriptionContainer>
      )}
    </>
  );
}

MarkerEmoji.displayName = 'MarkerEmoji';
export default MarkerEmoji;
