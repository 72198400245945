import styled from 'styled-components';

export const ChecklistItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & + & {
    margin-top: 0;
  }
`;

export const ChecklistItemInputSpan = styled.span`
  margin-right: 0.75em;
`;

type CheckListItemLabelSpanProps = {
  checked?: boolean,
};
export const ChecklistItemLabelSpan = styled.span<CheckListItemLabelSpanProps>`
  flex: 1;
  opacity: ${(props) => (props.checked ? 0.666 : 1)};
  text-decoration: ${(props) => (!props.checked ? 'none' : 'line-through')};
  &:focus {
    outline: none;
  }
`;
