import React, { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { useAppDispatch } from '../../redux/hooks';

import { saveCardOrderForActiveList, tempUpdateCardOrder } from '../../redux/trip/trip.slice';
import Card, { CardProps } from '../card/card.component';

import { ItemType } from '../dnd/dnd.types';

interface SortableCardProps extends CardProps {
  originalCardOrder?: string[],
}

interface CardDragItem {
  type: string,
  id: string,
  listId: string,
  index: number,
}

function SortableCard({
  card,
  listId,
  tripId,
  cardOrder,
  originalCardOrder,
  isLarge = false,
  canEdit = false,
  canDelete = false,
  onCardMouseEnter,
  onCardMouseLeave,
}: SortableCardProps) {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const index = cardOrder?.indexOf(card.id);

  const [, dropRef] = useDrop<CardDragItem, void, void>({
    accept: ItemType.CARD,
    hover: (item: CardDragItem) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      dispatch(tempUpdateCardOrder({ dragIndex, hoverIndex }));

      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
    drop: () => {
      dispatch(saveCardOrderForActiveList({ tripId, listId, cardOrder, originalCardOrder }));
    },
  });

  dropRef(ref);

  return (
    <Card
      ref={ref}
      card={card}
      listId={listId}
      tripId={tripId}
      cardOrder={cardOrder}
      isLarge={isLarge}
      canEdit={canEdit}
      canDelete={canDelete}
      onCardMouseEnter={onCardMouseEnter}
      onCardMouseLeave={onCardMouseLeave}
    />
  );
}

SortableCard.displayName = 'SortableCard';
export default SortableCard;
