import {
  doc,
  collection,
  getDoc,
  getDocs,
  writeBatch,
  DocumentReference,
} from 'firebase/firestore';
import { db } from '../firebase.utils';
import { ECollections } from '../firebase.types';
import { IChangeDefaultDatesServicePayload, Trip } from '../../redux/trip/trip.types';

export const changeDefaultDatesService = async ({
  tripId,
  enumDays,
}: IChangeDefaultDatesServicePayload): Promise<void> => {
  const batch = writeBatch(db);
  const tripRef = doc(db, ECollections.trips, tripId);
  const daysRef = collection(db, ECollections.trips, tripId, ECollections.days);
  const eventsRef = collection(db, ECollections.trips, tripId, ECollections.events);

  const tripDoc = await getDoc<Trip>(tripRef as DocumentReference<Trip>);
  const tripDocData = tripDoc.data();
  const dayCollection = await getDocs(daysRef);
  const eventCollection = await getDocs(eventsRef);
  dayCollection.forEach((day) => {
    const dayDoc = day.data();
    batch.update(doc(db, ECollections.trips, tripId, ECollections.days, day.id), { dateISO: enumDays[dayDoc.dateISO] });
  });
  eventCollection.forEach((event) => {
    const eventDoc = event.data();
    batch.update(doc(db, ECollections.trips, tripId, ECollections.events, event.id), { startDateISO: enumDays[eventDoc.startDateISO] });
  });
  if (tripDocData) {
    batch.update(tripRef, {
      endDateISO: enumDays[tripDocData.endDateISO as string],
      startDateISO: enumDays[tripDocData.startDateISO as string],
    });
    await batch.commit();
  }
};

export default { changeDefaultDatesService };
