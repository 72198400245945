import styled from 'styled-components';
import { TabList, TabPanel } from '@mui/lab';

type MobileProp = {
  isMobile: boolean,
};

// eslint-disable-next-line import/prefer-default-export
export const SignInAndSignUpContainer = styled.div<MobileProp>`
  display: flex;
  width: ${(props) => (props.isMobile ? '85vw' : '375px')};
  margin: ${(props) => (props.isMobile ? '0' : '6em')} auto;
`;

export const SignInAndSignUpMainContainer = styled.div`
  width: 100%;
`;

export const SignInAndSignUpTitle = styled.h2`
  font-size: 24px;
  text-align: center;
  margin: 10px 0;
`;

export const SignInTabList = styled(TabList)`
  & .MuiButtonBase-root {
    text-transform: none;
  }

  & .MuiTabs-flexContainer {
    justify-content: center;
  }

  & .Mui-selected {
    font-weight: 700;
    letter-spacing: 0.08rem;
  }

  & .Mui-disabled {
    letter-spacing: 0.08rem;
  }

  & .MuiTabs-indicator {
    background-color: #1e959e;
    height: 3px;
  }
`;

export const SignInTabPanel = styled(TabPanel)`
  padding: 0px;
`;
