import React, { useRef } from 'react';
import { XYCoord, useDrop } from 'react-dnd';
import { useAppDispatch } from '../../../../redux/hooks';
import { DayID, TripCard, TripEvent } from '../../../../redux/trip/trip.types';
import { updateEventOrderForDay } from '../../../../redux/trip/trip.slice';
import { ItemType } from '../../../dnd/dnd.types';
import ItineraryEvent, { ItineraryEventProps } from './itinerary-event.component';

interface SortableItineraryEventProps extends ItineraryEventProps {
  dayId?: DayID,
}

interface ItineraryEventDragItem {
  type: string,
  event: TripEvent,
  card: TripCard,
  index: number,
}

function SortableItineraryEvent({
  tripId,
  event,
  card,
  dayId,
  emoji,
  color,
  eventOrder,
  canEdit,
  eventLength,
  isMultidayEvent,
}: SortableItineraryEventProps) {
  const dispatch = useAppDispatch();
  const index = eventOrder && event.id ? eventOrder.indexOf(event.id) : undefined;
  const ref = useRef<HTMLDivElement>(null);

  const [, dropRef] = useDrop({
    accept: ItemType.EVENT,
    hover: (item: ItineraryEventDragItem, monitor) => {
      if (!ref.current || !dayId || !eventOrder || index === undefined || index === -1) {
        return;
      }
      // Don't allow multiday events to trigger reordering
      if (item.event.eventLength) {
        return;
      }

      // TODO: Handle dragging to a different day
      if (item.event.startDateISO !== event.startDateISO) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (item.event.startDateISO === event.startDateISO && dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      dispatch(
        updateEventOrderForDay({
          tripId,
          dayId,
          dateISO: item.event.startDateISO,
          eventOrder,
          dragIndex,
          hoverIndex,
        })
      );

      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  dropRef(ref);

  return (
    <ItineraryEvent
      ref={ref}
      tripId={tripId}
      event={event}
      card={card}
      emoji={emoji}
      color={color}
      eventOrder={eventOrder}
      eventLength={eventLength}
      canEdit={canEdit}
      isMultidayEvent={isMultidayEvent}
    />
  );
}

SortableItineraryEvent.displayName = 'SortableItineraryEvent';
export default SortableItineraryEvent;
