import styled from 'styled-components';
import { EItineraryEvents } from './itinerary-events.types';

interface ItineraryEventsContainerProps {
  col: number,
  gap: EItineraryEvents.gridGap,
  containerPadding: EItineraryEvents.containerPadding,
}

// eslint-disable-next-line import/prefer-default-export
export const ItineraryEventsContainer = styled.div`
  grid-row: -2 / -1;
  // grid-row: 4 / -1;
  grid-column: ${(props: ItineraryEventsContainerProps) => props.col} / span 1;

  display: flex;
  flex-flow: column nowrap;
  gap: ${(props: ItineraryEventsContainerProps) => props.gap}px;
  padding: ${(props: ItineraryEventsContainerProps) => props.containerPadding}px;
  margin-bottom: 12px;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`;

export const ItineraryEventsDialogHoursContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 12px max-content;
  grid-template-rows: repeat(auto-fit, minmax(20px, 1fr));
  justify-content: center;
  padding: 24px 0px;
`;

type ItineraryEventsDialogDayContainerProps = {
  row: number,
};

export const ItineraryEventsDialogDayContainer = styled.span<ItineraryEventsDialogDayContainerProps>`
  grid-column: 1;
  grid-row: ${(props) => props.row};
`;

type ItineraryEventsDialogTimeContainerProps = {
  row: number,
};

export const ItineraryEventsDialogTimeContainer = styled.div<ItineraryEventsDialogTimeContainerProps>`
  grid-column: 3;
  grid-row: ${(props) => props.row};
  display: flex;
  flex-flow: column nowrap;
`;
