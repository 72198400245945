import React, { createContext, FC, useCallback, useMemo, useState } from 'react';

const addLocality = (localities: Set<string>, localityToAdd: string) =>
  new Set(localities).add(localityToAdd);

const removeLocality = (localities: Set<string>, localityToRemove: string) => {
  const newLocalities = new Set(localities);
  newLocalities.delete(localityToRemove);
  return newLocalities;
};

interface TripContextType {
  selectedLocalities?: Set<string>,
  addLocalityToFilter?: (locality: string) => void,
  removeLocalityFromFilter?: (locality: string) => void,
  clearLocalityFilter?: () => void,
}

export const TripContext = createContext<TripContextType>({});

// eslint-disable-next-line react/function-component-definition
export const TripProvider: FC = ({ children }) => {
  const [selectedLocalities, setSelectedLocalities] = useState<Set<string> | undefined>(undefined);

  const addLocalityToFilter = useCallback(
    (locality: string) => {
      if (selectedLocalities) {
        setSelectedLocalities(addLocality(selectedLocalities, locality));
      } else {
        setSelectedLocalities(new Set([locality]));
      }
    },
    [selectedLocalities]
  );

  const removeLocalityFromFilter = useCallback(
    (locality: string) => {
      if (selectedLocalities) {
        setSelectedLocalities(removeLocality(selectedLocalities, locality));
      }
    },
    [selectedLocalities]
  );

  const clearLocalityFilter = () => {
    setSelectedLocalities(undefined);
  };

  const value = useMemo(
    () => ({
      selectedLocalities,
      addLocalityToFilter,
      removeLocalityFromFilter,
      clearLocalityFilter,
    }),
    [addLocalityToFilter, removeLocalityFromFilter, selectedLocalities]
  );

  return <TripContext.Provider value={value}>{children}</TripContext.Provider>;
};

TripProvider.displayName = 'TripProvider';