import styled from 'styled-components';

function textColorBasedOnBgColor(bgColor: string, lightColor: string, darkColor: string) {
  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  const uicolors = [r / 255, g / 255, b / 255];
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return ((col + 0.055) / 1.055) ** 2.4;
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179 ? darkColor : lightColor;
}

interface ItineraryEventContainerProps {
  color: string,
  isConfirmed: boolean,
  isDragging: boolean,
}

export const ItineraryEventContainer = styled.div<ItineraryEventContainerProps>`
  position: relative;
  width: auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 8px;
  background: ${(props) => props.color};
  border-radius: 5px;

  ${(props) => (props.isConfirmed ? 'cursor: pointer' : '')};
  opacity: ${
    // eslint-disable-next-line no-nested-ternary
    (props) => (props.isDragging ? 0 : props.isConfirmed ? 1 : 0.6)
  };
`;

export const ItineraryEventContainerResizer = styled('div')({
  position: 'absolute',
  top: 0,
  right: '-3px',
  width: '6px',
  height: '100%',
  '&:hover': { cursor: 'e-resize' },
});

export const ItineraryEventIcon = styled.span`
  padding: 0px 8px;
`;

interface ItineraryEventTitleProps {
  backgroundColor: string,
}

export const ItineraryEventTitle = styled.span<ItineraryEventTitleProps>`
  color: ${(props) => textColorBasedOnBgColor(props.backgroundColor, '#ffffff', '#000000')};
  font-size: 0.875em;
`;
