import React from 'react';
import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

CustomTooltip.displayName = 'CustomTooltip';
export default CustomTooltip;
