/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React, { useCallback, useMemo } from 'react';
import isHotkey from 'is-hotkey';
import { Slate, useSlate, withReact } from 'slate-react';
import { Editor, Transforms, createEditor, Element as SlateElement } from 'slate';
import { withHistory } from 'slate-history';
import { emptyTemplate } from '../../redux/trip/trip.slice';
import useMobileMediaQuery from '../../utils/media-query.utils';

import {
  StyledEditable,
  StyledLink,
  EditorButton,
  EditorIcon,
  EditorToolbar,
} from './rich-text-editor.styles';

import findUrlsInText from './rich-text-editor.utils'; /** detects URLs in text editor as you type */
import ChecklistItemElement from './checklist/checklist.component';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const CHECKLIST_TYPE = 'checklist';
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
    })
  );

  return !!match;
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);
  const isChecklist = format === CHECKLIST_TYPE;

  const BLOCK_TYPES = [...LIST_TYPES, CHECKLIST_TYPE];

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      BLOCK_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });

  let newProperties = {};

  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = { align: isActive ? undefined : format };
  } else {
    switch (true) {
      case isActive:
        newProperties = { type: 'paragraph' };
        break;
      case isList:
        newProperties = { type: 'list-item' };
        break;
      case isChecklist:
        newProperties = { type: 'checklist-item' };
        break;
      default:
        newProperties = { type: format };
        break;
    }
  }

  Transforms.setNodes(editor, newProperties);

  if (!isActive && (isList || isChecklist)) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks && marks[format] === true;
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const ELEMENT_TYPES = {
  'block-quote': 'blockquote',
  'bulleted-list': 'ul',
  'heading-one': 'h1',
  'heading-two': 'h2',
  'list-item': 'li',
  'numbered-list': 'ol',
  'checklist': 'div',
  'checklist-item': ChecklistItemElement,
};
const Element = ({ attributes, children, element, readOnly }) => {
  const style = { textAlign: element.align, lineHeight: 'normal' };
  const Component = ELEMENT_TYPES[element.type] || 'p';

  return (
    <Component style={style} {...attributes} element={element} readOnly={readOnly}>
      {children}
    </Component>
  );
};

const LinkLeaf = ({ attributes, children, leaf }) => {
  const handleClick = () => {
    const url = leaf.text;
    const hasProtocol = /^https?:\/\//i.test(url);
    // add the http:// protocol to any links that do not have a protocol before opening them in a new window. Fixes issue of browser treating url's as relative links.
    // In case user enters for example: 'www.google.com' instead of 'http://www.google.com'
    if (hasProtocol) {
      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      window.open(`http://${url}`, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <StyledLink {...attributes} href={leaf.text} onClick={handleClick}>
      {children}
    </StyledLink>
  );
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.strikethrough) {
    children = <s>{children}</s>;
  }

  if (leaf.decoration === 'link') {
    children = <LinkLeaf leaf={leaf}>{children}</LinkLeaf>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  const onMouseDown = useCallback(
    (event) => {
      event.preventDefault();
      toggleBlock(editor, format);
    },
    [editor, format]
  );

  return (
    <EditorButton
      active={isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')}
      onMouseDown={onMouseDown}
    >
      <EditorIcon className="material-icons">{icon}</EditorIcon>
    </EditorButton>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  const onMouseDown = useCallback(
    (event) => {
      event.preventDefault();
      toggleMark(editor, format);
    },
    [editor, format]
  );

  return (
    <EditorButton active={isMarkActive(editor, format)} onMouseDown={onMouseDown}>
      <EditorIcon className="material-icons">{icon}</EditorIcon>
    </EditorButton>
  );
};

/** Decorator for Link
 *
 * The decorator function accepts a NodeEntry object, which is a tuple that contains node and path objects. We will use both to find any links in the node’s text and attach their location as metadata to the node.

  findUrlsInText is a custom function that uses regex to find the indexes of all of the links in the text

  Now every time the content of the editor changes, our decorator function will run. Whenever it finds a link, it will return it’s location as a metadata, which we can use when rendering the node.
 *
*/
const LinkDecorator = ([node, path]) => {
  const nodeText = node.text;

  if (!nodeText) return [];

  return findUrlsInText(nodeText).flatMap(([url, index]) => ({
    anchor: {
      path,
      offset: index,
    },
    focus: {
      path,
      offset: index + url.length,
    },
    decoration: 'link',
  }));
};

const RichTextEditor = ({ initialValue, onChange, toolbar, placeholder, readOnly }) => {
  const isMobile = useMobileMediaQuery();

  const renderElement = useCallback(
    (props) => <Element {...props} readOnly={readOnly} />,
    [readOnly]
  );
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const editor = useMemo(() => withReact(withHistory(createEditor())), []);

  const handleOnChange = useCallback(
    (value) => {
      const isAstChange = editor.operations.some((op) => op.type !== 'set_selection');
      if (isAstChange) {
        onChange(value);
      }
    },
    [editor.operations, onChange]
  );

  const onKeyDown = useCallback(
    (event) => {
      for (const hotkey in HOTKEYS) {
        if (isHotkey(hotkey, event)) {
          event.preventDefault();
          const mark = HOTKEYS[hotkey];
          toggleMark(editor, mark);
        }
      }
    },
    [editor]
  );

  return (
    <Slate editor={editor} value={initialValue || emptyTemplate} onChange={handleOnChange}>
      {toolbar && (
        <EditorToolbar isMobile={isMobile}>
          <MarkButton format="bold" icon="format_bold" />
          <MarkButton format="italic" icon="format_italic" />
          <MarkButton format="underline" icon="format_underlined" />
          <MarkButton format="strikethrough" icon="format_strikethrough" />
          <BlockButton format="heading-one" icon="looks_one" />
          <BlockButton format="heading-two" icon="looks_two" />
          <BlockButton format="block-quote" icon="format_quote" />
          <BlockButton format="numbered-list" icon="format_list_numbered" />
          <BlockButton format="bulleted-list" icon="format_list_bulleted" />
          <BlockButton format="checklist" icon="check_box" />
          <BlockButton format="left" icon="format_align_left" />
          <BlockButton format="center" icon="format_align_center" />
          <BlockButton format="right" icon="format_align_right" />
          <BlockButton format="justify" icon="format_align_justify" />
        </EditorToolbar>
      )}
      <StyledEditable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        decorate={LinkDecorator} // when content in editor changes, decorator will run to check for links
        placeholder={placeholder}
        spellCheck
        readOnly={readOnly}
        onKeyDown={onKeyDown}
        isMobile={isMobile}
      />
    </Slate>
  );
};

Element.displayName = 'Element';
LinkLeaf.displayName = 'LinkLeaf';
Leaf.displayName = 'Leaf';
BlockButton.displayName = 'BlockButton';
MarkButton.displayName = 'MarkButton';
RichTextEditor.displayName = 'RichTextEditor';
export default RichTextEditor;
