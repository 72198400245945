import React from 'react';
import useMobileMediaQuery from '../../utils/media-query.utils';
import CustomTooltip from '../tooltip/tooltip.component';
import LetterAvatar from './letter-avatar.component';

function LetterAvatarGroup({ userRoles }: any) {
  const isMobile = useMobileMediaQuery();
  const isTripsList = window.location.pathname === '/trips';
  const overlapAmount = isMobile ? '-3px' : '-10px';
  const visibleLetterAvatars = isMobile && isTripsList ? 4 : 5;
  const remainingRolesCount = Math.max(0, userRoles.length - visibleLetterAvatars);

  return (
    <div style={{ display: 'flex', marginRight: '10px' }}>
      {userRoles?.length > 1 &&
        userRoles.slice(0, visibleLetterAvatars).map((email: string, index: number) => (
          <div key={email} style={{ zIndex: index, marginLeft: overlapAmount }}>
            <CustomTooltip title={email}>
              <div>
                <LetterAvatar string={email} capitalize />
              </div>
            </CustomTooltip>
          </div>
        ))}
      {remainingRolesCount > 0 && (
        <div style={{ zIndex: 999, marginLeft: overlapAmount }}>
          <LetterAvatar string="" remainingRolesCount={remainingRolesCount} />
        </div>
      )}
    </div>
  );
}

LetterAvatarGroup.displayName = 'LetterAvatarGroup';
export default LetterAvatarGroup;
