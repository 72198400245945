import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { eachDayOfInterval, format, parseISO, addDays, getYear } from 'date-fns';

import { useDrop } from 'react-dnd';
import { useSnackbar } from 'notistack';
import {
  CardID,
  DateISO,
  EventID,
  Trip,
  TripCard,
  TripDay,
  TripEvent,
  TripList,
} from '../../../redux/trip/trip.types';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  addEventToDate,
  clearDeletedEvents,
  deleteEventFromDate,
  moveEventToDate,
} from '../../../redux/trip/trip.slice';
import { getISODate } from '../../../redux/utils';
import { ItemType } from '../../dnd/dnd.types';
import { selectCurrentUser } from '../../../redux/user/user.selectors';
import { canEditTrip } from '../../../redux/trip/trip.utils';
import { getEmojiAndColorForCard } from '../../../utils/itinerary.utils';
import { SnackbarDismissButton } from '../../snackbar/snackbar.styles';
import { selectCards, selectDeletedEvents } from '../../../redux/trip/trip.selectors';
import { defaultStartDate } from '../constants';
import useMobileMediaQuery from '../../../utils/media-query.utils';
import ItineraryEvent from './itinerary-event/itinerary-event.component';
import ItineraryEvents from './itinerary-events/itinerary-events.component';
import ItinerarySidebar from './itinerary-sidebar/itinerary-sidebar.component';
import ItinerarySidebarDefault from './itinerary-sidebar/itinerary-sidebar-default/itinerary-sidebar-default.component';

import {
  ItineraryCalendarContainer,
  ItineraryCalendarDayTitle,
  ItineraryContainer,
  ItineraryMainContainer,
  ItineraryMultiDaysEvents,
  ItineraryMultiDaysEventsItem,
  ItinerarySidebarContainer,
} from './itinerary.styles';

interface ItineraryProps {
  trip: Trip,
}

function getDateISOToEventsMap(
  days: { [key: DateISO]: TripDay },
  events: { [key: EventID]: TripEvent }
) {
  const dateISOToEvents: { [key: DateISO]: TripEvent[] } = {};

  Object.keys(days).forEach((dateISO) => {
    const dayEvents: TripEvent[] = [];
    days[dateISO].eventOrder?.forEach((eventId) => {
      if (!events[eventId]) {
        return;
      }
      dayEvents.push(events[eventId]);
    });
    dateISOToEvents[dateISO] = dayEvents;
  });
  return dateISOToEvents;
}

const defaultNumDays = 4;

function Itinerary({ trip }: ItineraryProps) {
  const dispatch = useAppDispatch();
  const isMobile = useMobileMediaQuery();
  const currentUser = useAppSelector(selectCurrentUser);
  const deletedEvents = useAppSelector(selectDeletedEvents);
  const cards = useAppSelector(selectCards);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const ref = useRef(null);

  const canEdit = Boolean(canEditTrip(trip, currentUser));
  const startDate = trip.startDateISO ? parseISO(trip.startDateISO) : defaultStartDate;
  const endDate = trip.endDateISO
    ? parseISO(trip.endDateISO)
    : addDays(defaultStartDate, defaultNumDays - 1);
  const tripDateRange = useMemo(() => ({ from: startDate, to: endDate }), [startDate, endDate]);
  const dateISOToEventsMap = getDateISOToEventsMap(trip.days, trip.events);

  const createEventForDate = useCallback(
    (
      dayId: string | undefined,
      dateISO: string,
      cardId: CardID,
      eventOrder: string[] | undefined
    ) => {
      dispatch(addEventToDate({ tripId: trip.id, dayId, dateISO, cardId, eventOrder }));
    },
    [dispatch, trip.id]
  );

  const moveEvent = useCallback(
    (
      dayId: string | undefined,
      toDateISO: DateISO,
      eventId: EventID,
      toDayEventOrder: string[] | undefined
    ) => {
      const event = trip.events[eventId];
      if (event) {
        const fromDay = trip.days[event.startDateISO];
        if (fromDay && fromDay.id && fromDay.eventOrder) {
          dispatch(
            moveEventToDate({
              tripId: trip.id,
              fromDayId: fromDay.id,
              toDayId: dayId,
              fromDateISO: fromDay.dateISO,
              toDateISO,
              eventId,
              fromDayEventOrder: fromDay.eventOrder,
              toDayEventOrder,
            })
          );
        }
      }
    },
    [dispatch, trip.days, trip.events, trip.id]
  );

  const deleteEvent = useCallback(
    (eventId: EventID, cardId: CardID, dateISO: DateISO) => {
      const day = trip.days[dateISO];
      if (day && day.id && day.eventOrder) {
        dispatch(
          deleteEventFromDate({
            tripId: trip.id,
            dayId: day.id,
            dateISO,
            eventId,
            cardId,
            eventOrder: day.eventOrder,
          })
        );
      }
    },
    [dispatch, trip.days, trip.id]
  );

  const [, eventDropRef] = useDrop({
    accept: ItemType.EVENT,
    drop: (item: { type: string, event: TripEvent, card: TripCard }) => {
      if (item.event.id && trip) {
        const [listName]: TripList[] = trip.lists.filter(
          (el) => el.id === trip.cardIdToListId[item.card.id]
        );
        const action = (snackbarId: string) => (
          <SnackbarDismissButton onClick={() => closeSnackbar(snackbarId)}>
            dismiss
          </SnackbarDismissButton>
        );
        deleteEvent(item.event.id, item.event.cardId, item.event.startDateISO);
        enqueueSnackbar(
          `${item.card.customName ?? item.card.name} was added back to your ${listName.name} list`,
          { action }
        );
      }
    },
  });

  eventDropRef(ref);

  const arrayOfDays =
    tripDateRange?.from &&
    tripDateRange?.to &&
    eachDayOfInterval({ start: tripDateRange.from, end: tripDateRange.to });

  const generateEvents = () => {
    const events: ReactElement[] = [];
    const multiDaysEvents: ReactElement[] = [];
    if (!arrayOfDays.length) return [events, multiDaysEvents];
    arrayOfDays.forEach((date, index) => {
      const dateISO = getISODate(date);
      const col = index + 1;
      const arrOfEvents = Object.values(trip.events);
      if (arrOfEvents.length) {
        arrOfEvents
          .sort((a: TripEvent, b: TripEvent) => {
            if (a.eventLength && b.eventLength) {
              if (a.eventLength > b.eventLength) return 1;
              if (a.eventLength < b.eventLength) return -1;
            }
            return 0;
          })
          .reverse();
        arrOfEvents.forEach((eventItem: TripEvent) => {
          if (
            !eventItem.id ||
            trip.events[eventItem.id].startDateISO !== dateISO ||
            !trip.events[eventItem.id].eventLength
          ) {
            return;
          }
          const eventLength = trip.events[eventItem.id].eventLength as number;
          const spanDate = col + eventLength <= arrayOfDays.length ? eventLength : -1;
          const { emoji, color } = getEmojiAndColorForCard(
            trip.events[eventItem.id].cardId,
            trip.lists
          );
          multiDaysEvents.push(
            <ItineraryMultiDaysEventsItem key={eventItem.id} startDate={col} spanDate={spanDate}>
              <ItineraryEvent
                key={eventItem.id}
                tripId={trip.id}
                event={trip.events[eventItem.id]}
                emoji={emoji}
                color={color}
                canEdit={canEdit}
                card={trip.cards[trip.events[eventItem.id].cardId]}
                eventLength={eventLength}
                isMultidayEvent
              />
            </ItineraryMultiDaysEventsItem>
          );
        });
      }
      events.push(
        <Fragment key={col}>
          <ItineraryCalendarDayTitle col={col}>
            {getYear(date) === getYear(defaultStartDate) ? `Day ${col}` : format(date, 'E MMM d')}
          </ItineraryCalendarDayTitle>
          <ItineraryEvents
            tripId={trip.id}
            tripDay={trip.days[dateISO]}
            events={dateISOToEventsMap[dateISO]}
            col={col}
            canEdit={canEdit}
            createEventForDate={createEventForDate}
            moveEventToDate={moveEvent}
          />
        </Fragment>
      );
    });
    return [events, multiDaysEvents];
  };

  const [singleDayEvents, multiDaysEvents] = generateEvents();

  const showDeletedEvents = useCallback(
    (arrOfDeletedEvents: TripEvent[]) => {
      if (!cards) {
        return;
      }
      const action = (snackbarId: string) => (
        <SnackbarDismissButton onClick={() => closeSnackbar(snackbarId)}>
          undo*
        </SnackbarDismissButton>
      );
      arrOfDeletedEvents.forEach((anEvent) => {
        enqueueSnackbar(`${cards[anEvent.cardId].name} added back to lists`, { action });
      });
      dispatch(clearDeletedEvents());
    },
    [cards, closeSnackbar, enqueueSnackbar, dispatch]
  );

  useEffect(() => {
    if (deletedEvents?.length) {
      showDeletedEvents(deletedEvents);
    }
  }, [deletedEvents, showDeletedEvents]);

  return (
    <ItineraryContainer>
      {canEdit && !isMobile && (
        <ItinerarySidebarContainer ref={ref}>
          <ItinerarySidebar trip={trip} canEdit={canEdit} />
          {!Object.keys(trip.cardIdToListId).length && <ItinerarySidebarDefault />}
        </ItinerarySidebarContainer>
      )}
      <ItineraryMainContainer canEdit={canEdit} isMobile={isMobile}>
        {/* <ItineraryCalendarKeyContainer>
          <ItineraryCalendarLabel key="lodging-label" row={2}>
            🏡
          </ItineraryCalendarLabel>
          <ItineraryCalendarLabel key="notes-label" row={3}>
            📝
          </ItineraryCalendarLabel>
          <ItineraryCalendarLabel key="events-label" row={4}>
            📍
          </ItineraryCalendarLabel>
        </ItineraryCalendarKeyContainer> */}
        {arrayOfDays ? (
          <ItineraryCalendarContainer
            isMultidayEvents={!!multiDaysEvents.length}
            isMobile={isMobile}
          >
            {!!multiDaysEvents.length && (
              <ItineraryMultiDaysEvents amountOfColumns={arrayOfDays.length}>
                {multiDaysEvents}
              </ItineraryMultiDaysEvents>
            )}
            {singleDayEvents}
          </ItineraryCalendarContainer>
        ) : null}
      </ItineraryMainContainer>
    </ItineraryContainer>
  );
}

Itinerary.displayName = 'Itinerary';
export default Itinerary;
