import { deleteField, doc, updateDoc } from 'firebase/firestore';
import { IChangeEventLengthPayload } from '../../redux/trip/trip.types';
import { ECollections } from '../firebase.types';
import { db } from '../firebase.utils';

export const changeEventLengthService = ({
  tripId,
  eventId,
  eventLength,
}: IChangeEventLengthPayload): Promise<void> => {
  const eventRef = doc(db, ECollections.trips, tripId, ECollections.events, eventId);
  return updateDoc(eventRef, { eventLength, updatedAt: Date.now() });
};

export const removeEventLengthService = ({
  tripId,
  eventId,
}: IChangeEventLengthPayload): Promise<void> => {
  const eventRef = doc(db, ECollections.trips, tripId, ECollections.events, eventId);
  return updateDoc(eventRef, {
    eventLength: deleteField(),
    updatedAt: Date.now(),
  });
};
