import styled from 'styled-components';

type PasswordPageProps = {
    isMobile?: boolean,
    isWrongPassword?: boolean,
};

export const PasswordPageContainer = styled.div`
    font-family: Poppins;
    text-align: center;
    width: 50vw;
    height: 100vh;
`;

export const PasswordContainer = styled.div`
    position: absolute;
    top: 40%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
`;

export const Passwordh1 = styled.h1<PasswordPageProps>`
    font-size: ${(props) => (props.isMobile ? '30' : '44')}px;
    font-weight: 600;
    letter-spacing: 1.92px;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 20px;
`;

export const Passwordh2 = styled.h2<PasswordPageProps>`
    font-size: ${(props) => (props.isMobile ? '12' : '18')}px;
    font-weight: 300;
    letter-spacing: 0.72px;
    text-transform: uppercase;
    margin: 10px;
`;

export const PasswordError = styled.div`
    color: red;
    margin-top: 1em;
`;

export const PasswordForm = styled.form<PasswordPageProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid ${(props) => (props.isWrongPassword ? 'red' : '#000')};
    width: ${(props) => (props.isMobile ? '80%' : '300px')};
    height: 56px;
    margin: 0 auto;
`;

export const PasswordInput = styled.input`
    font-size: 16px;
    flex: 1;
    border: none;
    border-radius: 10px;
    width: inherit;
    height: 100%;
    outline: none;
    margin-left: 4px;
`;

export const PasswordButton = styled.button`
    font-size: 14px;
    font-weight: 400;
    color: black;
    background-color: transparent;
    border: none;
    width: 100px;
    height: 100%;
    text-transform: uppercase;
    z-index: 1;
`;

export const MadeWithLoveFooter = styled.footer`
    font-size: 12px;
    width: 100%;
    position: absolute;
    bottom: 4em;
`;
