import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
} from '@mui/material';
import { DeleteOutlineRounded, MoreVertRounded } from '@mui/icons-material';

import { useAppDispatch } from '../../../redux/hooks';
import useMobileMediaQuery from '../../../utils/media-query.utils';
import { deleteTrip } from '../../../redux/trip/trip.slice';
import { getTripDates } from '../../../utils/trips-list.utils';
import LetterAvatarGroup from '../../letter-avatar/letter-avatar-group.component';
import { getUserDocumentsWithIds } from '../../../firebase/firebase.utils';
import { RolesMap, TripRole, UserData, UserToRole } from '../../../firebase/firebase.types';

import {
  TripsListAvatarsGroup,
  TripsListDates,
  TripsListItemContainer,
  TripsListItemDeleteItemIcon,
  TripsListItemDeleteItemText,
  TripsListLocation,
  TripsListMoreButton,
  TripsListMoreOptionsContainer,
  TripsListTitle,
} from './trips-list-item.styles';

type UserAndRole = UserToRole & UserData;

interface TripsListItemProps {
  tripId: string,
  title: string,
  location: string | unknown,
  startDateISO?: string,
  endDateISO?: string,
  userRoles: RolesMap,
  canDelete?: boolean,
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined,
}

function TripsListItem({
  tripId,
  title,
  location,
  startDateISO,
  endDateISO,
  userRoles,
  canDelete,
  onClick,
}: TripsListItemProps) {
  const dispatch = useAppDispatch();
  const isMobile = useMobileMediaQuery();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);
  const open = Boolean(anchorEl);
  const tripDates = getTripDates(startDateISO, endDateISO, true);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    setShowDeleteDialog(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteDialog(false);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteTrip({ tripId }));
    setShowDeleteDialog(false);
  };

  useEffect(() => {
    const fetchUserRoles = async () => {
      const userIds = Object.keys(userRoles);
      const usersDocs = await getUserDocumentsWithIds(userIds);
      const roles: UserAndRole[] = [];
      usersDocs.forEach((userDoc) => {
        const userAndRole = {
          userId: userDoc.id,
          role: userRoles[userDoc.id],
          ...(userDoc.data() as UserData),
        };
        if (userAndRole.role === TripRole.OWNER) {
          roles.unshift(userAndRole);
        } else {
          roles.push(userAndRole);
        }
      });
      const userEmails = roles.map((user) => user.email);
      setEmails(userEmails);
    };
    if (userRoles) {
      fetchUserRoles();
    }
  }, [dispatch, userRoles]);

  return (
    <>
      <TripsListItemContainer onClick={onClick} isMobile={isMobile}>
        <TripsListTitle isMobile={isMobile}>{title}</TripsListTitle>
        {canDelete && (
          <TripsListMoreOptionsContainer isMobile={isMobile}>
            <TripsListMoreButton onClick={handleClick}>
              <MoreVertRounded />
            </TripsListMoreButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{ sx: { borderRadius: '10px' } }}
            >
              <MenuItem onClick={handleDeleteClick}>
                <TripsListItemDeleteItemIcon>
                  <DeleteOutlineRounded />
                </TripsListItemDeleteItemIcon>
                <TripsListItemDeleteItemText>Delete</TripsListItemDeleteItemText>
              </MenuItem>
            </Menu>
          </TripsListMoreOptionsContainer>
        )}
        <TripsListLocation isMobile={isMobile}>📍 {location}</TripsListLocation>
        {tripDates && <TripsListDates isMobile={isMobile}>{tripDates}</TripsListDates>}
        {emails.length > 1 && (
          <TripsListAvatarsGroup isMobile={isMobile}>
            <LetterAvatarGroup userRoles={emails} />
          </TripsListAvatarsGroup>
        )}
      </TripsListItemContainer>

      <Dialog
        open={showDeleteDialog}
        onClose={handleCancelDelete}
        PaperProps={{ sx: { borderRadius: '10px' } }}
      >
        <DialogTitle>{`Are you sure you want to delete "${title}"?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>This cannot be undone.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>No</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

TripsListItem.displayName = 'TripsListItem';
export default TripsListItem;
