// Import necessary libraries/components
import React, { useState, useEffect, useRef } from 'react';
import { TypeAnimation } from 'react-type-animation';
import PlacesAutocomplete from '../../components/places-autocomplete/places-autocomplete.component';

type HomePlaceholderAnimationProps = {
  onSuggestionSelect: (suggestion: google.maps.places.AutocompletePrediction) => void,
  setHasError: React.Dispatch<React.SetStateAction<boolean>>,
  hasError: boolean,
  isMobile: boolean,
};
/* eslint-disable react/function-component-definition */
const AnimatedPlaceholderInput: React.FC<HomePlaceholderAnimationProps> = ({
  onSuggestionSelect,
  setHasError,
  hasError,
  isMobile,
}) => {
  const [isTyping, setIsTyping] = useState(false);
  const [suggestionSelected, setSuggestionSelected] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleContainerClick = () => {
    setIsTyping(true);
  };

  const handleKeyDown = () => {
    setIsTyping(true);
  };

  const handleSuggestionSelect = (suggestion: google.maps.places.AutocompletePrediction) => {
    onSuggestionSelect(suggestion);
    setSuggestionSelected(true);
    setHasError(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        // Only set isTyping to false if a suggestion has not been selected
        if (!suggestionSelected) {
          setIsTyping(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [suggestionSelected]);

  return (
    <div
      ref={containerRef}
      style={{ position: 'relative' }}
      onKeyDown={handleKeyDown}
      onClick={handleContainerClick}
      role="button"
      tabIndex={0}
    >
      <PlacesAutocomplete
        types={['(regions)']}
        placeholder=""
        className="home-autocomplete"
        onSuggestionSelect={handleSuggestionSelect}
        hasError={hasError}
        isHomeInput
      />
      {!isTyping && !suggestionSelected && (
        <div
          style={{
            position: 'absolute',
            top: isMobile ? '28px' : '6px',
            marginLeft: '5.85rem',
            zIndex: -3,
          }}
        >
          <TypeAnimation
            sequence={[
              'Tokyo',
              1000,
              'Barcelona',
              1000,
              'New York',
              1000,
              'Cape Town',
              1000,
              'Bangkok',
              1000,
              'Mexico City',
              1000,
              'Vancouver',
              1000,
              'Amsterdam',
              1000,
            ]}
            speed={25}
            style={{
              fontSize: isMobile ? '1.85rem' : '2.5rem',
              color: 'grey',
              fontStyle: 'italic',
              zIndex: '-2',
              opacity: '0.7',
              display: 'inline-block',
            }}
            repeat={Infinity}
          />
        </div>
      )}
    </div>
  );
};

AnimatedPlaceholderInput.displayName = 'AnimatedPlaceholderInput';
export default AnimatedPlaceholderInput;
