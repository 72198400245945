import styled from 'styled-components';

type MarkerEmojiContainerProps = {
  bringToFront: boolean,
};

export const MarkerEmojiContainer = styled.div<MarkerEmojiContainerProps>`
  position: absolute;
  transform: translate(-50%, -100%);

  ${(props) => (props.bringToFront ? 'z-index: 10;' : '')}
`;

export const MarkerEmojiPin = styled.div`
  width: 32px;
  height: 42px;
  background: url('/svgs/map-marker.svg') no-repeat;
`;

export const MarkerEmojiPinHighlighted = styled.div`
  width: 32px;
  height: 42px;
  background: url('/svgs/map-marker-highlighted.svg') no-repeat;
`;

type MobileProps = {
  isMobile: boolean,
};

export const MarkerEmojiText = styled.span<MobileProps>`
  position: absolute;
  font-size: ${(props) => (props.isMobile ? '14px' : '16px')};
  top: ${(props) => (props.isMobile ? '4px' : '6px')};
  left: ${(props) => (props.isMobile ? '7px' : '8px')};
`;

export const MarkerDescriptionContainer = styled.div`
  position: absolute;
  transform: translate(-50%, 0);
  min-width: 80px;
  max-width: 280px;
  padding: 8px;
  background: white;
  color: #2c969d;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0;
  opacity: 0.9;
  z-index: 10;
  border-radius: 2px;
`;
