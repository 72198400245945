import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useMobileMediaQuery from '../../utils/media-query.utils';

import { LogoImg } from '../home/home.styles';
import {
    PasswordPageContainer,
    PasswordContainer,
    Passwordh1,
    Passwordh2,
    PasswordForm,
    PasswordError,
    PasswordInput,
    PasswordButton,
    MadeWithLoveFooter
} from './password-protect.styles'

function PasswordProtect() {
    const isMobile = useMobileMediaQuery();
    const navigate = useNavigate();
    const [isWrongPassword, setIsWrongPassword] = useState(false);

    const checkPassword = (password: string) => {
        const correctPassword = 'wanderingwheretogo';
        if (password.toLowerCase() === correctPassword) {
            localStorage.setItem('isAuthenticated', 'true');
            navigate(-1);
            window.location.reload();
        } else {
            setIsWrongPassword(true);
        }
    };

    return (
        <PasswordPageContainer>
            <PasswordContainer>
                <LogoImg src="/svgs/logo_2.png" alt="wander logo" />
                <Passwordh2 isMobile={isMobile}>Beta Access</Passwordh2>
                <Passwordh1 isMobile={isMobile}>Invitation Code</Passwordh1>
                <PasswordForm isMobile={isMobile} isWrongPassword={isWrongPassword} onSubmit={(e) => {
                    e.preventDefault();
                    const passwordInput = (e.target as HTMLFormElement).elements.namedItem('password') as HTMLInputElement;
                    checkPassword(passwordInput?.value);
                }}>
                        <PasswordInput type="text" name="password"/>
                        <PasswordButton type="submit">Enter ↵</PasswordButton>
                </PasswordForm>
                <PasswordError>{isWrongPassword && 'Invitation code not valid'}</PasswordError>
                {/* <Passwordh2>Join Waitlist</Passwordh2> */}
            </PasswordContainer>
            <MadeWithLoveFooter>Made with ❤️ from a team of passionate travelers</MadeWithLoveFooter>
        </PasswordPageContainer>
    );
};

PasswordProtect.displayName = 'PasswordProtect';
export default PasswordProtect;
