import React from 'react';
import { Helmet } from 'react-helmet';
import useMobileMediaQuery from '../../utils/media-query.utils';
import {NotFoundContainer, NotFoundImage, NotFoundMessage, NotFoundRedirectButton} from './not-found-404.styles';

function NotFound404() {
  const isMobile = useMobileMediaQuery();

  return (
    <>
      <Helmet>
        <title>Page Not Found 404</title>
      </Helmet>
      <NotFoundContainer>
        <NotFoundImage src="/svgs/404.png" alt="404 Not found" isMobile={isMobile}/>
        <NotFoundMessage isMobile={isMobile}>You&apos;ve wandered into unknown territory</NotFoundMessage>
        <NotFoundRedirectButton href="/" isMobile={isMobile}>Head Back Home</NotFoundRedirectButton>
      </NotFoundContainer>
    </>
  );
};

NotFound404.displayName = 'NotFound404';
export default NotFound404;