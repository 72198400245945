/** Function which uses regex to detext links as we type in the editor */
const findUrlsInText = (text: string) => {
  const urlRegex =
    /(https?:\/\/(?:www\.|(?!www))[^\s.]+\.[^\s{}()[\];<>^*`'"]{2,}|www\.[^\s]+\.[^\s{}()[\];<>^*`'"]{2,})/gi;

  const matches = text.match(urlRegex);

  return matches ? matches.map((m) => [m.trim(), text.indexOf(m.trim())]) : [];
};

export default findUrlsInText;
