import { Descendant, Element, Node, Text } from 'slate';
import { Trip, TripCard } from '../redux/trip/trip.types';

export const isElement = (descendant: Descendant): descendant is Element =>
  (descendant as Element).children !== undefined;

export const isNotesEmpty = (notepad: Trip | TripCard): boolean =>
  !notepad.notes ||
  (notepad.notes.length === 1 &&
    ((isElement(notepad.notes[0]) &&
      notepad.notes[0].children.length === 1 &&
      !Node.string(notepad.notes[0].children[0])) ||
      (Text.isText(notepad.notes[0]) && !notepad.notes[0].text)));
