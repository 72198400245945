import React, { useState } from 'react';
import Grow from '@material-ui/core/Grow';
import { Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { selectCurrentUser } from '../../../../redux/user/user.selectors';
import { addList } from '../../../../redux/trip/trip.slice';
import useMobileMediaQuery from '../../../../utils/media-query.utils';

import {
  ListsLabelDiv,
  AddListDiv,
  PlusSvg,
  StyledInput,
  NewListButtons,
  AddItemDiv,
  SaveAddItemButton,
  AddSpan,
  CancelAddItemDiv,
  CancelAddItemButton,
  CancelSpan,
} from './add-list-input.styles';

type AddListInputProps = {
  tripId: string | undefined,
  canEdit?: boolean,
  listOrder: string[] | undefined,
};

const defaultEmoji = '🏝️';

function AddListInput({ tripId, canEdit, listOrder }: AddListInputProps) {
  const isMobile = useMobileMediaQuery();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const [newListName, setNewListName] = useState('');
  const [isAddingList, setIsAddingList] = useState(false);

  const showAddList = () => setIsAddingList(true);

  const onAddList = () => {
    setIsAddingList(false);
    if (tripId && currentUser && newListName.length !== 0) {
      dispatch(
        addList({
          tripId,
          list: {
            name: newListName,
            emoji: defaultEmoji,
            creator: currentUser.uid,
          },
          listOrder: listOrder || [],
        })
      );
      setNewListName('');
    }
  };

  const cancelAddList = () => {
    // setTimeout needed to override the onBlur when the AddItemDiv is clicked
    setTimeout(() => {
      setIsAddingList(false);
      setNewListName('');
    }, 100);
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const maxCharacterCount = 100;
    const listName = event.target as HTMLInputElement;
    const text = listName.value;

    if (event.key === 'Enter' && text.length <= maxCharacterCount) {
      onAddList();
    }

    if (event.key === 'Escape') {
      cancelAddList();
    }

    if (
      text.length >= maxCharacterCount &&
      event.key !== 'Backspace' &&
      event.key !== 'ArrowLeft' &&
      event.key !== 'ArrowRight'
    ) {
      event.preventDefault(); // Prevent further input
    }
  };

  return (
    <>
      <ListsLabelDiv isMobile={isMobile}>
        LISTS
        {canEdit && !isAddingList && (
          <Tooltip title="Add List">
            <AddListDiv onClick={showAddList}>
              <PlusSvg viewBox="0 0 455 455">
                <polygon points="455,212.5 242.5,212.5 242.5,0 212.5,0 212.5,212.5 0,212.5 0,242.5 212.5,242.5 212.5,455 242.5,455 242.5,242.5 455,242.5" />
              </PlusSvg>
            </AddListDiv>
          </Tooltip>
        )}
      </ListsLabelDiv>
      <Grow
        in={isAddingList}
        mountOnEnter
        unmountOnExit
        timeout={{
          enter: 1000,
          exit: 400,
        }}
      >
        <>
          <StyledInput
            placeholder="e.g. Restaurants"
            value={newListName}
            onChange={(e) => setNewListName(e.target.value)}
            onBlur={cancelAddList}
            onKeyDown={handleOnKeyDown}
            isMobile={isMobile}
            autoFocus
          />
          <NewListButtons isMobile={isMobile}>
            <CancelAddItemDiv onClick={cancelAddList}>
              <CancelAddItemButton />
              <CancelSpan>CANCEL</CancelSpan>
            </CancelAddItemDiv>
            {newListName && (
              <AddItemDiv onClick={onAddList}>
                <SaveAddItemButton />
                <AddSpan>SAVE</AddSpan>
              </AddItemDiv>
            )}
          </NewListButtons>
        </>
      </Grow>
    </>
  );
}

AddListInput.displayName = 'AddListInput';
export default AddListInput;
