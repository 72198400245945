import { FieldValue, Timestamp } from 'firebase/firestore';
import { Descendant } from 'slate';

export enum ECollections {
  users = 'users',
  trips = 'trips',
  cards = 'cards',
  events = 'events',
  lists = 'lists',
  days = 'days',
}

export interface AdditionalInformation {
  displayName?: string,
}

export type UserData = {
  createdAt: Date,
  displayName: string,
  email: string,
};

export type LatLng = {
  latitude: number,
  longitude: number,
};

export enum TripRole {
  OWNER = 'owner',
  EDITOR = 'editor',
  VIEWER = 'viewer',
}

export interface UserToRole {
  userId: string,
  role: TripRole,
}

export interface RolesMap {
  [key: string]: TripRole,
}

export interface DeletableRolesMap {
  [key: string]: TripRole | FieldValue,
}

export interface NewTripData {
  title: string,
  location: LatLng,
  place: string,
  notes: Descendant[],
  createdAt: Timestamp | FieldValue,
}

export interface LastOpenedMap {
  [userId: string]: Timestamp,
}

export interface TripData {
  listOrder: string[],
  location: LatLng,
  notes: Descendant[],
  place: string,
  public: boolean,
  roles: RolesMap,
  title: string,
  createdAt: Timestamp,
  startDateISO?: string,
  endDateISO?: string,
  lastOpened?: LastOpenedMap,
}

export interface ListData {
  cardOrder?: string[],
  creator?: string,
  emoji: string,
  name: string,
  createdAt?: Timestamp,
}

export interface CardData extends Record<string, unknown> {
  adrAddress?: string,
  businessStatus?: google.maps.places.BusinessStatus,
  creator: string,
  formattedAddress?: string,
  formattedPhoneNumber?: string,
  googlePlaceId: string,
  internationalPhoneNumber?: string,
  location?: LatLng,
  name?: string,
  notes?: Descendant[],
  openingHours?: string[],
  priceLevel?: number,
  rating?: number,
  types?: string[],
  url?: string,
  website?: string,
  dateAdded?: Timestamp,
  defaultImage?: number | null,
}

export interface DayData {
  dateISO: string,
  notes?: Descendant[],
  eventOrder?: string[],
}

export interface EventData {
  cardId: string,
  startDateISO: string,
  endDateISO?: string,
}
