/**
 *
 * TripTitleHeader
 *
 */
import React, { MouseEvent, useMemo, useState, useEffect, useCallback } from 'react';
import { parseISO, getYear, addDays } from 'date-fns';
import { defaultStartDate } from '../constants';
import useMobileMediaQuery from '../../../utils/media-query.utils';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

import { updateTrip } from '../../../redux/trip/trip.slice';
import { Trip } from '../../../redux/trip/trip.types';
import { updateDates } from '../../../redux/trip/trip.operations';
import { selectUserEmails } from '../../../redux/trip/trip.selectors';
import { getTripDates } from '../../../utils/trips-list.utils';

import TripShare from '../trip-share/trip-share.component';
import LetterAvatarGroup from '../../letter-avatar/letter-avatar-group.component';
import DateRangePicker from '../../date-range-picker/date-range-picker.component';
import getItineraryTitle from './trip-title-header.utils';

import {
  TripTitleHeaderText,
  TripDetailsContainer,
  TripDatesContainer,
} from './trip-title-header.styles';

type TripTitleHeaderProps = {
  trip: Trip,
  canEdit?: boolean,
  currentUser: unknown,
};

function TripTitleHeader({ trip, canEdit = false, currentUser }: TripTitleHeaderProps) {
  const { id: tripId, title: tripTitle, startDateISO, endDateISO } = trip;
  const dispatch = useAppDispatch();
  const isMobile = useMobileMediaQuery();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const userRoles: any = useAppSelector(selectUserEmails);
  const tripDates = getTripDates(startDateISO, endDateISO, isMobile);
  const hasTripDates = !!tripDates;

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isPickerOpen, setIsPickerOpen] = useState(false); // keeps date-picker open if true. Doesn't close if hover away.

  const defaultNumDays = 4;

  const startDate = trip.startDateISO ? parseISO(trip.startDateISO) : defaultStartDate;
  const endDate = trip.endDateISO
    ? parseISO(trip.endDateISO)
    : addDays(defaultStartDate, defaultNumDays - 1);
  const tripDateRange = useMemo(() => ({ from: startDate, to: endDate }), [startDate, endDate]);

  const setDefaultDates = useCallback(() => {
    dispatch(
      updateDates({
        tripId: trip.id,
        oldDateRange: tripDateRange,
        newDateRange: { from: startDate, to: endDate },
        days: trip.days,
      })
    );
  }, [dispatch, trip.id, trip.days, startDate, endDate, tripDateRange]);

  useEffect(() => {
    if (startDate !== defaultStartDate) {
      return;
    }
    setDefaultDates();
  }, [setDefaultDates, startDate]);

  // Handlers for showing and hiding the DateRangePicker
  const handleMouseEnter = () => {
    setShowDatePicker(true);
  };

  const handleMouseLeave = () => {
    if (!isPickerOpen) {
      setShowDatePicker(false);
    }
  };

  const makeEditable = (event: MouseEvent<HTMLSpanElement>) => {
    const tripTitleSpan = event.target as HTMLSpanElement;
    tripTitleSpan.setAttribute('contentEditable', 'true');
    tripTitleSpan.focus();
  };

  const submitChanges = (
    event: React.FocusEvent<HTMLSpanElement> | React.KeyboardEvent<HTMLSpanElement>
  ) => {
    const tripTitleSpan = event.target as HTMLSpanElement;
    tripTitleSpan.setAttribute('contentEditable', 'false');
    const text = tripTitleSpan.innerText;

    if (text.length === 0 || !text.trim() || text === tripTitle) {
      tripTitleSpan.innerText = tripTitle;
      return;
    }

    dispatch(updateTrip({ tripId, field: 'title', value: text }));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    const maxCharacterCount = 100;
    const tripTitleSpan = event.target as HTMLSpanElement;
    const text = tripTitleSpan.innerText;

    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent creating a new line
      submitChanges(event);
    }

    if (
      text.length >= maxCharacterCount &&
      event.key !== 'Backspace' &&
      event.key !== 'ArrowLeft' &&
      event.key !== 'ArrowRight'
    ) {
      event.preventDefault(); // Prevent further input
    }
  };

  return (
    <div>
      <TripTitleHeaderText
        canEdit={canEdit}
        onClick={canEdit ? makeEditable : undefined}
        onBlur={canEdit ? submitChanges : undefined}
        onKeyDown={canEdit ? handleKeyDown : undefined}
      >
        {tripTitle}
      </TripTitleHeaderText>
      <TripDetailsContainer hasTripDates={hasTripDates}>
        <div>
          {/* Don't render h3 element if user is a Viewer AND no dates are set. */}
          {(!canEdit &&
            getYear(tripDateRange?.from || defaultStartDate) === getYear(defaultStartDate)) ||
          (isMobile &&
            getYear(tripDateRange?.from || defaultStartDate) ===
              getYear(defaultStartDate)) ? null : (
            <TripDatesContainer onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <h3>
                {getYear(tripDateRange?.from || defaultStartDate) === getYear(defaultStartDate)
                  ? getItineraryTitle(undefined, isMobile)
                  : getItineraryTitle(tripDateRange, isMobile)}
              </h3>
              {canEdit && !isMobile && showDatePicker && (
                <DateRangePicker
                  trip={trip}
                  initialDateRange={tripDateRange}
                  setIsPickerOpen={setIsPickerOpen}
                />
              )}
            </TripDatesContainer>
          )}

          {isMobile && userRoles && userRoles.length > 1 && (
            <LetterAvatarGroup userRoles={userRoles} />
          )}
        </div>
        {isMobile && currentUser && <TripShare trip={trip} canEdit={canEdit} />}
      </TripDetailsContainer>
    </div>
  );
}

TripTitleHeader.displayName = 'TripTitleHeader';
export default TripTitleHeader;
