import React, { ChangeEventHandler, InputHTMLAttributes } from 'react';

import { GroupContainer, FormInputContainer, FormInputLabel } from './form-input.styles';

type FormInputProps = {
  label: string,
  handleChange?: ChangeEventHandler<HTMLInputElement>,
} & InputHTMLAttributes<HTMLInputElement>;

function FormInput({ handleChange, label, ...props }: FormInputProps) {
  return (
    <GroupContainer>
      <FormInputContainer onChange={handleChange} {...props} />
      {label ? (
        // eslint-disable-next-line react/prop-types
        <FormInputLabel
          className={
            props.value && typeof props.value === 'string' && props.value.length ? 'shrink' : ''
          }
        >
          {label}
        </FormInputLabel>
      ) : null}
    </GroupContainer>
  );
}

FormInput.displayName = 'FormInput';
export default FormInput;
