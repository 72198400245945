/* eslint-disable camelcase */
/* eslint-disable react/jsx-boolean-value */
/**
 *
 * LandingPage
 *
 */
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import Fade from '@mui/material/Fade';
import { getDetails } from 'use-places-autocomplete';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

import { selectCurrentUser } from '../../redux/user/user.selectors';
import { selectCurrentTrip, selectTripCreationStatus } from '../../redux/trip/trip.selectors';
import { resetTripCreationStatus, createTrip } from '../../redux/trip/trip.slice';

import Spinner from '../../components/spinner/spinner.component';
import { TripCreationStatus } from '../../redux/trip/trip.types';
import { LatLng } from '../../firebase/firebase.types';
import useMobileMediaQuery from '../../utils/media-query.utils';
import AnimatedPlaceholderInput from './home-placeholder-animation';

import {
  HomeAutocompleteContainer,
  HomeButtonContainer,
  HomeContainer,
  LogoContainer,
  LogoImg,
  HomeHeader,
  HomeInputLabel,
  StartPlanningButton,
  HomepageFooter,
  HomeErrorMessage,
} from './home.styles';

function hasGeometry(
  result: google.maps.places.PlaceResult | string
): result is google.maps.places.PlaceResult {
  return (result as google.maps.places.PlaceResult).geometry !== undefined;
}

type Place = {
  description: string,
  location: LatLng,
};

function Home() {
  const dispatch = useAppDispatch();
  const isMobile = useMobileMediaQuery();
  const navigate = useNavigate();
  const currentUser = useAppSelector(selectCurrentUser);
  const tripCreationStatus = useAppSelector(selectTripCreationStatus);
  const currentTrip = useAppSelector(selectCurrentTrip);
  const [place, setPlace] = useState<Place | null>(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (tripCreationStatus === TripCreationStatus.CREATED) {
      dispatch(resetTripCreationStatus());
      if (currentTrip) {
        navigate(`/trips/${currentTrip.id}`);
      }
    }
  }, [currentTrip, dispatch, navigate, tripCreationStatus]);

  const handleSuggestionSelect = (suggestion: google.maps.places.AutocompletePrediction) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { place_id, description } = suggestion;
    getDetails({ placeId: place_id, fields: ['geometry.location'] })
      .then((details) => {
        if (hasGeometry(details) && details.geometry?.location) {
          const latitude = details.geometry.location.lat();
          const longitude = details.geometry.location.lng();
          setPlace({
            description,
            location: { latitude, longitude },
          });
        }
      })
      .catch((error) => {
        console.log('Error ', error);
      });
  };

  const handleStartPlanning = () => {
    if (place) {
      if (currentUser) {
        dispatch(
          createTrip({ place: place.description, location: place.location, user: currentUser })
        );
      }
      setHasError(false);
    } else {
      setHasError(true);
    }
  };

  const navigateToSignInSignUp = () => navigate('/login');

  return tripCreationStatus === TripCreationStatus.CREATING ? (
    <Spinner />
  ) : (
    <>
      <Helmet>
        <title>Wander | Home</title>
      </Helmet>
      <HomeContainer isMobile={isMobile}>
        <LogoContainer>
          <LogoImg src="/svgs/logo_2.png" alt="" />
        </LogoContainer>
        {currentUser ? (
          <div>
            <Fade in={true} timeout={2100}>
              <HomeHeader isMobile={isMobile}>A planner for your next</HomeHeader>
            </Fade>
            <HomeButtonContainer>
              <HomeAutocompleteContainer isMobile={isMobile}>
                <HomeInputLabel isMobile={isMobile}>Adventure to</HomeInputLabel>
                <AnimatedPlaceholderInput
                  onSuggestionSelect={handleSuggestionSelect}
                  setHasError={setHasError}
                  hasError={hasError}
                  isMobile={isMobile}
                />
                {/* Display error message */}
                {hasError && (
                  <HomeErrorMessage isMobile={isMobile}>Select a Location.</HomeErrorMessage>
                )}
              </HomeAutocompleteContainer>
              <StartPlanningButton variant="outlined" onClick={handleStartPlanning}>
                Start Planning
              </StartPlanningButton>
            </HomeButtonContainer>
          </div>
        ) : (
          <div>
            <Fade in={true} timeout={2100}>
              <HomeHeader isMobile={isMobile}>A planner for your next adventure</HomeHeader>
            </Fade>
            <HomeButtonContainer>
              <StartPlanningButton variant="outlined" onClick={navigateToSignInSignUp}>
                Get Started
              </StartPlanningButton>
            </HomeButtonContainer>
          </div>
        )}
        <HomepageFooter isMobile={isMobile}>
          Made with ❤️ from a team of passionate travelers
        </HomepageFooter>
      </HomeContainer>
    </>
  );
}

Home.displayName = 'Home';
export default Home;
