import styled from 'styled-components';
import { Accordion, AccordionSummary } from '@mui/material';

type MobileProp = {
  isMobile?: boolean,
};

export const ItinerarySidebarContainer = styled.div`
  width: 100%;
`;

export const ItinerarySidebarTitle = styled.h2<MobileProp>`
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  padding: ${(props) => (props.isMobile ? '1em 16px' : '1em 16px 0')};
`;

export const ItinerarySidebarAccordion = styled(Accordion)`
  box-shadow: none;

  &:before {
    display: none;
  }

  .MuiAccordionSummary-content {
    display: inline-block;
    text-transform: capitalize;
    margin: auto;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    font-weight: 600;
    font-size: 1rem;
    color: #219fa8;
  }
`;

export const ItinerarySidebarEmoji = styled.span`
  font-size: 1.15rem;
  padding-right: 4px;
`;

export const ItineraryCardsContainer = styled.div`
  display: grid;
  align-content: start;
  gap: 16px;
  padding: 4px 2px 8px 2px;
  overflow-y: auto;
  max-height: 400px;
`;

export const ItinerarySidebarItemEmoji = styled.span`
  font-size: 1.15rem;
  padding-right: 10px;
`;

export const ItinerarySidebarItemAccordionSummary = styled(AccordionSummary)`
  box-sizing: border-box;
  border-top: 2px solid
    ${<H,>(props: { hovered?: H }) => (props.hovered ? '#1cb6c1' : 'transparent')};
  border-bottom: 2px solid
    ${<H,>(props: { hovered?: H }) => (props.hovered ? '#1cb6c1' : 'transparent')};

  &.MuiAccordionSummary-root {
    background-color: white;
  }
`;

export const ItinerarySidebarListTitle = styled.div`
  display: flex;
  align-items: flex-start;
`;
