import { combineReducers } from 'redux';

import userSlice from './user/user.slice';
import tripSlice from './trip/trip.slice';

// eslint-disable-next-line import/prefer-default-export
export const rootReducer = combineReducers({
  user: userSlice.reducer,
  trip: tripSlice.reducer,
});
