/**
 *
 * Sidebar
 *
 */
import React, { useState } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { DeleteOutlineRounded, EditRounded, InsertEmoticon } from '@mui/icons-material';
import { useAppDispatch } from '../../../../../redux/hooks';
import { removeList } from '../../../../../redux/trip/trip.slice';
import { ListID, TripID, TripList } from '../../../../../redux/trip/trip.types';
import {
  OptionsMenu,
  OptionsDialog,
  OptionsDialogContent,
  OptionsDialogTitle,
  OptionsDialogContentText,
  OptionsDialogActions,
  CancelButton,
  DeleteButton,
} from './list-options.styles';

type ListOptionsProps = {
  tripId: TripID,
  list: TripList,
  listOrder: ListID[],
  isMobile: boolean,
  anchorEl: HTMLDivElement | null,
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>,
  editListName: () => void,
  handleListEmojiEdit: ((event: React.MouseEvent<HTMLLIElement>) => void) | undefined,
};

function ListOptions({
  tripId,
  list,
  listOrder,
  anchorEl,
  isMobile,
  setAnchorEl,
  editListName,
  handleListEmojiEdit,
}: ListOptionsProps) {
  const dispatch = useAppDispatch();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const handleOptionsClose = () => setAnchorEl(null);

  const handleDeleteDialogOpen = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setShowDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setShowDeleteDialog(false);
    handleOptionsClose();
  };

  const handleListDelete = () => {
    handleDeleteDialogClose();
    dispatch(removeList({ tripId, listId: list.id, listOrder, cardIds: list.cardOrder ?? [] }));
  };

  // Wrapper function to handle event propagation
  const handleCancelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleDeleteDialogClose();
  };

  const handleListNameEdit = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    setTimeout(() => editListName(), 0);
  };

  const deleteText = (listToDelete: string) =>
    `Deleting ${listToDelete.toUpperCase()} will remove all cards in the list.`;

  const open = Boolean(anchorEl);

  return (
    <>
      <OptionsMenu
        open={open}
        onClose={handleOptionsClose}
        onClick={(event) => event.stopPropagation()}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{ sx: { borderRadius: '10px' } }}
      >
        <MenuItem onClick={handleListNameEdit}>
          <ListItemIcon>
            <EditRounded />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>
        {isMobile && (
          <MenuItem onClick={handleListEmojiEdit}>
            <ListItemIcon>
              <InsertEmoticon />
            </ListItemIcon>
            <ListItemText>Edit Emoji</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={handleDeleteDialogOpen}>
          <ListItemIcon>
            <DeleteOutlineRounded />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </OptionsMenu>

      <OptionsDialog
        open={showDeleteDialog}
        onClose={handleDeleteDialogClose}
        PaperProps={{ sx: { borderRadius: '10px' } }}
      >
        <OptionsDialogContent>
          <OptionsDialogTitle>Are you sure?</OptionsDialogTitle>
          <OptionsDialogContentText>{deleteText(list.name)}</OptionsDialogContentText>
        </OptionsDialogContent>
        <OptionsDialogActions>
          <CancelButton onClick={handleCancelClick}>Cancel</CancelButton>
          <DeleteButton onClick={handleListDelete} autoFocus>
            Delete
          </DeleteButton>
        </OptionsDialogActions>
      </OptionsDialog>
    </>
  );
}

ListOptions.displayName = 'ListOptions';
export default ListOptions;
