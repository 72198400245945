import styled from 'styled-components';
import { ClearRounded } from '@mui/icons-material';

export const CardContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 2%;
  left: 40%;
  transform: translateX(-40%);
  width: 22em;
  background-color: #fcfcfc;
  aspect-ratio: 3 / 1;
  align-items: center;
  cursor: pointer;

  -moz-box-shadow: 1px 1px 4px 2px #ccc;
  -webkit-box-shadow: 1px 1px 4px 2px #ccc;
  box-shadow: 1px 1px 4px 2px #ccc;
  border-radius: 10px;
`;

export const CardImgContainer = styled.div`
  height: 100%;
  aspect-ratio: 1 / 1;
`;

export const CardImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: rgb(115, 175, 177);
  background-image: url('/svgs/logo.png');
  background-position: center;
  background-size: 90%;
  border-radius: 10px 0 0 10px;
`;

export const CardDescriptionContainer = styled.div`
  padding: 0px 16px;
`;

export const CardName = styled.h3`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.2;
`;

export const Exit = styled(ClearRounded)`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 8px;
  right: 8px;

  filter: drop-shadow(1px 1px 1px #fff);
`;
